import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { getBrandConstants } from '_js/brands';
import Button from '_js/components/ui/Button';
import Loader from '_js/components/ui/Loader';
import { loginSign } from '_js/store/actions/api/auth/login';

import CloseCross from '../../../images/close-cross.svg?react';
import BankIdLogoNO from '../../../images/bank-id-no.svg?react';

const ModalStyle = styled.div`
  all: unset;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: var(--white);
  width: 20rem;
  border-radius: 5px;
  padding: 1em;
  text-align: center;
  z-index: 1;
`;

const OverlayStyle = styled.div`
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: #0000008c;
  top: 0;
  left: 0;
`;

const Heading = styled.h2`
  font-family: var(--wallet-font-family-secondary);
  font-size: ${(props) => props.theme.fonts.headingsize.small};
  margin: 3.5rem 0 2rem;
`;

const CloseIcon = styled(CloseCross)`
  display: inline-block;
  position: relative;
  line-height: normal;
  width: 0.9em;
`;

const BankIdIcon = styled(BankIdLogoNO)`
  margin-right: 1rem;
`;

const CloseIconButton = styled.button`
  height: 25px;
  width: 25px;
  background-color: #f4f4f4;
  border-radius: 50%;
  display: inline-block;
  right: 0.9375rem;
  top: 0.9125rem;
  position: absolute;
  cursor: pointer;
  border: 0;
  font-weight: 10;
  line-height: 1;
  color: black;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 50% 4rem;
`;

const NoBankIDText = styled.p`
  font-family: var(--wallet-font-family-standard);
  font-size: ${(props) => props.theme.fonts.p.small};
  text-align: center;
`;

const TeleLink = () => (
  <a href={`tel:${getBrandConstants().phone.number}`}>{getBrandConstants().phone.display}</a>
);

const messages = defineMessages({
  closeButtonTitle: {
    id: 'gjeldsregisteret.consent.button.close',
    defaultMessage: 'Close',
    description: 'Descriptive text for button used to close a modal',
  },
});

const GjeldsregisteretSigninModal = ({ isOpen, handleClose }) => {
  const loginLink = useSelector((state) => state.auth.loginLink);
  const [isSignInLoading, setIsSignInLoading] = useState(false);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const onClose = () => {
    setIsSignInLoading(false);
    handleClose();
  };

  useEffect(() => {
    if (loginLink) {
      window.location.href = loginLink;
    }
  }, [loginLink]);

  return (
    <ReactModal
      className="_"
      overlayClassName="_"
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      // eslint-disable-next-line react/no-unstable-nested-components
      contentElement={(props, children) => <ModalStyle {...props}>{children}</ModalStyle>}
      // eslint-disable-next-line react/no-unstable-nested-components
      overlayElement={(props, contentElement) => (
        <OverlayStyle {...props}>{contentElement}</OverlayStyle>
      )}
    >
      <CloseIconButton
        type="button"
        title={formatMessage(messages.closeButtonTitle)}
        onClick={onClose}
      >
        <CloseIcon />
      </CloseIconButton>
      <Heading>
        <FormattedMessage
          id="gjeldsregisteret.consent.modal.heading"
          defaultMessage="Log in with BankID to see your overview!"
          description="Descriptive header for prompting user to log in with BankID"
        />
      </Heading>
      {isSignInLoading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : null}
      <Button
        type="button"
        primary
        fullWidth
        onClick={() => {
          setIsSignInLoading(true);
          dispatch(loginSign({ idKollenStandaloneRedirect: '/gjeldsregisteret' }));
        }}
        disabled={isSignInLoading}
      >
        <BankIdIcon />
        <FormattedMessage
          id="gjeldsregisteret.consent.modal.button"
          defaultMessage="Open BankID"
          description="Button text for BankIDinloggningsknappen in identification modal"
        />
      </Button>
      <NoBankIDText>
        <FormattedMessage
          id="gjeldsregisteret.consent.modal.customerService"
          defaultMessage="If you do not have BankID, please contact customer service at {phoneNumber}"
          description="Infotext under knapp för inloggning med BankID i indentification modal om kunden inte har bank id"
          values={{
            phoneNumber: TeleLink(),
          }}
        />
      </NoBankIDText>
    </ReactModal>
  );
};

GjeldsregisteretSigninModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

GjeldsregisteretSigninModal.defaultProps = {
  isOpen: false,
  handleClose: () => {},
};

export default GjeldsregisteretSigninModal;
