import { isProductionOrStaging } from '_js/utils/environment';

const DEV_CLIENT_ID = '644552272451-kt4dk6hucfmntkmdiip8s8f547pa3lts.apps.googleusercontent.com';
const PROD_CLIENT_ID = '153352736029-1h4uk6vsbuasrn55ohodcs5muk3bql18.apps.googleusercontent.com';

const GOOGLE_AUTH_CONFIG = {
  client_id: isProductionOrStaging() ? PROD_CLIENT_ID : DEV_CLIENT_ID,
  ux_mode: 'redirect',
  scope: 'profile email',
  redirect_uri: `${window.location.origin}/internal-login-successful`,
};

export default GOOGLE_AUTH_CONFIG;
