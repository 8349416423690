/* eslint-disable default-param-last */
import {
  FETCH_APPLICATION,
  FETCH_APPLICATION_SUCCESS,
  FETCH_APPLICATION_FAILED,
  FETCH_APPLICATION_ACCOUNTS,
  FETCH_APPLICATION_ACCOUNTS_SUCCESS,
  FETCH_APPLICATION_ACCOUNTS_FAILED,
  FETCH_APPLICATION_ACCOUNTS_UNMODIFIED,
} from '_js/store/actions/api/application/fetch';

import {
  SKIP_APPLICATION_TASK,
  SKIP_APPLICATION_TASK_SUCCESS,
  SKIP_APPLICATION_TASK_FAILED,
} from '_js/store/actions/api/application/skip-application-task';

import {
  SUBMIT_MORTGAGE_INTEREST,
  SUBMIT_MORTGAGE_INTEREST_FAILED,
  SUBMIT_MORTGAGE_INTEREST_SUCCESS,
} from '_js/store/actions/api/application/mortgage-interest';

import {
  FETCH_RETURNING_APPLICANT,
  FETCH_RETURNING_APPLICANT_SUCCESS,
  FETCH_RETURNING_APPLICANT_FAILED,
} from '_js/store/actions/api/application/fetch-returning-applicant';

import {
  FETCH_RECOMMENDED_UPDATE,
  FETCH_RECOMMENDED_UPDATE_SUCCESS,
  FETCH_RECOMMENDED_UPDATE_FAILED,
} from '_js/store/actions/api/application/recommended-update';

import {
  COMPLETE_TINK,
  TINK_ERROR,
  TINK_RESET_ERROR,
  SESSION_ID_SUCCESS,
} from '_js/store/actions/api/application/tink';
import { RECENT_APPLICATION_MODAL_IS_OPEN } from '_js/store/actions/recent-application-modal';
import {
  FETCH_MULTIPLE_APPLICATIONS,
  FETCH_MULTIPLE_APPLICATIONS_SUCCESS,
  FETCH_MULTIPLE_APPLICATIONS_FAILED,
  APPLICATIONS_OVERVIEW,
} from '_js/store/actions/api/application/multiple-applications';
import { PICKING_INSURANCE } from '../actions/ppi/picking-insurance.js';

export const initialState = {
  loading: true,
  loadingBankAccounts: true,
  updating: false,
  updatingApplicants: false,
  returningApplicantData: {},
  secondChanceLoading: true,
  stepSkipError: false,
  tinkError: false,
  bankAccounts: [],
  sessionId: null,
  mortgageInterest: {
    initial: true,
  },
  multipleApplications: [],
  isShowingMultipleApplications: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_APPLICATION:
      return {
        ...state,
        loading: !state.state,
      };

    case FETCH_APPLICATION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        playCurrentLoans: action.payload.playCurrentLoans
          ? action.payload.playCurrentLoans.map((loan) => ({
              amount: loan.amount,
              monthlyCost: loan.monthlyPayment,
              repaymentTime: loan.repaymentTime,
              interestRate: loan.interestRate,
            }))
          : [],
        loading: false,
        permissions: undefined,
      };

    case FETCH_APPLICATION_FAILED:
      return { ...state, loading: false };

    case FETCH_MULTIPLE_APPLICATIONS:
      return {
        ...state,
        loading: true,
      };

    case FETCH_MULTIPLE_APPLICATIONS_SUCCESS:
      return {
        ...state,
        multipleApplications: action.payload,
        loading: false,
      };

    case FETCH_MULTIPLE_APPLICATIONS_FAILED:
      return {
        ...state,
        loading: false,
      };

    case APPLICATIONS_OVERVIEW:
      return {
        ...state,
        isShowingMultipleApplications: action.payload,
      };

    case SKIP_APPLICATION_TASK:
      return {
        ...state,
        loading: true,
        stepSkipError: false,
        tinkError: false,
      };

    case SKIP_APPLICATION_TASK_SUCCESS:
      return {
        ...state,
        state: 'pending',
        loading: false,
        stepSkipError: false,
      };

    case SKIP_APPLICATION_TASK_FAILED:
      return {
        ...state,
        loading: false,
        stepSkipError: true,
      };

    case SESSION_ID_SUCCESS:
      return {
        ...state,
        sessionId: action.payload.data,
      };

    case COMPLETE_TINK:
      return {
        ...state,
        tinkComplete: true,
        tinkError: false,
      };

    case TINK_ERROR:
      return {
        ...state,
        tinkError: typeof action.payload === 'string' ? action.payload : true,
        stepSkipError: false,
      };

    case TINK_RESET_ERROR:
      return {
        ...state,
        tinkError: false,
        stepSkipError: false,
      };

    case FETCH_RETURNING_APPLICANT:
      return {
        ...state,
        loading: true,
      };

    case FETCH_RETURNING_APPLICANT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };

    case FETCH_RETURNING_APPLICANT_FAILED:
      return { ...state, loading: false };

    case FETCH_RECOMMENDED_UPDATE:
      return {
        ...state,
        secondChanceLoading: true,
      };

    case FETCH_RECOMMENDED_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        recommendedAmount: action.payload.recommendedTotalAmount,
        recommendedUpdateEligible: action.payload.eligible,
        secondChanceLoading: false,
      };

    case FETCH_RECOMMENDED_UPDATE_FAILED:
      return {
        ...state,
        secondChanceLoading: false,
      };

    case RECENT_APPLICATION_MODAL_IS_OPEN:
      return {
        ...state,
        recentApplicationModalIsOpen: action.payload,
      };

    case PICKING_INSURANCE:
      return {
        ...state,
        isPickingInsurance: action.payload,
      };

    case FETCH_APPLICATION_ACCOUNTS:
      return {
        ...state,
        loadingBankAccounts: true,
      };

    case FETCH_APPLICATION_ACCOUNTS_SUCCESS:
      return {
        ...state,
        bankAccounts: action.payload,
        loadingBankAccounts: false,
      };

    case FETCH_APPLICATION_ACCOUNTS_FAILED:
    case FETCH_APPLICATION_ACCOUNTS_UNMODIFIED:
      return {
        ...state,
        loadingBankAccounts: false,
      };

    case SUBMIT_MORTGAGE_INTEREST:
      return {
        ...state,
        mortgageInterest: {
          loading: true,
        },
      };

    case SUBMIT_MORTGAGE_INTEREST_SUCCESS:
      return {
        ...state,
        mortgageInterest: {
          success: true,
        },
      };

    case SUBMIT_MORTGAGE_INTEREST_FAILED:
      return {
        ...state,
        mortgageInterest: {
          failure: true,
        },
      };

    default:
      return state;
  }
};
