import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { APPLICATION } from '_js/constants/States';

import BreadcrumbItem from '../atoms/BreadcrumbItem';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 1em auto 0;
  ${({ $hasAcceptedBid }) =>
    $hasAcceptedBid &&
    css`
      margin-bottom: 1.5em;
      margin-top: 1.5em;
    `}
`;

const BreadcrumbListWrapper = styled.ol`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 30rem;
  margin: 0;
  padding: 0;
  text-align: center;
  box-sizing: border-box;
`;

export const BreadcrumbList = ({ signed }) => {
  const bids = useSelector((state) => state.bidding.bids);
  const applicationState = useSelector((state) => state.application.state);
  const hasBids = bids.length > 0;
  const hasApplicationOnHold = applicationState === APPLICATION.ON_HOLD || signed;
  const hasAcceptedBid = applicationState === APPLICATION.ACCEPTED || signed;
  const hasSigned = signed;

  return (
    <Wrapper $hasAcceptedBid={hasAcceptedBid}>
      <BreadcrumbListWrapper>
        <BreadcrumbItem done>
          <FormattedMessage
            id="breadcrumbList.apply"
            defaultMessage="Apply"
            description="First element in the breadcrumb (the progress stepper)"
            tagName=""
          />
        </BreadcrumbItem>

        <BreadcrumbItem done={!hasApplicationOnHold} active={hasApplicationOnHold} number={2}>
          <FormattedMessage
            id="breadcrumbList.optimize"
            defaultMessage="Optimize"
            description="Second element in the breadcrumb (the progress stepper)"
            tagName=""
          />
        </BreadcrumbItem>

        <BreadcrumbItem
          done={hasAcceptedBid}
          active={!hasApplicationOnHold && !hasAcceptedBid && hasBids}
          number={3}
        >
          <FormattedMessage
            id="breadcrumbList.choose"
            defaultMessage="Choose"
            description="Third element in the breadcrumb (the progress stepper)"
            tagName=""
          />
        </BreadcrumbItem>

        <BreadcrumbItem
          done={hasSigned}
          active={!hasApplicationOnHold && !hasSigned && hasAcceptedBid}
          number={4}
        >
          <FormattedMessage
            id="breadcrumbList.sign"
            defaultMessage="Sign"
            description="Last element in the breadcrumb (the progress stepper)"
            tagName=""
          />
        </BreadcrumbItem>
      </BreadcrumbListWrapper>
    </Wrapper>
  );
};

BreadcrumbList.propTypes = {
  signed: PropTypes.bool,
};

BreadcrumbList.defaultProps = {
  signed: false,
};

export default BreadcrumbList;
