// internal
import { API } from '_js/store/constants';

export const FETCH_APPLICATION_LOGS = 'API/SYSTEM/FETCH_APPLICATION_LOGS';
export const FETCH_APPLICATION_LOGS_SUCCESS = 'API/SYSTEM/FETCH_APPLICATION_LOGS_SUCCESS';
export const FETCH_APPLICATION_LOGS_FAILED = 'API/SYSTEM/FETCH_APPLICATION_LOGS_FAILED';

const success = (type, payload) => ({
  type,
  payload,
});

const failure = (type, error) => ({
  type,
  message: 'Failed to fetch application logs.',
  error,
});

export const fetchApplicationLogs = (id, actions = []) => ({
  type: API,
  types: [FETCH_APPLICATION_LOGS, FETCH_APPLICATION_LOGS_SUCCESS, FETCH_APPLICATION_LOGS_FAILED],
  payload: () => {
    const actionsQueryString = actions.reduce(
      (queryString, action) => `${queryString}actions=${action}&`,
      '',
    );
    return {
      url: `/applications/${id}/logs?${actionsQueryString}`,
      success,
      failure,
      auth: true,
    };
  },
});
