import { GrowthBook } from '@growthbook/growthbook-react';

import { getBrand, getBrandAndMarket, getMarket } from '_js/brands';
import { getCookie } from '_js/utils/cookies';
import { useEffect, useMemo } from 'react';
import { isDevelopmentOrStaging } from '../environment';

export const useSetupGrowthbook = () => {
  const userPseudoId = getCookie('_ga');
  const market = getMarket();
  const brand = getBrand();
  const brandAndMarket = getBrandAndMarket();
  const growthbook = useMemo(
    () =>
      new GrowthBook({
        apiHost: 'https://cdn.growthbook.io',
        clientKey: import.meta.env.VITE_GROWTHBOOK_SDK_KEY,
        enableDevMode: isDevelopmentOrStaging(),
        attributes: {
          brandAndMarket,
          brand,
          market,
          user_pseudo_id: userPseudoId,
        },
      }),
    [brand, brandAndMarket, market, userPseudoId],
  );

  useEffect(() => {
    growthbook.loadFeatures({
      autoRefresh: isDevelopmentOrStaging(),
    });
  }, [growthbook]);

  return growthbook;
};

export const updateGrowthBookAttributes = (growthbook, attributes) => {
  growthbook.setAttributes({
    ...growthbook.getAttributes(),
    ...attributes,
  });
};
