// external
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form/dist/redux-form';

// internal
import application from './application';
import applicationLogs from './application-logs';
import auth from './auth';
import bidding from './bidding';
import creditors from './creditors';
import ppi from './ppi';
import clientTimeOffset from './client-time-offset';
import token from './token';
import { loanCalculatorPlugin } from './loans';
import combinePlugins from './plugins/combine-plugins';
import dirtyState from './plugins/dirty-state';
import formState from './plugins/form-state';
import newsletter from './newsletter';

const reducers = combineReducers({
  form: formReducer.plugin({
    application: combinePlugins([dirtyState, formState]),
    loanCalculator: loanCalculatorPlugin,
  }),
  application,
  applicationLogs,
  auth,
  bidding,
  creditors,
  ppi,
  clientTimeOffset,
  token,
  newsletter,
});

const REDUCERS_TO_CLEAR_ON_LOGOUT = {
  application: true,
  applicationLogs: true,
  bidding: true,
  ppi: true,
  token: true,
};

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    Object.keys(state).forEach((reducerKey) => {
      if (REDUCERS_TO_CLEAR_ON_LOGOUT[reducerKey]) {
        // eslint-disable-next-line no-param-reassign
        state[reducerKey] = undefined;
      }
    });
  }

  return reducers(state, action);
};

export default rootReducer;
