import EMPLOYMENT_TYPES from '_js/localizations/config/SE/employment-types';
import INTL_FORMATS from '_js/localizations/config/intl-formats';
import LOAN_LIMITS from '_js/localizations/config/SE/loan-limits';
import LOAN_RESTRICTIONS from '_js/localizations/config/SE/loan-restrictions';
import MARITAL_STATUS from '_js/localizations/config/SE/marital-status';
import HOME_TYPES from '_js/localizations/config/SE/home-types';
import LOAN_PURPOSES, {
  DEFAULT_LOAN_PURPOSE,
  ALL_LOAN_PURPOSES,
  NEW_LOAN_FORM_PURPOSES,
} from '_js/localizations/config/SE/loan-purposes';
import DETAILS from '_js/localizations/config/SE/details';
import AWAITING_BIDS_INFO from '_js/localizations/config/SE/awaiting-bids-info';

const localeData = {
  LOAN: LOAN_LIMITS,
  RESTRICTIONS: LOAN_RESTRICTIONS,
  EMPLOYMENT_TYPES,
  INTL_FORMATS,
  CIVIL_STATUS_TYPES: MARITAL_STATUS,
  HOME_TYPES,
  PURPOSES: LOAN_PURPOSES,
  NEW_LOAN_FORM_PURPOSES,
  ALL_LOAN_PURPOSES,
  DEFAULT_LOAN_PURPOSE,
  AWAITING_BIDS_INFO,
  ...DETAILS,
};

export { LOAN_LIMITS as LOAN };
export { LOAN_RESTRICTIONS as RESTRICTIONS };
export { EMPLOYMENT_TYPES };
export { INTL_FORMATS };
export { MARITAL_STATUS as CIVIL_STATUS_TYPES };
export { HOME_TYPES };
export { LOAN_PURPOSES as PURPOSES };
export { NEW_LOAN_FORM_PURPOSES };
export { ALL_LOAN_PURPOSES };
export { DEFAULT_LOAN_PURPOSE };
export { AWAITING_BIDS_INFO };
export { DETAILS };

export default localeData;
