import { FormattedMessage, FormattedPlural } from 'react-intl';
import { FormattedNumberWithFallback } from '_js/utils/FormattedNumberWithFallback';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LabelValueRowWithBorderBottom, BoldText } from './GjeldsregisteretCurrentLoans.styles';

const StyledLabel = styled.span`
  text-align: left;
`;

export default function GjeldsregisteretRepaymentLoan({ loan }) {
  return (
    <>
      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.financialInstitutionName.3"
            defaultMessage="Bank"
            description="A label for the financial institution name"
          />
        </StyledLabel>
        <BoldText>{loan?.financialInstitutionName?.toLowerCase()}</BoldText>
      </LabelValueRowWithBorderBottom>
      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em" data-testid="repaymentLoan">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.terms"
            defaultMessage="Payback Time"
            description="A label for the payback time"
          />
        </StyledLabel>
        <BoldText>
          {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
          {loan?.terms}{' '}
          <FormattedPlural
            value={10}
            one={
              <FormattedMessage id="common.month" defaultMessage="Month" description="One month" />
            }
            other={
              <FormattedMessage
                id="common.months"
                defaultMessage="Months"
                description="Multiple months"
              />
            }
          />
        </BoldText>
      </LabelValueRowWithBorderBottom>
      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.balance"
            defaultMessage="Balance"
            description="A label for the balance"
          />
        </StyledLabel>
        <BoldText>
          <FormattedNumberWithFallback value={loan.balance} formatAs="currency" />
        </BoldText>
      </LabelValueRowWithBorderBottom>

      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em" data-testid="interest">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.interest"
            defaultMessage="Interest"
            description="A label for the nominal interest rate"
          />
        </StyledLabel>
        <BoldText>
          <FormattedNumberWithFallback
            value={loan.nominalInterestRate / 100}
            formatAs="interestRate"
          />
        </BoldText>
      </LabelValueRowWithBorderBottom>
      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em" data-testid="installmentCharges">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.installmentCharges.2"
            defaultMessage="Charge"
            description="A label for the installment Charges"
          />
        </StyledLabel>
        <BoldText>
          <FormattedNumberWithFallback value={loan?.installmentCharges} formatAs="currency" />
        </BoldText>
      </LabelValueRowWithBorderBottom>
      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em" data-testid="installmentChargePeriod">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.installmentChargePeriod.2"
            defaultMessage="Installment charge period"
            description="A label for the installment charge period"
          />
        </StyledLabel>
        <BoldText>
          {loan?.installmentChargePeriod === 'MONTHLY' ? (
            <FormattedMessage
              id="common.monthly.2"
              defaultMessage="Monthly"
              description="Value for monthly"
            />
          ) : (
            <FormattedMessage
              id="common.annually.2"
              defaultMessage="Annually"
              description="Value for annually"
            />
          )}
        </BoldText>
      </LabelValueRowWithBorderBottom>
      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.coBorrower.3"
            defaultMessage="Co-borrower"
            description="A label for the co borrower"
          />
        </StyledLabel>
        <BoldText>
          {loan?.coBorrower === 'none' ? (
            <FormattedMessage
              id="gjeldsregisteret.current.loans.moreInfo.coBorrowerValue.none.3"
              defaultMessage="None"
              description="The value of co borrower"
            />
          ) : (
            <FormattedMessage
              id="gjeldsregisteret.current.loans.moreInfo.coBorrowerValue.exists.3"
              defaultMessage="Exists"
              description="The value of co borrower"
            />
          )}
        </BoldText>
      </LabelValueRowWithBorderBottom>
      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.processedTime.3"
            defaultMessage="Last updated from creditor"
            description="A label for the processed time"
          />
        </StyledLabel>
        <BoldText>{format(new Date(loan?.processedTime), 'dd/MM/yy')}</BoldText>
      </LabelValueRowWithBorderBottom>
      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.receivedTime.3"
            defaultMessage="Last updated from gjeldsregistret"
            description="A label for the receieved time"
          />
        </StyledLabel>
        <BoldText>{format(new Date(loan?.receivedTime), 'dd/MM/yy')}</BoldText>
      </LabelValueRowWithBorderBottom>
    </>
  );
}

GjeldsregisteretRepaymentLoan.propTypes = {
  loan: PropTypes.shape({
    id: PropTypes.number,
    balance: PropTypes.number,
    coBorrower: PropTypes.string,
    consentId: PropTypes.number,
    createdAt: PropTypes.string,
    financialInstitutionId: PropTypes.string,
    financialInstitutionName: PropTypes.string,
    installmentChargePeriod: PropTypes.string,
    installmentCharges: PropTypes.number,
    interestBearingBalance: PropTypes.number,
    loanType: PropTypes.string,
    nominalInterestRate: PropTypes.number,
    nonInterestBearingBalance: PropTypes.number,
    originalBalance: PropTypes.number,
    processedTime: PropTypes.string,
    providerId: PropTypes.string,
    receivedTime: PropTypes.string,
    type: PropTypes.number,
    updatedAt: PropTypes.string,
    terms: PropTypes.number,
  }),
};

GjeldsregisteretRepaymentLoan.defaultProps = {
  loan: null,
};
