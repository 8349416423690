import deepFreeze from '_js/utils/deepFreeze';

export const PERMISSIONS = deepFreeze({
  APPLICATIONS: {
    VIEW: 'APPLICATIONS.VIEW',
    LIST: 'APPLICATIONS.LIST',
    MANAGE: 'APPLICATIONS.MANAGE',
    UPDATE: 'APPLICATIONS.UPDATE',
  },
  BIDS: {
    VIEW: 'BIDS.VIEW',
    LIST: 'BIDS.LIST',
    ACCEPT: 'BIDS.ACCEPT',
    UNDO_ACCEPT: 'BIDS.UNDO_ACCEPT',
    REDIRECT: 'BIDS.REDIRECT',
    CREATED_AT: 'BIDS.CREATED_AT',
  },
  INVITES: {
    VIEW: 'INVITES.VIEW',
    REQUEST_NEW: 'INVITES.REQUEST_NEW',
  },
  PPI: {
    VIEW: 'PPI.VIEW',
    ALL_PLANS: 'PPI.ALL_PLANS',
    SIGN: 'PPI.SIGN',
    END: 'PPI.END',
  },
  CURRENT_LOANS: {
    VIEW: 'CURRENT_LOANS.VIEW',
    UPDATE: 'CURRENT_LOANS.UPDATE',
  },
  USER: {
    LOGOUT: 'USER.LOGOUT',
  },
});

export const ALL_PERMISSIONS = Object.freeze(
  Object.values(PERMISSIONS).reduce(
    (entities, actions) => entities.concat(Object.values(actions)),
    [],
  ),
);

export const ADMIN_PERMISSIONS = Object.freeze(
  ALL_PERMISSIONS.filter((action) => action !== PERMISSIONS.BIDS.REDIRECT),
);

export const NO_PERMISSIONS = Object.freeze([]);
