import { useEffect, useState, useDebugValue } from 'react';

const useTimeout = (delay) => {
  const [hasTimedOut, setTimeState] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => setTimeState(true), delay);
    return () => clearTimeout(timeoutId);
  }, [delay]);

  useDebugValue(hasTimedOut);

  return hasTimedOut;
};

export default useTimeout;
