import { defineMessages } from 'react-intl';

const awaitingBidsMessages = defineMessages({
  loanCompare: {
    defaultMessage: 'The lenders that suit you best submit loan proposals.',
    id: 'awaitingBidsMessages.loanCompare',
    description: 'Informs the customer that the creditors best suited will give a loan offer',
  },
  bestBid: {
    defaultMessage: 'Lenders provide as high an amount and repayment period as they can.',
    id: 'awaitingBidsMessages.bestBid',
    description: 'Informs the customer that the creditors will give the best loan offer possible',
  },
  sms: {
    defaultMessage:
      'Via the SMS, you will proceed to your application. You can leave this page whenever you want.',
    id: 'awaitingBidsMessages.sms',
    description:
      'Informs the customer that they will receive text message on every new loan offer and that they can close the page if they like',
  },
  oneUc: {
    defaultMessage: '{brand} only does one credit check at UC.',
    id: 'awaitingBidsMessages.oneUc',
    description: 'Informs the customer that Advisa will only take 1 credit check',
  },
  responseTime: {
    defaultMessage: 'Lenders respond within 1 minute to 1 business day.',
    id: 'awaitingBidsMessages.time',
    description:
      'Informs the customer that the creditors will give loan offer from 1 min to 1 working day',
  },
  fastAnswersHeader: {
    id: 'awaitingBidsGoodToKnow.mediaObject.fastAnswers.contentHeading',
    defaultMessage: 'Fast responses',
    description: 'Tells the customer that they will get fast answers from the creditors',
  },
  loanCompareHeader: {
    id: 'awaitingBidsGoodToKnow.mediaObject.loanCompare.contentHeading',
    defaultMessage: 'Number of loan proposals',
    description: 'Gives information about how the number of loan offers can differ',
  },
  bestBidHeader: {
    id: 'awaitingBidsGoodToKnow.mediaObject.bestBid.contentHeading',
    defaultMessage: 'Best possible loan proposals',
    description: 'Information to the customer that all creditors will give the best offer possible',
  },
  smsHeader: {
    id: 'awaitingBidsGoodToKnow.mediaObject.sms.contentHeading',
    defaultMessage: 'SMS on new loan proposal',
    description: 'Informs the customer that they will get a text message on every new loan offer',
  },
  oneUcHeader: {
    id: 'awaitingBidsGoodToKnow.mediaObject.UC.contentHeading',
    defaultMessage: '1 credit check',
    description:
      'Information shown after the customer has made a loan application, while they are waiting for loan offers. While a single credit check is being made, the customer may get multiple copies of it, since it has been sent to multiple creditors.',
  },
  ppiInsurance: {
    id: 'awaitingBidsGoodToKnow.mediaObject.ppi',
    defaultMessage:
      'We offer a payment protection insurance so that you can feel safe with your new monthly cost, even if something unexpected happens.',
    description:
      'Information to eligible customers that we offer an insurance so they can feel safe with their new monthly cost',
  },
  ppiInsuranceHeader: {
    id: 'awaitingBidsGoodToKnow.mediaObject.ppi.contentHeading',
    defaultMessage: 'Payment protection insurance',
    description: 'Heading to the content that we offer an insurance',
  },
});

export default awaitingBidsMessages;
