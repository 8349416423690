import PropTypes from 'prop-types';
import styled from 'styled-components';

import { APPLICATION_OPTIMIZE_STATE } from '_js/constants/ApplicationOnHold';
import FAQ from '_js/components/molecules/FAQ';
import devices from '_js/styles/Devices';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.desktop} {
    margin-top: 1rem;
  }
`;

const Content = styled.div`
  max-width: 30rem;
  padding: 1.2em;
  margin: 3.2em auto 4.2em;
  border-radius: 5px;
  text-align: center;
  background-color: var(--white);
  position: relative;
`;

const OptimizeStep = ({ children, step }) => {
  const optimizeState = [
    APPLICATION_OPTIMIZE_STATE.ADD_CO_APPLICANT,
    APPLICATION_OPTIMIZE_STATE.ADD_CURRENT_LOANS,
  ];

  return (
    <Wrapper>
      <Content>{children}</Content>

      {optimizeState.includes(step) && (
        <div className="u-margin--bottom-2em">
          <FAQ qnaSet={step} />
        </div>
      )}
    </Wrapper>
  );
};

OptimizeStep.propTypes = {
  children: PropTypes.node,
  step: PropTypes.string,
};

OptimizeStep.defaultProps = {
  children: null,
  step: '',
};

export { Wrapper };
export default OptimizeStep;
