// internal
import { API } from '_js/store/constants';
import { getBrand } from '_js/brands';
import { urlETags } from 'redux-shapeshifter-middleware';

export const FETCH_PPI = 'API/SYSTEM/FETCH_PPI';
export const FETCH_PPI_SUCCESS = 'API/SYSTEM/FETCH_PPI_SUCCESS';
export const FETCH_PPI_FAILED = 'API/SYSTEM/FETCH_PPI_FAILED';

const FETCH_INSURANCE_ELIGIBILITY = 'API/SYSTEM/FETCH_INSURANCE_ELIGIBILITY';
export const FETCH_INSURANCE_ELIGIBILITY_SUCCESS = 'API/SYSTEM/FETCH_INSURANCE_ELIGIBILITY_SUCCESS';
const FETCH_INSURANCE_ELIGIBILITY_FAILED = 'API/SYSTEM/FETCH_INSURANCE_ELIGIBILITY_FAILED';

const clearEtags = () => {
  Object.keys(urlETags || {}).forEach((etag) => {
    delete urlETags[etag];
  });
};

const success = (type, payload) => ({
  type,
  payload,
});

const failure = (type, error) => ({
  type,
  message: 'Failed to fetch PPI for user.',
  error,
});

export const fetchPpi = (id, brand) => ({
  type: API,
  types: [FETCH_PPI, FETCH_PPI_SUCCESS, FETCH_PPI_FAILED],
  payload: () => ({
    url: `/applications/${id}/insurance/policies?brand=${brand}`,
    success,
    failure,
    auth: true,
  }),
  axios: {
    withCredentials: true,
  },
});

const fetchInsuranceEligibility = (id, brand) => ({
  type: API,
  types: [
    FETCH_INSURANCE_ELIGIBILITY,
    FETCH_INSURANCE_ELIGIBILITY_SUCCESS,
    FETCH_INSURANCE_ELIGIBILITY_FAILED,
  ],
  payload: () => ({
    url: `/applications/${id}/insurance/eligibility?brand=${brand}`,
    success,
    failure,
    auth: true,
  }),
  axios: {
    withCredentials: true,
  },
});

export const getInsuranceEligibility =
  ({ applicationId }) =>
  (dispatch) =>
    dispatch(fetchInsuranceEligibility(applicationId, getBrand()));

export const getPpiInfo =
  ({ applicationId, clearCache } = {}) =>
  (dispatch) => {
    if (clearCache) {
      clearEtags();
    }

    return dispatch(fetchPpi(applicationId, getBrand()));
  };
