import { sortBy } from '_js/utils/sorting';
import { shouldShowExpirationTime } from '_js/utils/bid';
import { CREDITOR_ACCEPT } from '_js/constants/States';
import { DEBT_LETTER_DELIVERY_METHOD_MAPPING, EBANK } from '_js/constants/DebtDeliveryMethods';
import { addMilliseconds, isBefore, min } from 'date-fns';

export const MONTHLY_COST = 'monthlyCost';
export const APR = 'apr';
export const AMOUNT = 'amount';
export const BIN = 'bin';
export const WEIGHTED = 'weighted';

export const getInviteFromList = (id, inviteList = []) =>
  inviteList.filter((invite) => invite.creditorId === Number(id))[0];

export const getBidFromList = (id, bidsList = []) =>
  bidsList.filter((bid) => bid.id === Number(id))[0];

export const getAcceptedBidFromList = (bidsList = []) => {
  const filteredBids = bidsList.filter((bid) => bid.accepted);
  const acceptedBid = filteredBids[0];
  if (!acceptedBid) {
    return undefined;
  }

  return {
    creditorAccept: CREDITOR_ACCEPT.PENDING,
    ...acceptedBid,
  };
};

export const haveAllBidsExpired = (bidsList = []) => {
  if (bidsList.length === 0) {
    return false;
  }

  return bidsList.every((bid) => bid.expired);
};

export const getEarliestExpirationDate = (bidsList = [], clientTimeOffset = 0) =>
  bidsList.reduce((currentEarliestDate, bid) => {
    const bidExpires = new Date(bid.expiresAt);
    const expirationDateWithOffset = addMilliseconds(bidExpires, clientTimeOffset);
    const expiresBeforeCurrentDate = isBefore(expirationDateWithOffset, new Date());

    if (shouldShowExpirationTime(bid)) return currentEarliestDate;
    if (bid.expired) return currentEarliestDate;
    if (expiresBeforeCurrentDate) return currentEarliestDate;

    if (currentEarliestDate === null) return new Date(bid.expiresAt);

    return currentEarliestDate ? min([bidExpires, currentEarliestDate]) : bidExpires;
  }, null);

export const hasSortableBids = (...bidsGroups) => bidsGroups.some((bids) => bids.length > 1);

const bestBinValue = {
  bin1: () => 1,
  bin2: () => 2,
  bin3: () => 3,
  bin4: () => 4,
  bin5: () => 5,
  undefined: (bid) => bid.apr,
};

const bidComparators = {
  monthlyCost: () => (bidA, bidB) => bidA.averageMonthlyPayment - bidB.averageMonthlyPayment,
  apr: () => (bidA, bidB) => bidA.apr - bidB.apr,
  amount: () => (bidA, bidB) => bidB.amount - bidA.amount,
  createdAt: () => (bidA, bidB) => Date.parse(bidA.createdAt) - Date.parse(bidB.createdAt),
  id: () => (bidA, bidB) => bidA.id - bidB.id,
  bin: () => (bidA, bidB) => bestBinValue[bidA.bin](bidA) - bestBinValue[bidB.bin](bidB),
  weighted: () => (bidA, bidB) => bidB.weightedAmount - bidA.weightedAmount,
};

export const bidsSortingFunctions = {
  monthlyCost: () =>
    sortBy(bidComparators.monthlyCost(), bidComparators.apr(), bidComparators.amount()),
  apr: () => sortBy(bidComparators.apr(), bidComparators.monthlyCost(), bidComparators.amount()),
  amount: () => sortBy(bidComparators.amount(), bidComparators.apr(), bidComparators.monthlyCost()),
  createdAt: () => sortBy(bidComparators.createdAt(), bidComparators.id()),
  bin: () => sortBy(bidComparators.bin(), bidComparators.apr()),
  weighted: () => sortBy(bidComparators.weighted(), bidComparators.apr()),
};

export const areBidArraysEqual = (bidsListA, bidsListB) =>
  bidsListA.every((bidA, i) => bidA.id === bidsListB[i].id);

export const hasAdditionalRequirements = (bid) => {
  if (
    !bid.additionalRequirementsBeforeDebtLetter ||
    !bid.additionalRequirementsOnDebtLetter ||
    !bid.loanInformation
  ) {
    return false;
  }

  const additionalRequirements = [].concat(
    bid.additionalRequirementsBeforeDebtLetter,
    bid.additionalRequirementsOnDebtLetter,
  );
  return Object.keys(additionalRequirements).length > 0;
};

export const hasDebtAcquittalAmount = (bid) => (bid.loanInformation || {}).debtAcquittalAmount > 0;

const shouldHaveCreditorSigningUrl = (bid) => {
  if (!bid.loanInformation) {
    return false;
  }
  const debtLetterDeliveryMethodId = bid.loanInformation.debtLetterDeliveryMethod;
  const debtLetterDeliveryMethods = DEBT_LETTER_DELIVERY_METHOD_MAPPING[debtLetterDeliveryMethodId];
  return debtLetterDeliveryMethods.includes(EBANK);
};

export const isPendingCreditorUrl = (bid, pollingDone) => {
  if (!bid) {
    return false;
  }

  if (bid.loanInformation?.creditorSigningUrl) {
    return false;
  }

  if (!shouldHaveCreditorSigningUrl(bid)) {
    return false;
  }

  if (pollingDone) {
    return false;
  }

  return bid.creditorAccept === CREDITOR_ACCEPT.PENDING;
};
