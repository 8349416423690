/* eslint-disable default-param-last */
import { SET_CLIENT_TIME_OFFSET } from '_js/store/actions/client-time-offset';

export default (state = 0, action) => {
  switch (action.type) {
    case SET_CLIENT_TIME_OFFSET:
      return action.payload;
    default:
      return state;
  }
};
