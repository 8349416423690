import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    margin: 0;
  }

  :root {
    /* Brand Colors */

    /* Primary Palette */
    --teal: #00bfbf;
    --dark-teal: #2b3845;
    --dark-teal-tint-10: #404c58;
    --off-white: #f0ebe5;
    --off-white-tint-50: #f8f5f2;
    --darken-light-grey-40: #526b84;
    --light-grey: #c6d1dc;
    --grey: #ced7e0;
    --dark-teal-tint-70: #b5c3d1;

    /* Secondary Palette */
    --burgundy: #70144a;
    --burgundy-tint-20: #8d436e;
    --red: #b81c4f;
    --red-tint-20: #c64972;
    --orange: #ff873d;
    --orange-tint-20: #ff9f64;
    --yellow: #ffd126;
    --yellow-tint-20: #ffda51;

    /* Tertiary Palette */
    --black: #000;
    --white: #fff;
    --black-lighten-20: #999;

    /* Variations (use sparingly) */
    --border-grey: #c7d1dc;

    /* Breakpoints */
    --breakpoint-desktop: 62rem;

    /* Shadow */
    --form-shadow: 0 3px 6px rgb(0 0 0 / 20%);
    --form-shadow-mobile: 0 8px 6px rgb(0 0 0 / 20%);

    /* Theming */
    --primary-button-background: ${({ theme }) => theme.colors.button.primary.background};
    --primary-button-color: ${({ theme }) => theme.colors.button.primary.text};
    --secondary-button-background: ${({ theme }) => theme.colors.button.secondary.background};
    --secondary-button-color: ${({ theme }) => theme.colors.button.secondary.text};
    --navlink-button-color: ${({ theme }) => theme.colors.navlink.color};
    --bid-page-separator: ${({ theme }) => theme.bidPage.separator};
    --item-circle-background: ${({ theme }) => theme.colors.circle.background};
    --item-circle-boxshadow: ${({ theme }) => theme.colors.circle.boxShadow};
    --item-circle-color: ${({ theme }) => theme.colors.circle.color};
    --carousel-dot-color: ${({ theme }) => theme.colors.carousel.dot};
    --carousel-arrow-color: ${({ theme }) => theme.colors.carousel.arrow};
    --item-expand-background: ${({ theme }) => theme.colors.expand.background};
    --details-number-color: ${({ theme }) => theme.colors.details.color};
    --details-summary-color: ${({ theme }) => theme.colors.details.color};
    --primary-body-background: ${({ theme }) => theme.colors.body.background};
    --interest-rate-color: ${({ theme }) => theme.colors.numbers.color};
    --item-chevron-arrow-color: ${({ theme }) => theme.colors.chevronArrow.color};
    --item-slider-color: ${({ theme }) => theme.colors.slider.color};
    --item-spinner-color: ${({ theme }) => theme.colors.spinner.color};
    --item-border-color: ${({ theme }) => theme.colors.border.color};
    --item-border-selected-option: ${({ theme }) => theme.colors.selectedOption.color};
    --primary-background-color: ${({ theme }) => theme.header.background.color};
    --spinner-border-color: ${({ theme }) => theme.spinner.border};
    --text-font-color: ${({ theme }) => theme.fonts.color};
    --subheading-font-size: ${({ theme }) => theme.fonts.subheading.large};
    --heading-font-medium: ${({ theme }) => theme.fonts.headingsize.medium};
    --heading-font-large: ${({ theme }) => theme.fonts.headingsize.large};
    --text-font-medium: ${({ theme }) => theme.fonts.p.mediumlarge};
    --text-font-medium-small: ${({ theme }) => theme.fonts.p.mediumSmall};
    --loading-background: ${({ theme }) => theme.loading.background};
    --loading-font-color: ${({ theme }) => theme.loading.fontColor};
    --loading-bounce: ${({ theme }) => theme.loading.bounce};
    --body-background-color: ${({ theme }) => theme.colors.body.background};
    --breadcrumb-line-color: ${({ theme }) => theme.breadcrumb.line};
    --breadcrumb-bg-color: ${({ theme }) => theme.breadcrumb.background.color};
    --breadcrumb-checkicon-color: ${({ theme }) => theme.breadcrumb.checkIcon};
    --breadcrumb-line-active-color: ${({ theme }) => theme.breadcrumb.activeColor};
    --breadcrumb-bg-bullet: ${({ theme }) => theme.breadcrumb.background.bullet};
    --breadcrumb-border-color: ${({ theme }) => theme.breadcrumb.border};
    --breadcrumb-bullet-font-color: ${({ theme }) => theme.breadcrumb.bulletFontColor};
    --breadcrumb-font-color: ${({ theme }) => theme.breadcrumb.fontColor};
    --burgermenu-li-bg-color: ${({ theme }) => theme.burgerMenuLi.background.color};
  }
`;
export default GlobalStyles;
