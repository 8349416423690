const teal = '#00bfbf';
const acceptTeal = '#0bb1b9';
const lightTeal = '#0dd';
const transparentTeal = '#e5f8f9';
const white = '#fff';
const maroon = '#b81c4f';
const whitesmoke = '#f8f5f2';
const blue = '#2b3845';
const greyblue = '#526b84';
const darkteal = '#008080';
const offWhiteTinted = '#cec8c1';
const grey = '#c7c2c2';
const neutralGrey50 = '#f4f4f410';
const neutralGrey100 = '#E0E0E020';

export default {
  id: 'advisa',
  loading: {
    background: blue,
    bounce: white,
    fontColor: white,
  },
  fonts: {
    button: {
      medium: '1.4em',
      large: '1.7em',
    },
    p: {
      small: '0.7500em',
      medium: '0.95em',
      mediumlarge: '1.1em',
      mediumSmall: '1em',
      large: '1.4em',
    },
    headingsize: {
      small: '1.5em',
      medium: '2em',
      large: '2.5em',
    },
    subheading: {
      large: '2em',
    },
    bidlistfont: {
      size: '0.95em',
    },
    color: white,
  },
  colors: {
    primary: teal,
    button: {
      primary: {
        text: white,
        background: teal,
      },
      secondary: {
        text: white,
        background: maroon,
      },
      tertiary: {
        text: white,
        bg: {
          default: 'transparent',
          hover: neutralGrey50,
          pressed: neutralGrey100,
        },
      },
    },
    navlink: {
      color: greyblue,
    },
    handlebutton: {
      color: {
        closed: teal,
        open: white,
      },
      bg: {
        closed: 'transparent',
        open: teal,
      },
    },
    carousel: {
      dot: darkteal,
      arrow: darkteal,
    },
    circle: {
      color: whitesmoke,
      background: teal,
      boxShadow: teal,
    },
    expand: {
      background: teal,
    },
    checkbullet: {
      color: teal,
    },
    details: {
      color: teal,
    },
    numbers: {
      color: teal,
    },
    arrow: {
      color: teal,
      chevron: white,
    },
    body: {
      background: whitesmoke,
    },
    chevronArrow: {
      color: teal,
    },
    slider: {
      color: teal,
    },
    border: {
      color: teal,
    },
    spinner: {
      color: teal,
    },
    selectedOption: {
      color: teal,
    },
    acceptedBid: {
      border: acceptTeal,
      color: transparentTeal,
    },
    lucideIcons: {
      primary: teal,
      secondary: teal,
    },
    accordion: {
      background: white,
      border: blue,
      body: blue,
      title: blue,
      chevron: blue,
    },
    infoCircle: {
      fill: teal,
    },
    myPagesAd: {
      background: whitesmoke,
      header: teal,
    },
  },
  styled: {
    button: teal,
    arrowButton: teal,
  },
  spinner: {
    border: lightTeal,
  },
  header: {
    background: {
      color: blue,
    },
  },
  bidPage: {
    separator: whitesmoke,
  },
  breadcrumb: {
    background: {
      color: blue,
      bullet: white,
    },
    border: white,
    line: offWhiteTinted,
    checkIcon: blue,
    activeColor: white,
    fontColor: grey,
    bulletFontColor: white,
  },
  burgerMenuLi: {
    background: {
      color: blue,
    },
  },
  headerText: {
    color: blue,
  },
  bidListBorder: {
    color: 'none',
    radius: '5px',
  },
  gjeldsregisteret: {
    privateLoans: {
      background: 'none',
      color: 'none',
    },
    creditCard: {
      background: 'none',
      color: 'none',
    },
    changeCards: {
      background: 'none',
      color: 'none',
    },
    button: {
      radius: '0px',
    },
  },
  insurance: {
    borderColor: 'none',
    iconColor: 'none',
    backgroundColor: 'none',
  },
};
