/* eslint "import/prefer-default-export": "off" */
import { jwtDecode } from 'jwt-decode';

let JWT_HEADER = '';

if (typeof btoa === 'function') {
  JWT_HEADER = btoa(
    JSON.stringify({
      alg: 'HS256',
      typ: 'JWT',
    }),
  );
}

export const decodeJwtPayload = (jwtToken) => {
  try {
    const completeJwtToken = `${JWT_HEADER}.${jwtToken}`;
    return jwtDecode(completeJwtToken);
  } catch {
    return false;
  }
};
