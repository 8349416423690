import SIGNING_METHODS from '_js/constants/SigningMethods';

export const getObjectOfCreditors = (creditors) =>
  creditors.reduce(
    (obj, creditor) => ({
      ...obj,
      [creditor.slug]: {
        ...creditor,
      },
    }),
    {},
  );

export const getSigningMethod = (signingMethod = '') => {
  if (typeof signingMethod !== 'string') {
    return SIGNING_METHODS.PAPER;
  }
  return SIGNING_METHODS[signingMethod.toUpperCase()] || SIGNING_METHODS.PAPER;
};

export const getCreditor = (creditor, bid) => ({
  id: bid?.creditorId,
  name: bid?.creditorName,
  slug: bid?.creditorSlug,
  ...creditor,
});
