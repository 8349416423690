import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

const OfflineMessage = () => (
  <div
    className={classNames(
      'o-alert',
      'o-alert--contained',
      'o-alert--top',
      'c-alert--warning',
      'u-margin--none',
    )}
  >
    <div className={classNames('o-container')}>
      <FormattedMessage
        id="network.offline"
        defaultMessage="You seem to lack internet access. To see the latest information, reconnect to the internet."
      />
    </div>
  </div>
);

export default OfflineMessage;
