import { isPlainObject, mapValues } from 'lodash-es';

const ANONIMIZATION_REGEX = /\w/gi;

const anonymize = (data) => {
  switch (typeof data) {
    case 'string':
    case 'number':
      return String(data).replace(ANONIMIZATION_REGEX, '*');
    case 'object':
      if (data instanceof Date) {
        return String(data).replace(ANONIMIZATION_REGEX, '*');
      }

      if (Array.isArray(data)) {
        return data.map(anonymize);
      }

      if (isPlainObject(data)) {
        return mapValues(data, anonymize);
      }
    // esline-disable-next-line no-fallthrough
    case 'boolean':
    default:
      return data;
  }
};

export default anonymize;
