import { useState, useEffect } from 'react';

function useMobileLayout() {
  const mobileMediaQuery = '(max-width: 61.99rem)';
  const mobileMediaQueryList = window.matchMedia(mobileMediaQuery);
  const [mobile, setMobile] = useState(mobileMediaQueryList.matches);

  useEffect(() => {
    const handleMediaQueryMatch = (event) => setMobile(event.matches);

    mobileMediaQueryList.addListener(handleMediaQueryMatch);

    return () => mobileMediaQueryList.removeListener(handleMediaQueryMatch);
    // Don't depend on external variables since it will make the effect trigger
    // on every resize, which we don't want.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return mobile;
}

export default useMobileLayout;
