import { actionTypes } from 'redux-form/dist/redux-form';
import LoanCalculator from '@advisa/loan-calculator';
import {
  toPath as toPathArray,
  get as getObjectPath,
  set as setObjectPath,
  last as getLastArrayItem,
  dropRight as removeLastArrayItem,
} from 'lodash-es';
import LoanCoverage from '_js/constants/LoanCoverage';
import { calculateLoan } from '_js/utils/Loan';
import getCurrentlySelectedBid from '../selectors/loans/getCurrentlySelectedBid';

const normalizeLoan = (loan) => ({
  ...loan,
  interestRate: undefined,
  interest: (loan.interestRate || 0) / 100,
  apr: (loan.apr || 0) / 100,
});

const normalizeLoans = (loans) => loans.map(normalizeLoan);

const compareLoans = ({ playBids = [], currentLoans = [], selectedLoanId }) => {
  const selectedBid = getCurrentlySelectedBid({ playBids, selectedLoanId });
  const normalizedSelectedBid = selectedBid && normalizeLoan(selectedBid);
  const normalizedCurrentLoans = currentLoans && normalizeLoans(currentLoans);
  return LoanCalculator.utils.compareLoans(normalizedSelectedBid, normalizedCurrentLoans);
};

const significantKeys = ['monthlyPayment', 'repaymentTime'];

const getSignificantKey = (currentInputName, previousSignificantKey) => {
  if (significantKeys.includes(currentInputName)) return currentInputName;

  if (previousSignificantKey) return previousSignificantKey;

  return 'repaymentTime';
};

const calculateChangedLoan = (object, path) => {
  const pathArray = toPathArray(path);
  const loanPathArray = removeLastArrayItem(pathArray);
  const loan = getObjectPath(object, loanPathArray) || {};
  const currentInputName = getLastArrayItem(pathArray);
  const updatedLoan = calculateLoan({
    ...loan,
    significantKey: getSignificantKey(currentInputName, loan.significantKey),
  });
  setObjectPath(object, loanPathArray, updatedLoan);
};

const getLoanCoverage = (loanIndex, summary) => {
  if (loanIndex < summary.fullyCoveredLoans) return LoanCoverage.FULL;

  if (loanIndex < summary.coveredLoans) return LoanCoverage.PARTIAL;

  return LoanCoverage.NONE;
};

const updateCurrentLoansCoverage = (currentLoans, summary) =>
  currentLoans.map((loan, currentLoanIndex) => ({
    ...loan,
    coverage: getLoanCoverage(currentLoanIndex, summary),
    remainingLoan: summary.remainingLoan,
  }));

/* eslint "import/prefer-default-export": "off" */
export const loanCalculatorPlugin = (state, action) => {
  if (
    action &&
    action.meta &&
    action.meta.form === 'loanCalculator' &&
    [
      actionTypes.CHANGE,
      actionTypes.ARRAY_SWAP,
      actionTypes.ARRAY_PUSH,
      actionTypes.ARRAY_REMOVE,
    ].includes(action.type)
  ) {
    const newState = JSON.parse(JSON.stringify(state));

    calculateChangedLoan(newState.values, action.meta.field);

    const normalizedLoans = normalizeLoans(newState.values.currentLoans || []);
    const currentLoansSummary = LoanCalculator.utils.calculateLoansSummary(normalizedLoans);
    let playBidsSummary;
    try {
      playBidsSummary = compareLoans(newState.values);
      if (
        newState.values.currentLoans &&
        playBidsSummary &&
        playBidsSummary.fullyCoveredLoans != null &&
        playBidsSummary.coveredLoans != null
      ) {
        newState.values.currentLoans = updateCurrentLoansCoverage(
          newState.values.currentLoans,
          playBidsSummary,
        );
      }
    } catch (error) {
      // TODO: Ensure proper error handling exposed to user
    }

    return {
      ...newState,
      currentSummary: currentLoansSummary,
      summary: playBidsSummary,
    };
  }
  return state;
};
