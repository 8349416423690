import { APPLICATION_OPTIMIZE_STATE } from '_js/constants/ApplicationOnHold';
import LOGOS from '../SE/CreditorLogos';
import creditors from '../SE/CreditorsName';

export default {
  phone: {
    number: '+46770175050',
    display: '0770 - 17 50 50',
  },
  email: 'kundtjanst@advisa.se',
  chatOpeningHours: {
    display: '09-20'
  },
  currency: {
    code: 'SEK',
    display: 'kr',
  },
  tracking: {
    googleTagManagerId: 'GTM-K6CDJWP',
    hotjarId: 3374880,
  },
  tink: {
    baseUrl: 'https://link.tink.com/1.0/reports/create-report',
    reportTypes: ['INCOME_CHECK_REPORT', 'RISK_INSIGHTS_REPORT', 'EXPENSE_CHECK_REPORT'],
    clientId: '194ca27c6ae047cbbb7fbea125b84720',
    redirectPath: '/application',
    scopes: {
      all: {
        accounts: 'read',
        transactions: 'read',
        user: 'read',
        identity: 'read',
        statistics: 'read',
        investments: 'read',
        credentials: 'read',
      },
      accountsOnly: {
        accounts: 'read',
      },
    },
  },
  homePageInsuranceLink: 'https://advisa.se/forsakringar/trygghetsforsakring/',
  optimizeSteps: APPLICATION_OPTIMIZE_STATE,
  logos: LOGOS,
  creditors,
  website: {
    url: 'https://advisa.se/',
    display: 'advisa.se',
  },
  languageSwitchSelect: `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="14" height="7"%3E%3Cpath fill="%23fff" fill-rule="evenodd" d="M5.732 6.56c.58.59 1.52.592 2.101 0l5.399-5.491C13.812.479 13.619 0 12.79 0H.776C-.05 0-.248.477.333 1.069L5.732 6.56z"/%3E%3C/svg%3E%0A');`,
};
