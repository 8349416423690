/* eslint-disable default-param-last */
import { getAuthorizationToken, getUrlToken } from '_js/utils/Authorization';
import { AUTHORIZED } from '_js/store/actions/auth';

let token = getAuthorizationToken();
const urlToken = getUrlToken(window.location.search);
if (token == null || (urlToken != null && token !== urlToken)) {
  token = urlToken;
}

export default (state = token, action) => {
  switch (action.type) {
    case AUTHORIZED:
      return action.payload.authorizationToken;

    default:
      return state;
  }
};
