import { string, func, object, shape, any } from 'prop-types';

export const intlConfigPropTypes = {
  locale: string,
  timeZone: string,
  formats: object,
  messages: object,
  textComponent: any,
  defaultLocale: string,
  defaultFormats: object,
  onError: func,
};

export const intlFormatPropTypes = {
  formatDate: func.isRequired,
  formatTime: func.isRequired,
  formatRelativeTime: func.isRequired,
  formatNumber: func.isRequired,
  formatPlural: func.isRequired,
  formatMessage: func.isRequired,
};

export default shape({
  ...intlConfigPropTypes,
  ...intlFormatPropTypes,
  formatters: shape(),
});
