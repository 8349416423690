import { APPLICATION_OPTIMIZE_STATE } from '_js/constants/ApplicationOnHold';
import LOGOS from '../FI/CreditorLogos';
import creditors from '../FI/CreditorsName';

export default {
  phone: {
    number: '+358942456000',
    display: '09 - 424 56 000',
  },
  email: 'asiakaspalvelu@sambla.fi',
  currency: {
    code: 'EUR',
    display: 'euro',
  },
  tracking: {
    googleTagManagerId: 'GTM-PTRCQ26',
    hotjarId: 3384895,
  },
  tink: {},
  optimizeSteps: {
    ADD_CURRENT_LOANS: APPLICATION_OPTIMIZE_STATE.ADD_CURRENT_LOANS,
  },
  logos: LOGOS,
  creditors,
  website: {
    url: 'https://www.sambla.fi/',
    display: 'sambla.fi',
  },
  languageSwitchSelect: `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="14" height="7"%3E%3Cpath fill="%23fff" fill-rule="evenodd" d="M5.732 6.56c.58.59 1.52.592 2.101 0l5.399-5.491C13.812.479 13.619 0 12.79 0H.776C-.05 0-.248.477.333 1.069L5.732 6.56z"/%3E%3C/svg%3E%0A');`,
};
