import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import { useState } from 'react';
import PropTypes from 'prop-types';

import devices from '../../styles/Devices';
import CloseCross from '../../../images/close-cross.svg?react';

const StyledModal = styled(ReactModal)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  max-width: 27.563rem;
  width: 80%;
  height: 337px;
  margin: auto;

  @media ${devices.smallerThanDesktop} {
    width: 84%;
  }
`;

export const CloseModal = styled.button`
  height: 25px;
  width: 25px;
  background-color: #f4f4f4;
  border-radius: 50%;
  display: inline-block;
  right: 0;
  top: 1.8125rem;
  position: absolute;
  cursor: pointer;
  border: 0;
  font-weight: 10;
  line-height: 1;
  color: black;

  &:focus {
    outline: 0;
  }
`;

const CloseIcon = styled(CloseCross)`
  display: inline-block;
  position: relative;
  line-height: normal;
  width: 0.9em;
`;

export const StyledH4 = styled.h4`
  text-align: left;
  display: flex;
  margin: 2rem 1.5rem;
  font-size: 1.625rem;
  font-weight: 600;
  justify-content: center;
`;

export const StyledP = styled.p`
  margin: 6px -1rem;
  font-weight: 300;
  text-align: center;

  @media ${devices.smallerThanDesktop} {
    margin: 6px 0;
  }
`;

const OverlayStyle = styled.div`
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: #0000008c;
  top: 0;
  left: 0;
`;

const StyledDiv = styled.div`
  margin: 6px -1rem;
  text-align: center;
`;
const MissingSharingNationalId = ({ invalidNationlId }) => {
  const [open, setOpen] = useState(invalidNationlId);

  return (
    <StyledModal
      isOpen={open}
      onRequestClose={() => {
        setOpen(false);
      }}
      ariaHideApp={false}
      // eslint-disable-next-line react/no-unstable-nested-components
      overlayElement={(props, contentElement) => (
        // eslint-disable-next-line react/no-unstable-nested-components
        <OverlayStyle {...props}>{contentElement}</OverlayStyle>
      )}
      overlayClassName="
        c-identification-modal
      "
    >
      <CloseModal
        className="react-modal-close"
        onClick={() => {
          setOpen(false);
        }}
      >
        <CloseIcon />
      </CloseModal>

      <StyledDiv
        className="
        c-card
        c-card--shadow
        c-card--center-text
        u-margin--none
        u-padding--horizontal-2em@desktop
      "
      >
        <StyledH4>
          <FormattedMessage
            id="gjeldsregisteret.consent.ops"
            defaultMessage="Oops!"
            description="Header ppi confirmation accept"
          />
        </StyledH4>
        <StyledP>
          <FormattedMessage
            id="gjeldsregisteret.consent.missingSSNs"
            defaultMessage="It seems that you have not provided your Social Security Number (SSN). In order to proceed with the process, we kindly ask you to go back and select the option that allows you to share your identification with us.{br} {br} Your SSN is essential for verification purposes and ensuring the security of your account. {br} {br} Thank you!"
            description="Confirm the text above"
            tagName=""
            values={{ br: <br /> }}
          />
        </StyledP>
      </StyledDiv>
    </StyledModal>
  );
};
MissingSharingNationalId.propTypes = {
  invalidNationlId: PropTypes.bool,
};
MissingSharingNationalId.defaultProps = {
  invalidNationlId: false,
};
export default MissingSharingNationalId;
