export const SORT_UNCHANGED = 0;

export const sortBy =
  (...order) =>
  (itemA, itemB) =>
    order.reduce((currentSortOrder, compare) => {
      if (currentSortOrder === 0) {
        return compare(itemA, itemB);
      }
      return currentSortOrder;
    }, SORT_UNCHANGED);
