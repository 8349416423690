import PropTypes from 'prop-types';

export const propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  sticky: PropTypes.bool,
  outline: PropTypes.bool,
  primary: PropTypes.bool,
  fullWidth: PropTypes.bool,
  href: PropTypes.string,
  children: PropTypes.node,
};

export const defaultProps = {
  children: null,
  disabled: false,
  isLoading: false,
  outline: false,
  primary: false,
  fullWidth: false,
  onClick: () => {},
  sticky: false,
  href: null,
};
