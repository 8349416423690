/* eslint-disable default-param-last */
import { UPDATE_ACCEPT_NEWSLETTER_STATUS } from '../actions/newsletter';

export const initialState = {
  status: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ACCEPT_NEWSLETTER_STATUS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
