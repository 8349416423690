import FAQse from '_js/constants/QandA/QandA-SE';
import FAQfi from '_js/constants/QandA/QandA-FI';
import FAQno from '_js/constants/QandA/QandA-NO';

const FAQ = ((region) => {
  switch (region) {
    case 'SE':
      return FAQse;
    case 'FI':
      return FAQfi;
    case 'NO':
      return FAQno;
    default:
      return FAQse;
  }
})(import.meta.env.VITE_REGION || 'SE');

export default FAQ;
