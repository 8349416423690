const green = '#008539';
const black = '#333';
const rubin = '#570014';
const lightRubin = '#a04';
const red = '#DF202D';
const whitesmoke = '#f5f5f5';
const white = '#fff';
const purple = '#70144a';
const blue = '#2b3845';
const deepGray = '#c6c6c6';
const offWhiteTinted = '#cec8c1';
const grey = '#c7c2c2';
const transparentTeal = '#e5f8f9';
const acceptTeal = '#0bb1b9';
const neutralGrey50 = '#f4f4f410';
const neutralGrey100 = '#E0E0E020';
const pink = '#ffc7c7';

export default {
  id: 'sambla',
  loading: {
    background: rubin,
    bounce: whitesmoke,
    fontColor: whitesmoke,
  },
  fonts: {
    p: {
      small: '0.7500em',
      medium: '0.8750em',
      mediumSmall: '0.9375em',
      mediumlarge: '1em',
      large: '1.5em',
    },
    button: {
      medium: '1.125em',
      large: '1.125em',
    },
    headingsize: {
      small: '1.1em',
      medium: '1.5em',
      large: '2em',
    },
    subheading: {
      large: '1.5em',
    },
    bidlistfont: {
      size: '0.8750em',
    },
    color: white,
  },
  colors: {
    primary: red,
    button: {
      primary: {
        text: white,
        background: green,
      },
      secondary: {
        text: white,
        background: purple,
      },
      tertiary: {
        text: white,
        bg: {
          default: 'transparent',
          hover: neutralGrey50,
          pressed: neutralGrey100,
        },
      },
    },
    navlink: {
      color: rubin,
    },
    handlebutton: {
      color: {
        closed: black,
        open: black,
      },
      bg: {
        closed: 'transparent',
        open: 'transparent',
      },
    },
    carousel: {
      dot: red,
      arrow: rubin,
    },
    details: {
      color: red,
    },
    numbers: {
      color: whitesmoke,
    },
    circle: {
      color: rubin,
      background: red,
      boxShadow: red,
    },
    expand: {
      background: blue,
    },
    checkbullet: {
      color: green,
    },
    arrow: {
      color: red,
      chevron: white,
    },
    body: {
      background: whitesmoke,
    },
    chevronArrow: {
      color: rubin,
    },
    slider: {
      color: red,
    },
    border: {
      color: green,
    },
    spinner: {
      color: deepGray,
    },
    selectedOption: {
      color: red,
    },
    acceptedBid: {
      border: acceptTeal,
      color: transparentTeal,
    },
    lucideIcons: {
      primary: rubin,
      secondary: red,
    },
    accordion: {
      background: white,
      border: black,
      body: black,
      title: black,
      chevron: black,
    },
    infoCircle: {
      fill: rubin,
    },
    myPagesAd: {
      background: whitesmoke,
      header: rubin,
    },
  },
  styled: {
    button: rubin,
    arrowButton: rubin,
  },
  spinner: {
    border: lightRubin,
  },
  header: {
    background: {
      color: rubin,
    },
  },
  bidPage: {
    separator: whitesmoke,
  },
  breadcrumb: {
    background: {
      color: rubin,
      bullet: white,
    },
    border: white,
    line: offWhiteTinted,
    checkIcon: rubin,
    activeColor: white,
    fontColor: grey,
    bulletFontColor: white,
  },
  burgerMenuLi: {
    background: {
      color: rubin,
    },
  },
  headerText: {
    color: blue,
  },
  bidListBorder: {
    color: 'none',
    radius: '5px',
  },
  gjeldsregisteret: {
    privateLoans: {
      background: rubin,
      color: white,
    },
    creditCard: {
      background: red,
      color: white,
    },
    changeCards: {
      background: pink,
      color: black,
    },
    button: {
      radius: '5px',
    },
  },
  insurance: {
    borderColor: rubin,
    iconColor: red,
    backgroundColor: white,
    textColor: rubin,
  },
};
