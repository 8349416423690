import * as Sentry from '@sentry/react';

import { postAuthorizationCode, getSessionId } from '../../../../utils/api/API';

export const COMPLETE_TINK = 'COMPLETE_TINK';
export const TINK_ERROR = 'TINK_ERROR';
export const TINK_RESET_ERROR = 'TINK_RESET_ERROR';
export const SESSION_ID_SUCCESS = 'SESSION_ID_SUCCESS';

export const completeTink = () => ({
  type: COMPLETE_TINK,
});

export const tinkError = (payload) => ({
  type: TINK_ERROR,
  payload,
});

export const resetTinkError = () => ({
  type: TINK_RESET_ERROR,
});

export const setSuccessfulSessionId = (payload) => ({
  type: SESSION_ID_SUCCESS,
  payload,
});

export const setSessionId = async (dispatch, applicationId, brand, SSN) => {
  try {
    const persistedSessionId = localStorage.getItem('persisted-tink-session-id');

    if (persistedSessionId) {
      dispatch(setSuccessfulSessionId({ data: persistedSessionId }));
    } else {
      try {
        const sessionIdPayload = await getSessionId(applicationId, brand, SSN);

        if (sessionIdPayload && sessionIdPayload.data) {
          localStorage.setItem('persisted-tink-session-id', sessionIdPayload.data);
          dispatch(setSuccessfulSessionId(sessionIdPayload));
        } else {
          throw new Error('Invalid session ID payload received.');
        }
      } catch (error) {
        throw new Error(`Failed to fetch session ID: ${error.message}`);
      }
    }
  } catch (localStorageError) {
    Sentry.captureException(localStorageError);
  }
};

export const tinkSuccess =
  (applicationId, reportsGenerationJobId, requestedResources) => async (dispatch) => {
    try {
      await postAuthorizationCode(applicationId, reportsGenerationJobId, requestedResources);
      dispatch(completeTink());
    } catch (error) {
      dispatch(tinkError(error.type));
      Sentry.captureException(error);
    }
  };
