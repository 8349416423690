/* eslint-disable default-param-last */
import {
  FETCH_APPLICATION_LOGS,
  FETCH_APPLICATION_LOGS_SUCCESS,
  FETCH_APPLICATION_LOGS_FAILED,
} from '_js/store/actions/application-logs/fetch';

export const initialState = {
  loading: false,
  logs: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_APPLICATION_LOGS:
      return { ...state, loading: true };

    case FETCH_APPLICATION_LOGS_SUCCESS:
      return { ...state, logs: action.payload, loading: false };

    case FETCH_APPLICATION_LOGS_FAILED:
      return { ...state, loading: false };

    default:
      return state;
  }
};
