import { MonthlyFixedAmortizationLoan, MonthlyFixedPaymentLoan } from '@advisa/loan-calculator';

export const repaymentTimeDisplay = (repaymentMonths) => {
  const repaymentYears = repaymentMonths / 12;
  if (repaymentMonths % 12 === 0) {
    return Math.round(repaymentYears).toString();
  }

  return repaymentYears.toFixed(1);
};

const DEFAULT_EMPTY_CALCULATIONS = {
  totalCost: 0,
  apr: 0,
  firstPayment: 0,
  averageMonthlyPayment: 0,
  lastPayment: 0,
  remainingPayment: 0,
};

export const calculateLoan = (loan) => {
  const attrs = {
    amount: loan.amount,
    arrangementFee: loan.arrangementFee,
    administrationFee: loan.administrationFee,
    interest: loan.interestRate / 100,
  };

  let LoanType;
  if (loan.repaymentType === 'annuity') {
    LoanType = MonthlyFixedPaymentLoan;
  } else if (loan.repaymentType === 'fixed-amortization') {
    LoanType = MonthlyFixedAmortizationLoan;
  }

  const isMonthlyPaymentBased = loan.significantKey === 'monthlyPayment';

  const calculatedLoan =
    LoanType &&
    new LoanType({
      ...attrs,
      repaymentTime: !isMonthlyPaymentBased ? loan.repaymentTime : null,
      monthlyPayment: isMonthlyPaymentBased ? loan.monthlyPayment : null,
    });

  if (!LoanType || !calculatedLoan.isValid()) {
    return {
      ...loan,
      ...DEFAULT_EMPTY_CALCULATIONS,
      error: calculatedLoan ? calculatedLoan.error.message : 'No loan type selected',
    };
  }

  const payments = calculatedLoan.getPayments();
  const totalCost = Math.ceil(calculatedLoan.getTotalCost() - loan.amount);
  const repaymentTime = calculatedLoan.getRepaymentTime();
  const apr = calculatedLoan.getAPR();
  const arrangementFee = loan.arrangementFee || 0;
  const lastPayment = isMonthlyPaymentBased
    ? payments[payments.length - 2]
    : payments[payments.length - 1];

  return {
    ...loan,
    totalCost,
    error: null,
    repaymentTime: isMonthlyPaymentBased ? repaymentTime : loan.repaymentTime,
    monthlyPayment: !isMonthlyPaymentBased ? payments[0] : loan.monthlyPayment,
    apr: apr * 100,
    firstPayment: payments.length ? payments[0] : 0,
    averageMonthlyPayment: (totalCost - arrangementFee) / repaymentTime,
    lastPayment: lastPayment || 0,
    remainingPayment: isMonthlyPaymentBased ? payments[payments.length - 1] : 0,
  };
};

export const getTotalLoanAmount = (mergeLoanAmount, newLoanAmount) => {
  let amount = 0;

  if (mergeLoanAmount) {
    amount += Number(mergeLoanAmount);
  }

  if (newLoanAmount) {
    amount += Number(newLoanAmount);
  }
  return amount;
};
