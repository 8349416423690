export const isStaging = () => import.meta.env.VITE_ENV === 'staging';
export const isProductionOrStaging = () => process.env.NODE_ENV === 'production';
export const isProduction = () => isProductionOrStaging() && !isStaging();
export const isDevelopment = () => !isProductionOrStaging();
export const isDevelopmentOrStaging = () => isDevelopment() || isStaging();
export const isTest = () => process.env.NODE_ENV === 'test';
export const isCircleCI = () => !!process.env.CIRCLECI;
export const getEnvironment = () =>
  (import.meta.env.VITE_ENV || process.env.NODE_ENV).toLocaleLowerCase();

export default () => process.env;
