import TRACKING from '_js/constants/Tracking';
import * as Sentry from '@sentry/react';
import { isDevelopment, isProduction } from '_js/utils/environment';
import { load, sendEvent } from '_js/utils/3rdparty/gtm';
import { isCrmUser } from '_js/utils/crm';

export const doNotTrack = () => (isCrmUser() && isProduction()) || isDevelopment();

export const gtm = () => {
  if (doNotTrack() || !TRACKING.googleTagManagerId) return;

  load({ id: TRACKING.googleTagManagerId });
};

export const trackEvent = ({ category, action, label, value, nonInteractiveEvent }) => {
  if (doNotTrack()) return;

  sendEvent({
    event: action,
    event_category: category,
    event_label: label,
    value,
    non_interaction: nonInteractiveEvent,
  });

  const eventData = {
    category,
    action,
  };

  if (label) eventData.label = label;
  if (value) eventData.value = value;

  Sentry.addBreadcrumb({
    type: 'default',
    level: 'info',
    category: nonInteractiveEvent ? 'tracking' : 'ui.action',
    message: 'Tracking event',
    data: eventData,
  });
};

export const hotjar = () => {
  if (doNotTrack()) return;
  if (!TRACKING.hotjarId) return;

  /* eslint-disable */
  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: TRACKING.hotjarId, hjsv: 5 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=');
  /* eslint-enable */
};

export const trackPageView = () => {
  if (doNotTrack()) return;
  if (TRACKING.hotjarId && window.hj) {
    window.hj('stateChange', window.location.href);
  }
};

export const EVENT_NAMES = {
  BIDDING_RECEIVED: 'AddToWishlist',
};
