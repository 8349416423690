import { FormattedMessage } from 'react-intl';
import { FormattedNumberWithFallback } from '_js/utils/FormattedNumberWithFallback';
import HashLink from '_js/utils/HashLink';
import { APPLICATION, WEBSITE_STATES } from '_js/constants/States';
import { APPLICATION_OPTIMIZE_STATE } from '_js/constants/ApplicationOnHold';
import { getBrandConstants, getBrandQandA, getBrand } from '_js/brands';
import localeData from '_js/localizations/locale-data';

const phone = { ...getBrandConstants().phone };
const brandName = getBrand();
const numberOfCreditors = localeData.NUM_OF_CREDITORS;
const teleHref = `tel:${phone.number}`;
const hashLink = (chunks) => (
  <HashLink targetId="what-happens-now-creditor-logo">{chunks}</HashLink>
);
const teleLink = (chunks) => <a href={teleHref}>{chunks}</a>;
const teleLinkDisplay = <a href={teleHref}>{phone.display}</a>;
const list = (chunks) => <ul>{chunks}</ul>;
const listItem = (chunks) => <li>{chunks}</li>;

const PPIAutogiro = [
  {
    id: 'autogiroBenefits',
    question: (
      <FormattedMessage
        id="faq.sv.question.insurance.direct.debit.benefit"
        defaultMessage="What are the benefits of Direct debit?"
        description="FAQ question for benefits of direct debit"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.answer.insurance.benefits"
        defaultMessage="Direct debit is the easiest way to pay bills. The premium for the insurance is automatically deducted from the account monthly, which reduces the risk of a payment being forgotten. By paying by direct debit, you save time and also protects the environment by choosing a paperless payment method."
        description="FAQ answer for benefits of direct debit."
        tagName=""
      />
    ),
  },
  {
    id: 'howDoesPaymentWorkForInsurance',
    question: (
      <FormattedMessage
        id="faq.sv.question.insurance.howDoesPaymentWorkForInsurance"
        defaultMessage="How do I pay for the insurance?"
        description="FAQ question displayed at the bottom when the customer is signing an insurance"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.sv.answer.insurance.howDoesPaymentWorkForInsurance"
        defaultMessage="<p>The insurance is paid once a month. Payment can either be made via paper invoices or direct debit. </p>"
        description="FAQ answer displayed at the bottom when the customer is signing an insurance"
        tagName=""
      />
    ),
  },
  {
    id: 'periodAndNotice',
    question: (
      <FormattedMessage
        id="faq.sv.question.insurance.periodAndNotice"
        defaultMessage="Is there a commitment period or notice period?"
        description="FAQ question displayed at the bottom when the customer is signing an insurance"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.sv.answer.insurance.periodAndNotice"
        defaultMessage="<p>No, the insurance doesn't have a commitment period or a notice period. You only pay for one month at a time.</p>"
        description="FAQ answer displayed at the bottom when the customer is signing an insurance"
        tagName=""
      />
    ),
  },
];

const PPI = [
  {
    id: 'whyToHaveAnInsurance',
    question: (
      <FormattedMessage
        id="faq.sv.question.insurance.whyToHaveAnInsurance"
        defaultMessage="Why is it a good idea to have disability income insurance?"
        description="FAQ question displayed at the bottom when the customer is signing an insurance"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.sv.answer.insurance.whyToHaveAnInsurance"
        defaultMessage="<p>You never know when an unforeseen event will occur. Disability income insurance helps you pay for your fixed monthly expenses in the event you become ill/injured, involuntarily unemployed or become responsible for the care of a close relative.</p><p>Life insurance also covers your dependents if you pass away prematurely.</p>"
        description="FAQ answer displayed at the bottom when the customer is signing an insurance"
        tagName=""
      />
    ),
  },
  {
    id: 'insuranceCovers',
    question: (
      <FormattedMessage
        id="faq.sv.question.insurance.insuranceCovers"
        defaultMessage="What does the insurance cover?"
        description="FAQ question displayed at the bottom when the customer is signing an insurance"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.sv.answer.insurance.insuranceCovers"
        defaultMessage="<p>Disability income insurance covers you if you become involuntarily unemployed, take long-term sick leave or become responsible for the care of a close relative. Life insurance covers your dependents if you pass away prematurely.</p>"
        description="FAQ answer displayed at the bottom when the customer is signing an insurance"
        tagName=""
      />
    ),
  },
  {
    id: 'howLongIsInsuranceValid',
    question: (
      <FormattedMessage
        id="faq.sv.question.insurance.howLongIsInsuranceValid"
        defaultMessage="How long does my disability income insurance coverage last?"
        description="FAQ question displayed at the bottom when the customer is signing an insurance"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.sv.answer.insurance.howLongIsInsuranceValid"
        defaultMessage="<p>You will receive insurance payments for the amount you've chosen for up to 12 months or until the policyholder turns 67.</p>"
        description="FAQ answer displayed at the bottom when the customer is signing an insurance"
        tagName=""
      />
    ),
  },
  {
    id: 'whatCostDoesTheInsuranceCovers',
    question: (
      <FormattedMessage
        id="faq.sv.question.insurance.whatCostDoesTheInsuranceCovers"
        defaultMessage="What costs can I cover with disability income insurance?"
        description="FAQ question displayed at the bottom when the customer is signing an insurance"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.sv.answer.insurance.whatCostDoesTheInsuranceCovers"
        defaultMessage="<p>Disability income insurance is paid to your account tax free. <strong>It is entirely up to you to choose how you want to use it to pay for your expenses, for example, your loans, rent, food, etc.</strong></p>"
        description="FAQ answer displayed at the bottom when the customer is signing an insurance"
        tagName=""
      />
    ),
  },
  {
    id: 'notPayingInsurance',
    question: (
      <FormattedMessage
        id="faq.sv.question.insurance.notPayingInsurance"
        defaultMessage="What happens if I don't pay the insurance?"
        description="FAQ question displayed at the bottom when the customer is signing an insurance"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.sv.answer.insurance.notPayingInsurance"
        defaultMessage="<p>If you miss payments two months in a row, your insurance will automatically be terminated.</p>"
        description="FAQ answer displayed at the bottom when the customer is signing an insurance"
        tagName=""
      />
    ),
  },
  {
    id: 'whoIsCovered',
    question: (
      <FormattedMessage
        id="faq.sv.question.insurance.whoIsCovered"
        defaultMessage="Who does the insurance cover?"
        description="FAQ question displayed at the bottom when the customer is signing an insurance"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.sv.answer.insurance.whoIsCovered"
        defaultMessage="<p>The insurance is personal and only covers the policyholder.</p>"
        description="FAQ answer displayed at the bottom when the customer is signing an insurance"
        tagName=""
      />
    ),
  },
  {
    id: 'whatDiffersForThisInsurace',
    question: (
      <FormattedMessage
        id="faq.sv.question.insurance.whatDiffersForThisInsurace"
        defaultMessage="What is the difference between disability income insurance and loan protection insurance or income insurance, for example?"
        description="FAQ question displayed at the bottom when the customer is signing an insurance"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.sv.answer.insurance.whatDiffersForThisInsurace"
        defaultMessage="<p>With loan protection insurance, you can only pay off the loan. Disability insurance payouts are sent to your account, and you decide which of your expenses you want to use them for.</p><p>Income insurance covers you only in cases of involuntary unemployment, and not in cases of sick leave. Disability insurance helps you in cases of both involuntary unemployment and long-term sick leave.</p>"
        description="FAQ answer displayed at the bottom when the customer is signing an insurance"
        tagName=""
      />
    ),
  },
].concat(PPIAutogiro);

const BidsOnDecline = [
  {
    id: 'bidsOnDeclineLowerAmount',
    question: (
      <FormattedMessage
        id="faq.sv.question.bidsOnDecline.bidsOnDeclineLowerAmount"
        defaultMessage="Why should I apply for a smaller amount?"
        description="FAQ question displayed at the bottom when the customer did not receive any loan proposals"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.sv.answer.bidsOnDecline.bidsOnDeclineLowerAmount"
        defaultMessage="<p>In general, lenders offer the amount they are able to, regardless of the amount you applied for. However, we have noticed that some of our lenders sometimes act differently if you apply for a smaller amount. Go ahead and change the amount you're applying for and see if you can get a loan offer. Keep in mind that this does <strong>not</strong> require another credit check.</p>"
        description="FAQ answer displayed at the bottom when the customer did not receive any loan proposals"
        tagName=""
      />
    ),
  },
  {
    id: 'bidsOnDeclineNewUc',
    question: (
      <FormattedMessage
        id="faq.sv.question.bidsOnDecline.bidsOnDeclineNewUc"
        defaultMessage="Do I need to do another credit check through UC?"
        description="FAQ question displayed at the bottom when the customer did not receive any loan proposals"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.sv.answer.bidsOnDecline.bidsOnDeclineNewUc"
        defaultMessage="<p>No, you do <strong>not</strong> need to do another credit check.</p>"
        description="FAQ answer displayed at the bottom when the customer did not receive any loan proposals"
        tagName=""
      />
    ),
  },
];

const awaiting = [
  {
    id: 'awaitingMoreBids',
    question: (
      <FormattedMessage
        id="faq.sv.question.awaiting.awaitingMoreBids"
        defaultMessage="Am I still waiting for more offers?"
        description="FAQ question displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.sv.answer.awaiting.awaitingMoreBids"
        defaultMessage="<p>Yes, more offers may still come in. Once all of the lenders have submitted their offers, you will receive an email and text message confirming that your application has been processed. To be able to make a well-informed decision, we recommend waiting until all lenders have made their offers.</p>"
        description="FAQ answer displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
  },
  {
    id: 'singleCreditCheck',
    question: (
      <FormattedMessage
        id="faq.sv.question.awaiting.singleCreditCheck"
        defaultMessage="Was only one credit report run?"
        description="FAQ question displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.sv.answer.awaiting.singleCreditCheck"
        defaultMessage="<p>Yes, we only run <strong>one (1) credit report through UC</strong>. However, all the lenders included in the comparison have to access the report. That is why so many letters are sent to you. Remember: the letters are not registered as credit reports and do not affect your credit worthiness.</p>"
        description="FAQ answer displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
  },
  {
    id: 'freeAmortization',
    question: (
      <FormattedMessage
        id="faq.sv.question.awaiting.freeAmortization"
        defaultMessage="Can I pay off more of my loan whenever I want?"
        description="FAQ question displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.sv.answer.awaiting.freeAmortization"
        defaultMessage="<p>Yes, you can always pay off more. You can pay a little more every month or a larger amount when you want, or you can even pay off your entire loan without paying any additional fees. You only pay interest for the time you have active debt.</p>"
        description="FAQ answer displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
  },
  {
    id: 'whatIfPayBackEarlier',
    question: (
      <FormattedMessage
        id="faq.sv.question.awaiting.whatIfPayBackEarlier"
        defaultMessage="What happens if I pay off my loan earlier?"
        description="FAQ question displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.sv.answer.awaiting.whatIfPayBackEarlier"
        defaultMessage="<p>Paying off your loan early is only beneficial for you. The sooner you pay off your debt, the sooner you are debt free and have lower monthly costs. By paying off your loan in advance, you save the money that would have otherwise gone to the lender in interest fees.</p>"
        description="FAQ answer displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
  },
  {
    id: 'isInterestRateTaxDeductible',
    question: (
      <FormattedMessage
        id="faq.sv.question.awaiting.isInterestRateTaxDeductible"
        defaultMessage="Is the interest on my loan tax deductible when I file my return?"
        description="FAQ question displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.sv.answer.awaiting.isInterestRateTaxDeductible"
        defaultMessage="<p>Yes, interest on private loans is tax deductible. Interest charges up to {amount} are tax deductible by 30%.</p>"
        description="FAQ answer displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
        values={{
          amount: <FormattedNumberWithFallback value={10000} formatAs="currency" />,
        }}
      />
    ),
  },
  {
    id: 'haveBeenDeclined',
    question: (
      <FormattedMessage
        id="faq.sv.question.awaiting.haveBeenDeclined"
        defaultMessage="Has my application been denied?"
        description="FAQ question displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.sv.answer.awaiting.haveBeenDeclined"
        defaultMessage="<p>No, not all of the lenders have had a chance to review your application yet. We will email you as soon as your application has been fully processed.</p>"
        description="FAQ answer displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
  },
  {
    id: 'whenPayout',
    question: (
      <FormattedMessage
        id="faq.sv.question.awaiting.whenPayout"
        defaultMessage="When will I get the money?"
        description="FAQ question displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.sv.answer.awaiting.whenPayout"
        defaultMessage="<p>That varies from lender to lender, but usually within one or a few days from when you accept the offer.</p><p>The timeline for when the loan is paid out depends on multiples factors. Factors include whether or not the lender uses electronic signature. Generally, electronic signatures are much faster than waiting on the post. To make the process as efficient as possible, it is a good idea to gather and prepare any possible supplementary information that the lender may request (for example, a copy of an ID document, a payslip or employer's certificate).</p>"
        description="FAQ answer displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
  },
];

const onHoldCoApplicant = [
  {
    id: 'coApplicantWhatIsIt',
    question: (
      <FormattedMessage
        id="faq.sv.question.onHoldCoApplicant.coApplicantWhatIsIt"
        defaultMessage="What is a co-applicant?"
        description="FAQ question displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.sv.answer.onHoldCoApplicant.coApplicantWhatIsIt"
        defaultMessage="<p>A co-applicant is a second person on the loan who is included to provide additional security. In general, having a co-applicant significantly strengthens your application, increasing your chances of getting better loan terms.</p>"
        description="FAQ answer displayed at the bottom when the customer is optimizing their application"
        tagName=""
      />
    ),
  },
  {
    id: 'coApplicantWhyIsItGood',
    question: (
      <FormattedMessage
        id="faq.sv.question.onHoldCoApplicant.coApplicantWhyIsItGood"
        defaultMessage="Why is it a good idea to have a co-applicant?"
        description="FAQ question displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.sv.answer.onHoldCoApplicant.coApplicantWhyIsItGood"
        defaultMessage="<p>By adding another person to your application who will share the loan, you increase the chances of getting the full loan amount requested, as well as better loan terms. When two people are on the application, the banks see this as double the security. However, remember that your co-applicant needs to have good, steady finances to be able to increase your chances of getting better loan terms.</p>"
        description="FAQ answer displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
  },
  {
    id: 'coApplicantWhoCanItBe',
    question: (
      <FormattedMessage
        id="faq.sv.question.onHoldCoApplicant.coApplicantWhoCanItBe"
        defaultMessage="Who can be a co-applicant?"
        description="FAQ question displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.sv.answer.onHoldCoApplicant.coApplicantWhoCanItBe"
        defaultMessage="<p>Anyone you trust. It is common for people to include their spouse or partner as a co-applicant. You can also use a parent, another relative or a friend. Always talk with your co-applicant before applying for a loan.</p>"
        description="FAQ answer displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
  },
];

const FAQse = {
  [APPLICATION.PPI_SIGNING]: PPI,
  [APPLICATION.PPI_DIRECT_DEBT]: PPIAutogiro,
  [APPLICATION.AWAITING]: awaiting,
  [APPLICATION_OPTIMIZE_STATE.ADD_CO_APPLICANT]: onHoldCoApplicant,
  [WEBSITE_STATES.BIDDING_ONGOING_HAS_BIDS]: awaiting.concat([
    {
      id: 'sortingOrder',
      question: (
        <FormattedMessage
          id="faq.sv.question.biddingOngoingHasBids.sortingOrder"
          defaultMessage="How is the list of loan offers sorted?"
          description="FAQ question displayed at the bottom when the customer is waiting for loan proposals and has received proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.biddingOngoingHasBids.sortingOrder"
          defaultMessage="<p>By default, we always show the loans with the lowest effective interest rate first, with higher effective interest rates the further down the list you go. We also let you know whether the lender was able to give you the entire amount requested, or as close to the requested amount as possible.</p><p>You are then able to sort the offers based on other parameters as needed.</p> "
          description="FAQ answer displayed at the bottom when the customer is waiting for loan proposals and has received proposals"
          tagName=""
        />
      ),
    },
  ]),
  [APPLICATION.HAS_BIDS]: [
    {
      id: 'whatHappensNow',
      question: (
        <FormattedMessage
          id="faq.sv.question.hasBids.whatHappensNow"
          defaultMessage="What happens now?"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.hasBids.whatHappensNow"
          defaultMessage="<p>The bidding process is now over, and your offers have been received. Because different lenders target different types of loans and different types of customers, all of the lenders who are willing to issue you a loan based on your application have now given their offer.</p><p>All that is left to do now is choose the best offer for you. After that, you will sign the loan agreement, and the money will be issued.</p>"
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
    },
    {
      id: 'whenBiddingFinished',
      question: (
        <FormattedMessage
          id="faq.sv.question.hasBids.whenBiddingFinished"
          defaultMessage="When will I get all of my loan offers/decisions?"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.hasBids.whenBiddingFinished"
          defaultMessage="<p>All of the lenders included in the comparison have now given their offers. If you would like help choosing the best option for you, feel free to <teleLink>contact us</teleLink>. We're here to help!</p>"
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
          values={{
            teleLink,
          }}
        />
      ),
    },
    {
      id: 'whatHappensWhenOfferAccepted',
      question: (
        <FormattedMessage
          id="faq.sv.question.hasBids.whatHappensWhenOfferAccepted"
          defaultMessage="What happens when I accept an offer?"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.hasBids.whatHappensWhenOfferAccepted"
          defaultMessage="<p>When you accept an offer, the lender you chose will send you an agreement, either electronically or by post. This does <strong>not</strong> mean that you have signed a loan offer.</p>"
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
    },
    {
      id: 'isLoanOfferAcceptBinding',
      question: (
        <FormattedMessage
          id="faq.sv.question.hasBids.isLoanOfferAcceptBinding"
          defaultMessage="Am I locked into a loan if I accept?"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.hasBids.isLoanOfferAcceptBinding"
          defaultMessage="<p>No. The offer will be sent to you. Don't hesitate to reach out to us if you want us to go through the offer with you and compare it with your current loans, for example, if you are going to use the loan to replace your old loans.</p>"
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
    },
    {
      id: 'whichLoanOfferToChoose',
      question: (
        <FormattedMessage
          id="faq.sv.question.hasBids.whichLoanOfferToChoose"
          defaultMessage="How do I know which loan to choose?"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.hasBids.whichLoanOfferToChoose"
          defaultMessage="<p>That depends on what is important to you. In general, the cheapest loan will be the one that has the lowest effective interest rate, but it can also be important to look at the monthly payment amount and try to get the lowest one possible. Sometimes it's better to find the loan that consolidates the most debt. Feel free to get in contact with us if you need advice. We can change the amount you applied for, as well as the repayment terms to help get you the best offer.</p>"
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
    },
    {
      id: 'needHelpHaveQuestions',
      question: (
        <FormattedMessage
          id="faq.sv.question.hasBids.needHelpHaveQuestions"
          defaultMessage="I need help or have questions"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.hasBids.needHelpHaveQuestions"
          defaultMessage="<p>No problem. <teleLink>Contact us</teleLink> for assistance or guidance. We're here to help make things easier for you.</p>"
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
          values={{
            teleLink,
          }}
        />
      ),
    },
    {
      id: 'whyNotMoreLoanOffers',
      question: (
        <FormattedMessage
          id="faq.sv.question.hasBids.whyNotMoreLoanOffers"
          defaultMessage="Why didn't I get more offers?"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.hasBids.whyNotMoreLoanOffers"
          defaultMessage="<p>{brandName} currently works with {numberOfCreditors} lenders, each of whom target different types of loans and different types of customers. This means that, in some cases, certain lenders may choose not to offer a loan regardless of how reliable the lender deems you as a customer. Sometimes this is because the lender knows that there are other actors who are specialized in your specific type of loan and will therefore be able to provide a better offer. As a result, this means that you won't receive {numberOfCreditors} offers, but all of the lenders will review your application and respond with a yes or a no.</p>"
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
          values={{
            brandName,
            numberOfCreditors,
          }}
        />
      ),
    },
    {
      id: 'haveToDecideNow',
      question: (
        <FormattedMessage
          id="faq.sv.question.hasBids.haveToDecideNow"
          defaultMessage="Do I have to make a decision now?"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.hasBids.haveToDecideNow"
          defaultMessage="<p>All loan offers are only valid for a limited time, so it is important that you don't take too long to make a decision. It is important to consider the amount of time it takes for a lender to send a loan offer, as well as the time it takes for you to provide supplementary information, if needed. Contact us for more detailed information on your loan offers.</p>"
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
    },
    {
      id: 'whatIfNotSatisfiedWithOffers',
      question: (
        <FormattedMessage
          id="faq.sv.question.hasBids.whatIfNotSatisfiedWithOffers"
          defaultMessage="What should I do if I'm not happy with my offers?"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.hasBids.whatIfNotSatisfiedWithOffers"
          defaultMessage="<p><teleLink>Contact us</teleLink> directly to see if we can modify your offers to better suit you and your needs. For example, we may be able change the repayment terms to ensure your monthly payment would fit your budget. If you didn't get any loan offers, it might be a good idea to add a co-applicant, which can make you more attractive to lenders.</p>"
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
          values={{
            teleLink,
          }}
        />
      ),
    },
    {
      id: 'canChangeLoanAmountRepaymentTime',
      question: (
        <FormattedMessage
          id="faq.sv.question.hasBids.canChangeLoanAmountRepaymentTime"
          defaultMessage="Can I change the amount I applied for or the loan term?"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.hasBids.canChangeLoanAmountRepaymentTime"
          defaultMessage="<p>Of course! <teleLink>Contact us</teleLink> and we'll update your loan to best meet your needs.</p>"
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
          values={{
            teleLink,
          }}
        />
      ),
    },
    {
      id: 'whyNotWholeAmountGranted',
      question: (
        <FormattedMessage
          id="faq.sv.question.hasBids.whyNotWholeAmountGranted"
          defaultMessage="Why is my loan offer less than what I applied for?"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.hasBids.whyNotWholeAmountGranted"
          defaultMessage="<p>All lenders offer the maximum amount they are willing to offer you based on the loan amount you have applied for. If a lender has offered a lower amount than what you applied for, their offer reflects the largest loan they are prepared to issue you. If you did a loan comparison but would like to apply for a higher amount, <teleLink>contact us</teleLink> and we'll take care of it.</p>"
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
          values={{
            teleLink,
          }}
        />
      ),
    },
    {
      id: 'whatIsRequirement',
      question: (
        <FormattedMessage
          id="faq.sv.question.hasBids.whatIsRequirement"
          defaultMessage='What does "supplementary information" mean?'
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.hasBids.whatIsRequirement"
          defaultMessage="<p>Supplementary information means that the lender needs additional information from you or needs to confirm the information that you provided in your application. Examples of supplementary information may include a copy of a valid ID document, a payslip or an employer's certificate.</p>"
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
    },
    {
      id: 'cannotEnterAccountNumber',
      question: (
        <FormattedMessage
          id="faq.sv.question.hasBids.cannotEnterAccountNumber"
          defaultMessage="I can't enter my account number when I accept?"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.hasBids.cannotEnterAccountNumber"
          defaultMessage="<p>Call us on <strong>{teleLinkDisplay}</strong> for assistance.</p>"
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
          values={{
            teleLinkDisplay,
          }}
        />
      ),
    },
    {
      id: 'whatIsDebtAcquittal',
      question: (
        <FormattedMessage
          id="faq.sv.question.hasBids.whatIsDebtAcquittal"
          defaultMessage="What does a debt acquittal mean?"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.hasBids.whatIsDebtAcquittal"
          defaultMessage='<p>A debt acquittal means that the lender will issue you the loan, on the condition that they be able to pay off your previous debts on your behalf. The lender will send you a "pay-off power of attorney", which you will fill out with the loans that are to be paid off. You will then sign the power of attorney, thereby giving your consent for the lender to do this. A debt acquittal is an easy way to avoid having to contact all of your lenders personally to pay off your old debts. Let the lender take care of this for you!</p>'
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
    },
    {
      id: 'sortingOrderBiddingDone',
      question: (
        <FormattedMessage
          id="faq.sv.question.hasBids.sortingOrderBiddingDone"
          defaultMessage="How is the list of loan offers sorted?"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.hasBids.sortingOrderBiddingDone"
          defaultMessage="<p>By default, we always show the loans with the lowest effective interest rate first, with higher effective interest rates the further down the list you go. We also let you know whether the lender was able to give you the entire amount requested, or as close to the requested amount as possible.</p><p>You are then able to sort the offers based on other parameters as needed.</p>"
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
    },
  ],
  [APPLICATION.MORTGAGE_INTEREST]: [
    {
      id: 'whyMortgageApplication',
      question: (
        <FormattedMessage
          id="faq.sv.question.mortgageEligible.whyRefinanceMortgage"
          defaultMessage="Why can it pay off to refinance the mortgage?"
          description="FAQ question displayed at the bottom when the consumer loan customer is eligible for a mortgage application"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.mortgageEligible.whyRefinanceMortgage"
          defaultMessage="<p>If you have a lot of credits and private loans with high interest rates, it may pay off to consolidate these by refinancing the mortgage as the interest rates usually are lower on a mortgage compared to creditors and unsecured loans.</p>"
          description="FAQ answer displayed at the bottom when the consumer loan customer is eligible for a mortgage application"
          tagName=""
        />
      ),
    },
    {
      id: 'whyNoLoanOffers',
      question: (
        <FormattedMessage
          id="faq.sv.question.noBids.whyNoLoanOffers"
          defaultMessage="Why didn't I get any loan offers?"
          description="FAQ question displayed at the bottom when the customer did not receive any loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.noBids.whyNoLoanOffers"
          defaultMessage="<p>The lenders have reviewed your application and are unable to issue you a loan at this time. There are many possible reasons for this and we never find out the exact reason.</p>"
          description="FAQ question displayed at the bottom when the customer did not receive any loan proposals"
          tagName=""
        />
      ),
    },
    {
      id: 'whatToDoDifferentInFuture',
      question: (
        <FormattedMessage
          id="faq.sv.question.noBids.whatToDoDifferentInFuture"
          defaultMessage="What should I do differently moving forward?"
          description="FAQ question displayed at the bottom when the customer did not receive any loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.noBids.whatToDoDifferentInFuture"
          defaultMessage="<p>One factor that impacts short-term credit worthiness is the number of credit reports that have been ordered within a certain period of time. If this applies to you, don't request any more reports to ensure you get the best possible comparison.</p>"
          description="FAQ answer displayed at the bottom when the customer did not receive any loan proposals"
          tagName=""
        />
      ),
    },
  ],
  [APPLICATION.NO_BIDS]: [
    {
      id: 'whyNoLoanOffers',
      question: (
        <FormattedMessage
          id="faq.sv.question.noBids.whyNoLoanOffers"
          defaultMessage="Why didn't I get any loan offers?"
          description="FAQ question displayed at the bottom when the customer did not receive any loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.noBids.whyNoLoanOffers"
          defaultMessage="<p>The lenders have reviewed your application and are unable to issue you a loan at this time. There are many possible reasons for this and we never find out the exact reason.</p>"
          description="FAQ question displayed at the bottom when the customer did not receive any loan proposals"
          tagName=""
        />
      ),
    },
    {
      id: 'whatToDoDifferentInFuture',
      question: (
        <FormattedMessage
          id="faq.sv.question.noBids.whatToDoDifferentInFuture"
          defaultMessage="What should I do differently moving forward?"
          description="FAQ question displayed at the bottom when the customer did not receive any loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.noBids.whatToDoDifferentInFuture"
          defaultMessage="<p>One factor that impacts short-term credit worthiness is the number of credit reports that have been ordered within a certain period of time. If this applies to you, don't request any more reports to ensure you get the best possible comparison.</p>"
          description="FAQ answer displayed at the bottom when the customer did not receive any loan proposals"
          tagName=""
        />
      ),
    },
  ],
  [WEBSITE_STATES.NO_BIDS_ON_DECLINED]: [
    {
      id: 'whyNoLoanOffers',
      question: (
        <FormattedMessage
          id="faq.sv.question.noBidsOnDeclined.whyNoLoanOffers"
          defaultMessage="Why didn't I get any loan offers?"
          description="FAQ question displayed at the bottom when the customer did not receive any loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.noBidsOnDeclined.whyNoLoanOffers"
          defaultMessage="<p>The lenders have reviewed your application and are unable to issue you a loan at this time. There are many possible reasons for this and we never find out the exact reason. </p>"
          description="FAQ answer displayed at the bottom when the customer did not receive any loan proposals"
          tagName=""
        />
      ),
    },
  ].concat(BidsOnDecline),
  [APPLICATION.ACCEPTED]: [
    {
      id: 'whatHappensNow',
      question: (
        <FormattedMessage
          id="faq.sv.question.applicationAccepted.whatHappensNow"
          defaultMessage="What happens now?"
          description="FAQ answer displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.applicationAccepted.whatHappensNow"
          defaultMessage='<p>Now there is only one step left: sign the agreement. After that, the money will be issued.</p><p>How your agreement is sent to you will depend on the lender you have chosen. Please refer to the list above to find out how you will receive your agreement.</p><p>When you sign the agreement, the lender will sometimes ask you to send along "supplementary information". This can be, for example, a payslip that shows that the information you provided is correct. If the lender wants you to provide more information, it will be stated in the <hashLink>list above</hashLink>.</p>'
          description="FAQ answer displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
          values={{
            hashLink,
          }}
        />
      ),
    },
    {
      id: 'whatInfoCreditorRequires',
      question: (
        <FormattedMessage
          id="faq.sv.question.applicationAccepted.whatInfoCreditorRequires"
          defaultMessage="What information do I need to provide the bank now?"
          description="FAQ question displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.applicationAccepted.whatInfoCreditorRequires"
          defaultMessage="<p>In order for the bank to be able to issue the loan to you, they will need:</p>
          <list>
            <listItem><strong>Always:</strong> the loan agreement signed by you (manually or electronically with BankID)</listItem>
            <listItem><strong>Usually:</strong> your account number into which the money is to be paid. If you have not already provided your account number, do so when you sign the loan agreement.</listItem>
            <listItem><strong>Sometimes:</strong> supplementary information. If the lender wants supplementary information, this will be clearly stated in the agreement that is sent to you by post or email.</listItem>
          </list>"
          description="FAQ answer displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
          values={{
            list,
            listItem,
          }}
        />
      ),
    },
    {
      id: 'rejectedAfterAccept',
      question: (
        <FormattedMessage
          id="faq.sv.question.applicationAccepted.rejectedAfterAccept"
          defaultMessage="What happens if my loan has been denied after approval?"
          description="FAQ question displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.applicationAccepted.rejectedAfterAccept"
          defaultMessage="<p>Was your loan denied after approval by a lender? Don't worry! If possible, we'll reopen your portal with your other offers to let you choose a new loan. Contact our customer service line on {teleLinkDisplay} for assistance!</p>"
          description="FAQ answer displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
          values={{
            teleLinkDisplay,
          }}
        />
      ),
    },
    {
      id: 'whatIfUndoAccept',
      question: (
        <FormattedMessage
          id="faq.sv.question.applicationAccepted.whatIfUndoAccept"
          defaultMessage="What do I do if I change my mind or want to go back?"
          description="FAQ question displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.applicationAccepted.whatIfUndoAccept"
          defaultMessage="<p>Did you choose the wrong offer or change your mind? No worries, call our customer service line on {teleLinkDisplay} for assistance.</p>"
          description="FAQ answer displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
          values={{
            teleLinkDisplay,
          }}
        />
      ),
    },
    {
      id: 'didNotGetDebtLetter',
      question: (
        <FormattedMessage
          id="faq.sv.question.applicationAccepted.didNotGetDebtLetter"
          defaultMessage="I have not received the agreement from the bank"
          description="FAQ question displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.applicationAccepted.didNotGetDebtLetter"
          defaultMessage="<p>If the lender uses electronic signature, the information should be sent to you by no later than one banking day from the date you accepted. If the lender only sends agreements by post, it may take 1–3 banking days. If you still have not received your loan agreement, please <teleLink>contact us</teleLink> for assistance.</p>"
          description="FAQ answer displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
          values={{ teleLink }}
        />
      ),
    },
    {
      id: 'whatIsEmployerCertificateHowToGetIt',
      question: (
        <FormattedMessage
          id="faq.sv.question.applicationAccepted.whatIsEmployerCertificateHowToGetIt"
          defaultMessage="What is a proof of employment and how do I get mine?"
          description="FAQ question displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.applicationAccepted.whatIsEmployerCertificateHowToGetIt"
          defaultMessage="<p>Proof of employment is a certificate showing your current place of employment. To obtain your proof of employment, speak with your manager or contact the human resources department at your workplace. Your proof of employment should contain the following:</p>
        <list>
          <listItem>Name and personal identity number</listItem>
          <listItem>Monthly Income/annual Income</listItem>
          <listItem>Date of employment</listItem>
          <listItem>Type of employment: permanent, fixed term (end date), temporary position (end date), project (duration of project), probationary employment (duration of employment)</listItem>
          <listItem>Company name and company registration number</listItem>
          <listItem>Phone number and name of company contact</listItem>
          <listItem>Date of signature</listItem>
          <listItem>Signature of employer including printed name</listItem>
          <listItem>The proof of employment may not be older than 30 days</listItem>
        </list>"
          description="FAQ answer displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
          values={{
            list,
            listItem,
          }}
        />
      ),
    },
    {
      id: 'whatIsPayslipHowToGetIt',
      question: (
        <FormattedMessage
          id="faq.sv.question.applicationAccepted.whatIsPayslipHowToGetIt"
          defaultMessage="What is a payslip and how do I obtain it?"
          description="FAQ question displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.applicationAccepted.whatIsPayslipHowToGetIt"
          defaultMessage="<p>A payslip is a confirmation of your most recent monthly salary. Some people have a physical copy sent to their home, some people have an internal system where they receive it, and others have it emailed to them. If you are unsure about how it works for you, we recommend speaking to your immediate supervisor or the human resources department at your job.</p>"
          description="FAQ answer displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
        />
      ),
    },
    {
      id: 'howToGetBankId',
      question: (
        <FormattedMessage
          id="faq.sv.question.applicationAccepted.howToGetBankId"
          defaultMessage="How do I create a mobile BankID?"
          description="FAQ question displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.sv.answer.applicationAccepted.howToGetBankId"
          defaultMessage="<p>Follow this guide to create a <link>mobile BankID</link></p>"
          description="FAQ answer displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
          values={{
            link: (chunks) => (
              <a href="https://support.bankid.com/sv/bestalla-bankid/bestalla-mobilt-bankid">
                {chunks}
              </a>
            ),
          }}
        />
      ),
    },
  ].concat(PPI),
  [APPLICATION_OPTIMIZE_STATE.ADD_CURRENT_LOANS]: [
    {
      id: 'addCurrentLoan',
      question: (
        <FormattedMessage
          id="faq.sv.question.optimizeApplication.addCurrentLoan"
          defaultMessage="Why should you fill out more than one loan?"
          description="FAQ question displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
        />
      ),
      answer: getBrandQandA(),
    },
  ],
};

export default FAQse;
