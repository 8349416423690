import styled, { css } from 'styled-components';

export const Loans = styled.div`
  margin-top: 2em;
`;

export const Header = styled.div`
  border-radius: 4em;
  margin: auto;
  width: 100%;
  text-align: center;
  padding: 0.3em;
  box-shadow: 0 2px 4px rgb(0 0 0 / 9%);
  margin-bottom: 1em;
  font-family: var(--wallet-font-family-secondary), serif;
  ${(props) =>
    props.gjeldsregisteret === 'currentLoans' &&
    css`
      background: ${props.theme.gjeldsregisteret.privateLoans.background};
      color: ${props.theme.gjeldsregisteret.privateLoans.color};
    `}
  ${(props) =>
    props.gjeldsregisteret === 'creditCard' &&
    css`
      background: ${props.theme.gjeldsregisteret.creditCard.background};
      color: ${props.theme.gjeldsregisteret.creditCard.color};
    `}
  ${(props) =>
    props.gjeldsregisteret === 'changeCards' &&
    css`
      background: ${props.theme.gjeldsregisteret.changeCards.background};
      color: ${props.theme.gjeldsregisteret.changeCards.color};
    `}
`;

export const LabelValueRowWithBorderBottom = styled.div`
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  padding-top: ${(props) => props.pt || '0.4em'};
  padding-bottom: ${(props) => props.pb || '0.3em'};
`;

export const LabelValueRowNotBorderBottom = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0.3em;
`;

export const BoldText = styled.span`
  color: #000;
  font-weight: bold;
  text-transform: capitalize;
`;

export const NumberText = styled.span`
  color: #545454;
  font-size: ${(props) => (props.size ? props.theme.fonts.p.large : '')};
`;
