import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { AUTHORIZED } from '_js/store/actions/auth';
import { ALL_PERMISSIONS } from '_js/constants/Permissions';

import { hasPermissions } from '_js/utils/Authorization';

const AuthorizationBoundary = ({ requiredPermissions, unauthorizedMessage, children }) => {
  const isAuthorized = useSelector((state) => state.auth.state === AUTHORIZED);
  const authorizedPermissions = useSelector((state) => state.auth.permissions);

  if (isAuthorized && hasPermissions(requiredPermissions, authorizedPermissions)) {
    return children;
  }

  return unauthorizedMessage;
};

AuthorizationBoundary.propTypes = {
  requiredPermissions: PropTypes.oneOfType([
    PropTypes.oneOf(ALL_PERMISSIONS),
    PropTypes.arrayOf(PropTypes.oneOf(ALL_PERMISSIONS)),
  ]).isRequired,
  unauthorizedMessage: PropTypes.node,
  children: PropTypes.node,
};

AuthorizationBoundary.defaultProps = {
  unauthorizedMessage: null,
  children: null,
};

export default AuthorizationBoundary;
