import Button from '_js/components/ui/Button';
import styled from 'styled-components';
import devices from '../../styles/Devices';
import Pencil from '../../../images/sambla-pencil.svg?react';

export const StyledPencil = styled(Pencil)`
  fill: ${(props) => props.theme.colors.primary};
`;

export const Heading = styled.h1`
  text-align: center;
  font-family: var(--wallet-font-family-secondary), serif;
  font-size: ${(props) => props.theme.fonts.headingsize.large};
  margin: 1.5rem 0 0.625rem;
`;

export const BodyText = styled.p`
  font-family: var(--wallet-font-family-standard);
  text-align: center;
  margin: 0 0 8rem;
  max-width: 30rem;
`;

export const ConsentButton = styled(Button)`
  width: 100%;
`;

export const SkipButton = styled(Button)`
  width: 100%;
  border: 0;
  color: #4b4b4b;
  font-size: 14px;
`;

export const MetaText = styled.p`
  font-family: var(--wallet-font-family-standard);
  font-size: ${(props) => props.theme.fonts.p.small};
  max-width: 30rem;
  text-align: center;
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Section = styled.section`
  position: relative;
  height: 100vh;
  max-width: 30rem;
  background-color: var(--white);
  padding: 5em 1.2em;
  border-radius: 5px;
  text-align: center;

  @media ${devices.mobileLargeAndUp} {
    margin: 2rem 0;
    height: auto;
  }
`;
