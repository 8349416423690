import PURPOSES from '_js/localizations/messages/loan-purpose-types';

const SWEDISH_LOAN_PURPOSES = [
  PURPOSES.vehicles,
  PURPOSES.consumption,
  PURPOSES.health,
  PURPOSES.renovation,
  PURPOSES.services,
  PURPOSES.buffer,
  PURPOSES.studies,
];

export const NEW_LOAN_FORM_PURPOSES = [...SWEDISH_LOAN_PURPOSES, PURPOSES.consolidateLoans];

export const DEFAULT_LOAN_PURPOSE = PURPOSES.consolidateLoans;

export const ALL_LOAN_PURPOSES = PURPOSES;

export default SWEDISH_LOAN_PURPOSES;
