import { defineMessages } from 'react-intl';

const messages = defineMessages({
  single: {
    defaultMessage: 'Single',
    id: 'maritalStatusTypes.single',
    description: 'Marital status of the applicant - single',
  },
  partner: {
    defaultMessage: 'Cohabitant',
    id: 'maritalStatusTypes.partner',
    description: 'Marital status of the applicant - partner',
  },
  married: {
    defaultMessage: 'Married',
    id: 'maritalStatusTypes.married',
    description: 'Marital status of the applicant - married',
  },
  divorced: {
    defaultMessage: 'Divorced',
    id: 'maritalStatusTypes.divorced',
    description: 'Marital status of the applicant - divorced',
  },
  widow: {
    defaultMessage: 'Widow',
    id: 'maritalStatusTypes.widow',
    description: 'Marital status of the applicant - widow',
  },
});

const maritalStatusTypes = {
  single: {
    label: messages.single,
    value: 'single',
  },
  partner: {
    label: messages.partner,
    value: 'partner',
  },
  married: {
    label: messages.married,
    value: 'married',
  },
  divorced: {
    label: messages.divorced,
    value: 'divorced',
  },
  widow: {
    label: messages.widow,
    value: 'widow',
  },
};

export const { single, partner, married, divorced, widow } = maritalStatusTypes;
