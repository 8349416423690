import {
  loanCompare,
  bestBid,
  oneUc,
  responseTime,
  ppiInsurance,
} from '_js/localizations/config/awaiting-bids-content';

const AWAITING_BIDS_INFO = [
  // Carousel slide 1
  loanCompare,
  bestBid,
  // Carousel slide 2
  responseTime,
  oneUc,
  // Carousel slide 3
  ppiInsurance,
];

export default AWAITING_BIDS_INFO;
