/* eslint-disable default-param-last */
import { actionTypes } from 'redux-form/dist/redux-form';

const dirtyState = (state = {}, action) => {
  if (action.type === actionTypes.CHANGE) {
    return {
      ...state,
      dirty: true,
    };
  }
  return state;
};

export default dirtyState;
