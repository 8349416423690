import { getBrand } from '_js/brands';
import * as Errors from './Errors';

const API_ROOT =
  import.meta.env.VITE_API_ROOT?.match(/^https?|^\/\//) === undefined
    ? window.location.origin + import.meta.env.VITE_API_ROOT
    : import.meta.env.VITE_API_ROOT;

if (typeof API_ROOT === 'undefined') {
  throw new Error('The VITE_API_ROOT environment variable has not been set during build time.');
}
export const rootUrl = new URL(API_ROOT);

export const ACCOUNT_SCRAPING_SKIP = {
  createUrl: (params) => `${API_ROOT}/applications/${params.applicationId}/skip-on-hold`,
  error: Errors.SkipOnHoldError,
};

export const COMPLETE_TINK = {
  createUrl: (params) =>
    `${API_ROOT}/applications/${params.applicationId}/runTink?brand=${getBrand()}`,
  error: Errors.CompleteTinkError,
};

export const ACCEPT_BID = {
  createUrl: (params) =>
    `${API_ROOT}/applications/${params.applicationId}/bidding/bids/${params.bidId}/accept`,
  error: Errors.AcceptBidError,
};

export const START_PPI_SIGNING = {
  createUrl: () => `${API_ROOT}/sign/start/insurance/?brand=${getBrand()}`,
  error: Errors.PpiSigningError,
};

export const START_PPI_SIGNING_WITHOUT_BANKID = {
  createUrl: (params) => `${API_ROOT}/applications/${params.applicationId}/insurance/sign`,
  error: Errors.PpiSigningError,
};

export const CHECK_USER_SIGNED = {
  createUrl: () => `${rootUrl.origin}/idKollen/v1/signed`,
  error: Errors.PpiSigningStatusError,
};

export const PPI_SIGNING_STATUS = {
  createUrl: (params) => `${API_ROOT}/sign/status/${params.token}`,
  error: Errors.PpiSigningStatusError,
};

export const START_LOGIN_SIGNING = {
  createUrl: (params) => `${API_ROOT}/sign/start/${params.type}/?brand=${getBrand()}`,
  error: Errors.PpiSigningError,
};

export const LOGIN_SIGNING_STATUS = {
  createUrl: (params) => `${API_ROOT}/sign/status/${params.token}/?brand=${getBrand()}`,
  error: Errors.PpiSigningStatusError,
};

export const ACCEPTED_BID_DATA = {
  createUrl: (params) =>
    `${API_ROOT}/applications/${params.applicationId}/bidding/accepted-bid-data/${params.bidId}`,
  error: Errors.LogAcceptedBidDataError,
};

export const GOOGLE_LOGIN = {
  createUrl: () => `${rootUrl.origin}/auth/v1/googleLogin`,
  error: Errors.GenericError,
};

export const MARKETING_CONTACT = {
  createUrl: (params) => `${API_ROOT}/applications/${params.applicationId}/marketing-contact`,
  error: Errors.GenericError,
};

export const PREFILL_SESSION = {
  createUrl: (params) => `${API_ROOT}/applications/${params.applicationId}/sessionId/tink`,
  error: Errors.GenericError,
};

export const SUBSCRIBE_NEWSLETTER = {
  createUrl: (params) => `${API_ROOT}/applications/${params.applicationId}/subscribeNewsletter`,
  error: Errors.GenericError,
};

export const ACCEPT_NEWSLETTER_STATUS = {
  createUrl: (params) => `${API_ROOT}/applications/${params.applicationId}/acceptNewsletterStatus`,
  error: Errors.GenericError,
};

export const CREDIT_CARD_OFFERS = {
  createUrl: (params) =>
    `${API_ROOT}/applications/${params.applicationId}/bidding?onlyCreditCardOffers=true`,
  error: Errors.GenericError,
};

export const ALL_BANK_ACCOUNTS = {
  createUrl: (params) => `${API_ROOT}/applications/${params.applicationId}/bankAccountsNew`,
  error: Errors.GenericError,
};

export const DRY_RUN_FILTERS = {
  createUrl: (params) => `${API_ROOT}/applications/${params.applicationId}/dryRunFilters`,
  error: Errors.GenericError,
};

export const GJELDSREGISTERET_CONSENT = {
  createUrl: () => `${API_ROOT}/gjeldsregisteret/consent`,
  error: Errors.GenericError,
};

export const GJELDSREGISTERET_STATUS = {
  createUrl: () => `${API_ROOT}/gjeldsregisteret/status`,
  error: Errors.GenericError,
};

export const IDKOLLEN_RESPONSE = {
  createUrl: () => `${rootUrl.origin}/idKollen/v1/signed`,
  error: Errors.GenericError,
};
