import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IfFeatureEnabled } from '@growthbook/growthbook-react';

import APPLICATION_TYPES from '_js/constants/ApplicationTypes';
import { getBrand } from '_js/brands';
import intlShape from '_js/schemas/intl';
import devices from '_js/styles/Devices';

import { APP_ADVERT_FEATURE } from '_js/localizations/config/features';
import Check from '../../images/check.svg?react';
import SamblaPencilIcon from '../../images/sambla-pencil.svg?react';
import GooglePlayLink from '../../images/google-play.svg?react';
import AppStoreLink from '../../images/app-store.svg?react';
import AppImage from '../../images/app-screens.png';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1.2rem 1.2rem 3rem;
  margin: auto;
  max-width: 40rem;
  background-color: white;

  @media ${devices.tabletAndUp} {
    margin-top: 3rem;
    padding-right: 3rem;
    padding-left: 3rem;
  }
`;

const Heading = styled.h1`
  font-family: var(--wallet-font-family-secondary), serif;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.03em;
  -webkit-font-smoothing: antialiased;
`;

const WhatHappensNow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

const SubHeading = styled.h2`
  font-family: var(--wallet-font-family-secondary), serif;
  font-weight: 400;
  letter-spacing: 0.03em;
  margin-bottom: 0;
  font-size: 1.2rem;
`;

const UnorderedList = styled.ul`
  padding-left: 0;
  list-style-type: none;

  li {
    margin-bottom: 1.5em;
    display: flex;
    overflow: hidden;
  }
`;

const CheckBullet = styled(Check)`
  min-width: 1.375rem;
  width: 1.375rem;
  height: 1.125rem;
  margin-right: 0.5em;
  fill: ${(props) => props.theme.colors.checkbullet.color};
`;

const DisclaimerText = styled.p`
  margin: 0 auto 1.5rem;

  @media ${devices.tabletAndUp} {
    margin-bottom: 2rem;
  }
`;

const PencilIcon = styled(SamblaPencilIcon)`
  fill: ${(props) => props.theme.colors.lucideIcons.secondary};
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 1.2rem 0;
`;

const ThankYouPreamble = styled.p`
  margin: 0 auto 1.2rem;
  font-size: 0.875rem;
  text-align: center;
`;

const AppPreamble = styled.p`
  margin: 0 auto 1.2rem;
  font-size: 0.875rem;
`;

const DownloadAppSection = styled.section`
  display: flex;
  padding: 1.25rem 1.25rem 0;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 1.25rem;
  background-color: ${(props) => props.theme.colors.myPagesAd.background};

  @media ${devices.tabletAndUp} {
    gap: 1.675rem;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

const StyledH3 = styled.h3`
  width: 100%;
  margin: 0;
  font-family: var(--wallet-font-family-secondary), serif;
  color: ${(props) => props.theme.colors.myPagesAd.header};
`;

const IconLinkWrappers = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: left;
  align-items: center;

  @media ${devices.tabletAndUp} {
    gap: 1.675rem;
    justify-content: center;
  }
`;

const AppScreensWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const AppScreens = styled.img`
  width: 100%;
  max-width: 23.438rem;
  height: auto;
`;

const AppLinkWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const messages = defineMessages({
  windowTitle: {
    id: 'noSelfService.windowTitle',
    defaultMessage: 'Thank you for applying | {brand} Customer portal',
    description: 'Window title for the NoSelfService page',
  },
});

const StrongChunk = (chunks) => <strong>{chunks}</strong>;

export const ThankYouComponent = ({ applicationType }) => (
  <>
    {getBrand() === 'Sambla' && (
      <IconWrapper>
        <PencilIcon />
      </IconWrapper>
    )}
    <FormattedMessage
      id="noSelfService.heading"
      defaultMessage="Thank you for your loan application!"
      description="Heading after submitting a non-self-service application"
      tagName={Heading}
    />
    {applicationType === APPLICATION_TYPES.MORTGAGE ? (
      <MortgageApplicationBody />
    ) : (
      <PrivateApplicationBody />
    )}
    {getBrand() === 'Advisa' && (
      <FormattedMessage
        id="noSelfService.disclaimer.advisa"
        defaultMessage="<strong>NOTE!</strong> To prevent your credit score and loan proposals from deteriorating, we recommend that you do not apply for a loan from other banks or loan brokers before you are notified by Advisa."
        description="Warning to customer not to apply for other loans to preserve credit rating and favourable loan proposals"
        values={{
          strong: StrongChunk,
        }}
        tagName={DisclaimerText}
      />
    )}
    {getBrand() === 'Sambla' && (
      <FormattedMessage
        id="noSelfService.disclaimer.sambla"
        defaultMessage="<strong>NOTE!</strong> To prevent your credit score and loan proposals from deteriorating, we recommend that you do not apply for a loan from other banks or loan brokers before you are notified by Sambla."
        description="Warning to customer not to apply for other loans to preserve credit rating and favourable loan proposals"
        values={{
          strong: StrongChunk,
        }}
        tagName={DisclaimerText}
      />
    )}
  </>
);

const PrivateApplicationBody = () => (
  <>
    <FormattedMessage
      id="noSelfService.summary"
      defaultMessage="Your application has now been received and is now being processed by the lenders. All lenders respond within 24 hours, but it usually goes much faster!"
      description="Confirmation of receipt of application"
      tagName={ThankYouPreamble}
    />
    <WhatHappensNow>
      <FormattedMessage
        id="noSelfService.subHeading"
        defaultMessage="What happens now?"
        description="Subheading after submitting a non-self-service application"
        tagName={SubHeading}
      />
      <UnorderedList>
        <li>
          <CheckBullet />
          <FormattedMessage
            id="noSelfService.nextSteps.one"
            defaultMessage="We will contact you with the best loan proposals."
            description="Confirmation the customer will be contacted with the best loan offers"
          />
        </li>
        <li>
          <CheckBullet />
          <FormattedMessage
            id="noSelfService.nextSteps.two"
            defaultMessage="After you have chosen a loan offer, you can sign it online with BankID."
            description="Once loan offer selected, it will can be signed by BankID"
          />
        </li>
        <li>
          <CheckBullet />
          <FormattedMessage
            id="noSelfService.nextSteps.three"
            defaultMessage="The loan is paid out within a couple of days after signing."
            description="The loan will be paid out within a few days of signing"
          />
        </li>
      </UnorderedList>
    </WhatHappensNow>
  </>
);

const MortgageApplicationBody = () => (
  <FormattedMessage
    id="noSelfService.mortgageSummary"
    defaultMessage="Your application has been received and is now being processed by our mortgage advisors. We will call you within 24 hours from a number that starts with 072."
    description="Confirmation of receipt of application"
    tagName="p"
  />
);

const NoSelfService = ({ intl: { formatMessage } }) => {
  const applicationType = useSelector((state) => state.application.applicationType);
  return (
    <Wrapper>
      <Helmet>
        <title>{formatMessage(messages.windowTitle, { brand: getBrand() })}</title>
      </Helmet>

      <ThankYouComponent applicationType={applicationType} />

      {applicationType !== APPLICATION_TYPES.MORTGAGE && (
        <IfFeatureEnabled feature={APP_ADVERT_FEATURE}>
          <DownloadAppSection>
            <TextWrapper>
              <FormattedMessage
                id="noSelfService.downloadApp"
                defaultMessage="Monitor your application in the Sambla app!"
                description="Heading for download app section"
                tagName={StyledH3}
              />
              <FormattedMessage
                id="noSelfService.downloadAppText"
                defaultMessage="You also gain easy access to view your Sambla Score and receive valuable tips on how to improve your financial situation."
                description="Text for download app section"
                tagName={AppPreamble}
              />
            </TextWrapper>
            <IconLinkWrappers>
              <AppLinkWrapper href="https://l.sambla.se/1uYQ1w">
                <AppStoreLink />
              </AppLinkWrapper>
              <AppLinkWrapper href="https://l.sambla.se/1uYQ1w">
                <GooglePlayLink />
              </AppLinkWrapper>
            </IconLinkWrappers>
            <AppScreensWrapper>
              <AppScreens src={AppImage} />
            </AppScreensWrapper>
          </DownloadAppSection>
        </IfFeatureEnabled>
      )}
    </Wrapper>
  );
};

NoSelfService.propTypes = {
  intl: intlShape.isRequired,
};

ThankYouComponent.propTypes = {
  applicationType: PropTypes.string.isRequired,
};

export default injectIntl(NoSelfService);
