import { PERMISSIONS, ADMIN_PERMISSIONS, NO_PERMISSIONS } from './Permissions';

export const ROLES = Object.freeze({
  GUEST: 'GUEST', // Not logged in public GUEST visitor
  CUSTOMER: 'CUSTOMER', // Logged in CUSTOMER with access token
  ADVISOR: 'ADVISOR', // Google Oauth logged in internal ADVISOR with limited access
  AGENT: 'AGENT', // Google Oauth logged in internal AGENT with limited access
  ADMIN: 'ADMIN', // Google Oauth logged in internal ADMIN with full access
});

export const ROLE_PERMISSIONS = Object.freeze({
  [ROLES.GUEST]: NO_PERMISSIONS,
  [ROLES.CUSTOMER]: [
    PERMISSIONS.APPLICATIONS.VIEW,
    PERMISSIONS.BIDS.VIEW,
    PERMISSIONS.BIDS.LIST,
    PERMISSIONS.INVITES.VIEW,
    PERMISSIONS.BIDS.REDIRECT,
    PERMISSIONS.USER.LOGOUT,
  ],
  [ROLES.ADVISOR]: [
    PERMISSIONS.APPLICATIONS.VIEW,
    PERMISSIONS.APPLICATIONS.UPDATE,
    PERMISSIONS.APPLICATIONS.MANAGE,
    PERMISSIONS.BIDS.VIEW,
    PERMISSIONS.BIDS.LIST,
    PERMISSIONS.BIDS.UNDO_ACCEPT,
    PERMISSIONS.BIDS.CREATED_AT,
    PERMISSIONS.INVITES.VIEW,
    PERMISSIONS.INVITES.REQUEST_NEW,
    PERMISSIONS.CURRENT_LOANS.VIEW,
    PERMISSIONS.USER.LOGOUT,
  ],
  [ROLES.AGENT]: [PERMISSIONS.USER.LOGOUT],
  [ROLES.ADMIN]: ADMIN_PERMISSIONS,
});

export const INTERNAL_ROLES = [ROLES.AGENT, ROLES.ADVISOR, ROLES.ADMIN];
