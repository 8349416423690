// Step after authentication, displaying the current loans
import { useState, useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { postGjeldsregisteretConsent } from '_js/utils/api/API';
import devices from '_js/styles/Devices';
import HeaderText from '_js/components/molecules/HeaderText';
import Link from '_js/components/ui/Link';
import Button from '_js/components/ui/Button';
import useEffectOnce from '_js/hooks/useEffectOnce';
import Loader from '_js/components/ui/Loader';

import GjeldsregisteretCurrentLoansTotalDebt from './GjeldsregisteretCurrentLoansTotalDebt';
import GjeldsregisteretCurrentLoansPrivateLoans from './GjeldsregisteretCurrentLoansPrivateLoans';
import GjeldsregisteretCurrentLoansCreditCards from './GjeldsregisteretCurrentLoansCreditCards';
import GjeldsregisteretCurrentLoansChangeCards from './GjeldsregisteretCurrentLoansChangeCards';
import GjeldsregisteretCurrentLoansMoreInfoItem from './GjeldsregisteretCurrentLoansMoreInfoItem';
import ChevronArrow from '../../../images/chevron-arrow.svg?react';
import FloatingScrollButton from '../ui/Button/FloatingScrollButton';
import { getBrandIcon } from './index';

const messages = defineMessages({
  header: {
    id: 'gjeldsregisteret.current.loans.header',
    defaultMessage: 'Your current loans',
    description: 'A text to prompt the current loans',
  },
});

const WrapperDashboard = styled.section`
  margin-bottom: 0;
  padding-bottom: 0.5em;
  margin-top: 0;
  background: #fff;

  @media ${devices.desktop} {
    padding-bottom: 2.5em;
    margin-top: 0;
    background: ${(props) => props.theme.colors.body.background};
  }
`;

const LoansWrapper = styled.div`
  width: 100%;
  margin: auto;
  max-width: 23rem;
  padding-top: 3em;

  @media ${devices.desktop} {
    max-width: 30rem;
    margin-top: 3em;
    background: #fff;
    padding: 1em 2em;
  }
`;

const StyledLink = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-decoration: none;
  font-size: 1.4rem;
  font-family: var(--wallet-font-family-standard), sans-serif;
  padding: 0.7em 1.5rem;
  border-radius: ${(props) => props.theme.gjeldsregisteret.button.radius};
  width: 100%;
  min-width: 200px;
  background: var(--primary-button-background);
  color: var(--primary-button-color);
  margin-top: 1em;
  margin-bottom: 0.5em;
  border: none;
`;

const StyledMoreInfoWrapper = styled.div`
  border: 1px solid #e7e7e7;
  padding: 0 1em;
  border-radius: ${(props) => props.theme.gjeldsregisteret.button.radius};
`;

const StyledMoreInfoButton = styled(Button)`
  color: #545454;
  border: none;
  background: none;
`;

const Arrow = styled(ChevronArrow)`
  width: 1.5rem;
  height: 1.5rem;
  color: #545454;
  transform: ${({ $showopen }) => ($showopen ? 'rotate(180deg)' : '')};
  transition: transform 0.3s ease-in-out;
`;

const MetaText = styled.p`
  font-family: var(--wallet-font-family-standard);
  font-size: ${(props) => props.theme.fonts.p.small};
  max-width: 30rem;
  text-align: center;
`;

const Text = styled.span`
  font-family: var(--wallet-font-family-standard);
  font-size: ${(props) => props.theme.fonts.p.mediumlarge};
  text-align: center;
`;

const NoCurrentLoans = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoaderWrapper = styled.div`
  padding: 40% 50% 4rem;

  @media ${devices.desktop} {
    padding: 25% 50% 4rem;
  }
`;

const GjeldsregisteretLink = () => (
  // eslint-disable-next-line formatjs/no-literal-string-in-jsx
  <Link href="https://www.gjeldsregisteret.com/" rel="noopener" target="_blank">
    gjeldsregisteret.com
  </Link>
);

const GjeldsregisteretCurrentLoans = ({ setIsGRJourneyFinished }) => {
  const [currentLoans, setCurrentLoans] = useState([]);
  const [showOpen, setOpen] = useState(false);
  const [gjeldredisteretConsentStatus, setGjeldredisteretConsentStatus] = useState('loading');
  const [isSignInLoading, setIsSignInLoading] = useState(true);
  const handleGRJourneyFinished = () => setIsGRJourneyFinished(true);
  useEffectOnce(() => {
    const getGjeldsregisteretResults = async () => {
      const resultGjeldsregisteret = await postGjeldsregisteretConsent();
      setGjeldredisteretConsentStatus(resultGjeldsregisteret.status);
      setCurrentLoans(resultGjeldsregisteret);
    };

    getGjeldsregisteretResults();
  });

  useEffect(() => {
    if (gjeldredisteretConsentStatus !== 'loading') {
      setIsSignInLoading(false);
    }
  }, [gjeldredisteretConsentStatus]);

  const toggleHandler = () => {
    setOpen(!showOpen);
  };

  return isSignInLoading ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <WrapperDashboard>
      <LoansWrapper>
        <HeaderText justifyContent="center" headline={messages.header} />
        {currentLoans?.status === 'notFound' ? (
          <NoCurrentLoans>
            <br />
            <br />
            {getBrandIcon()}
            <br />
            <br />
            <Text>
              <FormattedMessage
                id="gjeldsregisteret.no.current.loans"
                defaultMessage="You have no current loans or credits that we can see!"
                description="A text for prompting the user don't have any current loans"
              />
            </Text>
            <br />
            <br />
            <StyledLink onClick={handleGRJourneyFinished}>
              <FormattedMessage
                id="gjeldsregisteret.current.loans.button.continue"
                defaultMessage="Continue"
                description="A text for prompting the user to continue"
              />
            </StyledLink>
            <br />
          </NoCurrentLoans>
        ) : (
          <>
            <GjeldsregisteretCurrentLoansTotalDebt
              currentLoansTotalAmount={currentLoans?.loanTotalAmount?.totalAmount}
            />
            <GjeldsregisteretCurrentLoansPrivateLoans
              currentLoansAmount={currentLoans?.privateLoans?.amount}
              currentLoansTotal={currentLoans?.privateLoans?.total}
            />
            <GjeldsregisteretCurrentLoansCreditCards
              currentLoansAmount={currentLoans?.creditCards?.amount}
              currentLoansCreditLimit={currentLoans?.creditCards?.creditLimit}
              currentLoansTotal={currentLoans?.creditCards?.total}
            />
            <GjeldsregisteretCurrentLoansChangeCards
              currentLoansAmount={currentLoans?.chargeCards?.amount}
            />
            <StyledLink
              onClick={() => {
                setIsGRJourneyFinished(true);
              }}
            >
              <FormattedMessage
                id="gjeldsregisteret.current.loans.button.continue"
                defaultMessage="Continue"
                description="A text for prompting the user to continue"
              />
            </StyledLink>
            <StyledMoreInfoWrapper>
              <StyledMoreInfoButton outline fullWidth onClick={toggleHandler}>
                <FormattedMessage
                  id="gjeldsregisteret.current.loans.button.more.info"
                  defaultMessage="More info"
                  description="A text for prompting the user to get more info"
                />
                <Arrow $showopen={showOpen} />
              </StyledMoreInfoButton>
              {showOpen &&
                currentLoans?.loans?.map((loan) => (
                  <GjeldsregisteretCurrentLoansMoreInfoItem loan={loan} />
                ))}
            </StyledMoreInfoWrapper>
            <MetaText>
              <FormattedMessage
                id="gjeldsregisteret.consent.current.loans.metaText"
                defaultMessage=" The debt information is provided by the Debt Register As. You can find an overview of financial companies that supply debt information to Gjeldsregistret at {gjeldsregisteretLink}"
                description="Text that informs the user about data privacy guidelines when using Gjeldsregisteret via Sambla Group"
                values={{ gjeldsregisteretLink: GjeldsregisteretLink() }}
              />
            </MetaText>
          </>
        )}
        <FloatingScrollButton />
      </LoansWrapper>
    </WrapperDashboard>
  );
};

GjeldsregisteretCurrentLoans.propTypes = {
  setIsGRJourneyFinished: PropTypes.func.isRequired,
};

export default GjeldsregisteretCurrentLoans;
