import { createBrowserRouter, createMemoryRouter } from 'react-router-dom';

let instance;

/**
 * A wrapper for sharing the React Router history across components.
 * This is required mostly for testing where we want to be able to reinitialize
 * the history in each test.
 *
 * IMPORTANT: wherever you use the history and want to test with mocking the history
 * object, make sure you do not call `getInstance()` only once in the module but
 * each time you need to access the history.
 *
 */
class HistoryWrapper {
  static init() {
    instance = createBrowserRouter(['/']);
  }

  static getInstance() {
    if (!instance) {
      throw new Error('HistoryWrapper: not initialized');
    }

    return instance;
  }

  static reinitializeAsMemoryHistory(options) {
    instance = createMemoryRouter(options);
  }
}

export default HistoryWrapper;
