import { defineMessages } from 'react-intl';

const messages = defineMessages({
  employedOptionGroupTitleSE: {
    id: 'employmentTypes.SE.employedOptionGroupTitle',
    defaultMessage: 'Full time employee',
    description: 'Employment type - Full time employee/permanent contract',
  },
  permanentSE: {
    defaultMessage: 'Permanent',
    id: 'employmentTypes.SE.permanent',
    description: 'Employment type - Tenured/employed indefinitely/untill further notice',
  },
  probationarySE: {
    defaultMessage: 'Probationary',
    id: 'employmentTypes.SE.probationary',
    description: 'Employment type - Probationary',
  },
  retiredOptionGroupTitleSE: {
    defaultMessage: 'Pensioner',
    id: 'employmentTypes.SE.retiredOptionGroupTitle',
    description: 'Employment type - Retired/pensioner',
  },
  retiredSE: {
    defaultMessage: 'Pensioner / early retiree',
    id: 'employmentTypes.SE.retired',
    description: 'Employment type - Retired/early retirement',
  },
  disabilityPensionSE: {
    defaultMessage: 'Sick retiree',
    id: 'employmentTypes.SE.disabilityPension',
    description: 'Employment type - Retired/pensioner on disibility',
  },
  tempEmployedOptionGroupTitleSE: {
    defaultMessage: 'Temporary employment',
    id: 'employmentTypes.SE.tempEmployedOptionGroupTitle',
    description: 'Employment type - Temporary employment',
  },
  projectSE: {
    defaultMessage: 'Project/fixed-term',
    id: 'employmentTypes.SE.project',
    description: 'Employment type - Temporary employment/employed during project',
  },
  projectSEABTest: {
    defaultMessage: 'Project / time limited',
    id: 'employmentTypes.SE.abTest.project',
    description: 'Employment type - Temporary employment/employed during project',
  },
  byTheHourSE: {
    defaultMessage: 'Extra work / hourly',
    id: 'employmentTypes.SE.byTheHour',
    description: 'Employment type - Extra work/hourly contract',
  },
  selfEmployedOptionGroupTitleSE: {
    defaultMessage: 'Self-employed',
    id: 'employmentTypes.SE.selfEmployedOptionGroupTitle',
    description: 'Employment type - Self-employed',
  },
  employedAtOwnCompanySE: {
    defaultMessage: 'Own business',
    id: 'employmentTypes.SE.employedAtOwnCompany',
    description: 'Employment type - Has his/her own business',
  },
  soleProprietorshipSE: {
    defaultMessage: 'Freelance / own sole proprietorship',
    id: 'employmentTypes.SE.soleProprietorship',
    description: 'Employment type - Freelancer or sole proprietorship',
  },
  studentFI: {
    defaultMessage: 'Student',
    id: 'employmentTypes.FI.student',
    description: 'Employment type - Student',
  },
  unemployedFI: {
    defaultMessage: 'Unemployed',
    id: 'employmentTypes.FI.unemployed',
    description: 'Employment type - Unemployed',
  },
  untilFurtherNoticeFI: {
    defaultMessage: 'Until further notice',
    id: 'employmentTypes.FI.untilFurtherNotice',
    description: 'Employment type - Tenured/employed indefinitely/untill further notice',
  },
  partTimeFI: {
    defaultMessage: 'Part-time employee',
    id: 'employmentTypes.FI.partTime',
    description: 'Employment type - Part time',
  },
  temporaryFI: {
    defaultMessage: 'Temporary employment',
    id: 'employmentTypes.FI.temporary',
    description: 'Employment type - Temporary employment',
  },
  selfEmployedFI: {
    defaultMessage: 'Self employed',
    id: 'employmentTypes.FI.selfEmployed',
    description: 'Employment type - Self employment',
  },
  retiredFI: {
    defaultMessage: 'Pensioner',
    id: 'employmentTypes.FI.retired',
    description: 'Employment type - Retired/Pensioner',
  },
});

const employmentTypeLabels = {
  employedOptionGroupTitleSE: {
    label: messages.employedOptionGroupTitleSE,
    value: '',
  },
  permanentSE: {
    label: messages.permanentSE,
    value: 'permanent',
  },
  probationarySE: {
    label: messages.probationarySE,
    value: 'probationary',
  },
  retiredOptionGroupTitleSE: {
    label: messages.retiredOptionGroupTitleSE,
    value: '',
  },
  retiredSE: {
    label: messages.retiredSE,
    value: 'retired',
  },
  disabilityPensionSE: {
    label: messages.disabilityPensionSE,
    value: 'disabilityPension',
  },
  tempEmployedOptionGroupTitleSE: {
    label: messages.tempEmployedOptionGroupTitleSE,
    value: '',
  },
  projectSE: {
    label: messages.projectSE,
    value: 'project',
  },
  projectSEABTest: {
    label: messages.projectSEABTest,
    value: 'project',
  },
  byTheHourSE: {
    label: messages.byTheHourSE,
    value: 'byTheHour',
  },
  selfEmployedOptionGroupTitleSE: {
    label: messages.selfEmployedOptionGroupTitleSE,
    value: '',
  },
  employedAtOwnCompanySE: {
    label: messages.employedAtOwnCompanySE,
    value: 'employedAtOwnCompany',
  },
  soleProprietorshipSE: {
    label: messages.soleProprietorshipSE,
    value: 'soleProprietorship',
  },
  studentFI: {
    label: messages.studentFI,
    value: 'student',
  },
  unemployedFI: {
    label: messages.unemployedFI,
    value: 'unemployed',
  },
  untilFurtherNoticeFI: {
    label: messages.untilFurtherNoticeFI,
    value: 'untilFurtherNotice',
  },
  partTimeFI: {
    label: messages.partTimeFI,
    value: 'partTime',
  },
  temporaryFI: {
    label: messages.temporaryFI,
    value: 'temporary',
  },
  retiredFI: {
    label: messages.retiredFI,
    value: 'retired',
  },
  selfEmployedFI: {
    label: messages.selfEmployedFI,
    value: 'selfEmployed',
  },
};

export const {
  employedOptionGroupTitleSE,
  permanentSE,
  probationarySE,
  retiredOptionGroupTitleSE,
  retiredSE,
  disabilityPensionSE,
  tempEmployedOptionGroupTitleSE,
  projectSE,
  projectSEABTest,
  byTheHourSE,
  selfEmployedOptionGroupTitleSE,
  employedAtOwnCompanySE,
  soleProprietorshipSE,
  untilFurtherNoticeFI,
  partTimeFI,
  temporaryFI,
  studentFI,
  unemployedFI,
  selfEmployedFI,
  retiredFI,
} = employmentTypeLabels;
