import {
  condominium,
  houseOrTownhouse,
  rentingApartment,
  rentingRoom,
} from '_js/localizations/messages/home-types';

const HOME_TYPES = [rentingApartment, houseOrTownhouse, condominium, rentingRoom];

export default HOME_TYPES;
