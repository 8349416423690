// internal
import { loginSigningStatus, startLoginSigning } from '_js/utils/api/API';
import { MINIMUM_SIGNING_STATUS_POLLING_INTERVAL } from '_js/constants/Time';
import { urlETags } from 'redux-shapeshifter-middleware';

import Poller from '_js/utils/PollerAsync';
import { fetchApplication } from '_js/store/actions/api/application/fetch';
import { getLoginType } from '_js/brands';

export const SIGN_LOGIN = 'API/SYSTEM/SIGN_LOGIN';
export const SIGN_LOGIN_SUCCESS = 'API/SYSTEM/SIGN_LOGIN_SUCCESS';
export const SIGN_LOGIN_FAILED = 'API/SYSTEM/SIGN_LOGIN_FAILED';
export const SIGN_LOGIN_RESET_ERROR_IF_HAPPENED = 'API/SYSTEM/SIGN_LOGIN_RESET_ERROR_IF_HAPPENED';
export const SIGN_LOGIN_STOP = 'API/SYSTEM/SIGN_LOGIN_STOP';
export const UPDATE_QR_DATA = 'API/SYSTEM/UPDATE_QR_DATA';

const BANK_ID_RESPONSES = {
  FAILED: 'failed',
  NOT_FOUND: 'notFound',
  COMPLETED: 'completed',
  SIGNING: 'signing',
  STARTED: 'started',
};

let poller;

export const LOGIN_ERRORS = {
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  LOGIN_STATUS_ERROR: 'LOGIN_STATUS_ERROR',
  LOGIN_START_ERROR: 'LOGIN_START_ERROR',
  NO_APPLICATION_ERROR: 'NO_APPLICATION_ERROR',
};

const loginInProgressQR = (qrData = null, qrRestart = false) => ({
  type: UPDATE_QR_DATA,
  payload: {
    qrData,
    qrRestart,
  },
});

export const cancelLoginQR = () => ({
  type: UPDATE_QR_DATA,
  payload: {
    qrData: null,
  },
});

const loginInProgress = (autoStartToken = null) => ({
  type: SIGN_LOGIN,
  payload: {
    autoStartToken,
  },
});

const loginInProgressLink = (loginLink = null) => ({
  type: SIGN_LOGIN,
  payload: {
    loginLink,
  },
});

const loginDone = (authorizationType) => {
  switch (authorizationType) {
    case 'identification':
      break;
    default:
  }

  return {
    type: SIGN_LOGIN_SUCCESS,
  };
};
const loginFailed = (authorizationType, error = 'UnknownError') => {
  /**
   * Possible error:
   *  UnknownError | USER_NOT_FOUND | LOGIN_STATUS_ERROR | LOGIN_START_ERROR
   */
  switch (authorizationType) {
    case 'identification':
      break;
    default:
  }

  return {
    type: SIGN_LOGIN_FAILED,
    payload: {
      error,
    },
  };
};

export const loginStop = () => {
  if (poller) {
    poller.stopPolling();
  }
  return {
    type: SIGN_LOGIN_STOP,
  };
};

const clearEtags = () => {
  Object.keys(urlETags || {}).forEach((etag) => {
    delete urlETags[etag];
  });
};

const redirectToCustomerPortal = (token) => {
  window.location.assign(`${window.location.origin}?t=${token}`);
};

export const loginSigningStatusReset = () => ({
  type: SIGN_LOGIN_RESET_ERROR_IF_HAPPENED,
});

const redirectToLoginLink = (loginLink) => {
  window.location.assign(`${loginLink}`);
};

const callback = (token, dispatch, authorizationType, applicationId) =>
  loginSigningStatus(token)
    .then((response = {}) => {
      if (!response) {
        return;
      }
      dispatch(loginInProgressQR(response.qrData, response.restart));
      if (response.status === BANK_ID_RESPONSES.FAILED) {
        poller.stopPolling();
        dispatch(loginStop(response.message || response.status));
      } else if (response.status === BANK_ID_RESPONSES.COMPLETED) {
        poller.stopPolling();
        dispatch(loginDone(authorizationType));
        if (authorizationType === 'identification') {
          dispatch(fetchApplication(applicationId));
        } else {
          redirectToCustomerPortal(response.token, response.redirectUrl);
        }
      } else if (response.status === BANK_ID_RESPONSES.NOT_FOUND) {
        throw new Error('LoginNotFoundError');
      } else if (response.restart) {
        poller.newCallback(() =>
          callback(response.token, dispatch, authorizationType, applicationId),
        );
      }
    })
    .catch((err) => {
      poller.stopPolling();
      const errorCode =
        err.status === 404 ? LOGIN_ERRORS.USER_NOT_FOUND : LOGIN_ERRORS.LOGIN_STATUS_ERROR;
      dispatch(loginFailed(authorizationType, errorCode));
    });

const startPollingForStatus = (data, applicationId, authorizationType, dispatch) => {
  if (!data || !data.token) {
    throw new Error('No signing token to fetch status on!');
  }

  if (data.autoStartToken) {
    dispatch(loginInProgress(data.autoStartToken));
  }

  if (data.qrData) {
    dispatch(loginInProgressQR(data.qrData));
  }
  if (data.loginLink) {
    dispatch(loginInProgressLink(data.loginLink));
  }

  poller = new Poller(
    () => callback(data.token, dispatch, authorizationType, applicationId),
    MINIMUM_SIGNING_STATUS_POLLING_INTERVAL,
  );
  poller.startPolling(true);
};

export const loginSign = (values, authorizationType) => (dispatch) => {
  const type = getLoginType();
  const payload = { ...values, type };

  clearEtags();
  if (type === 'loginFinland' || type === 'loginNorway') {
    dispatch(loginInProgressLink());
  } else {
    dispatch(loginInProgress());
  }

  return startLoginSigning(payload)
    .then((response) => {
      if (response.noApplication) {
        dispatch(loginFailed());
        return response;
      }
      if (type === 'loginFinland') {
        redirectToLoginLink(response.loginLink);
      }

      return startPollingForStatus(response, payload.applicationId, authorizationType, dispatch);
    })
    .catch(() => dispatch(loginFailed(authorizationType, LOGIN_ERRORS.LOGIN_START_ERROR)));
};
