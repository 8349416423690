import { Loader, Wrapper } from './Button.styles';
import { defaultProps, propTypes } from './Button.proptypes';

const Button = ({
  disabled,
  children,
  onClick,
  sticky,
  primary,
  outline,
  fullWidth,
  href,
  isLoading,
  ...props
}) => {
  const isDisabled = disabled || isLoading;
  const handleClick = isDisabled ? null : onClick;

  return (
    <Wrapper
      as={href ? 'a' : 'button'}
      onClick={handleClick}
      disabled={isDisabled}
      $sticky={sticky}
      $primary={primary}
      $outline={outline}
      $fullWidth={fullWidth}
      href={href}
      {...props}
    >
      {isLoading && <Loader />}
      {children}
    </Wrapper>
  );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
