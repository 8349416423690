/* eslint-disable default-param-last */
import * as Sentry from '@sentry/react';

import {
  AUTH_STATES,
  AUTH_LOADING,
  AUTH_LOADING_FAILED,
  UNAUTHENTICATED,
  LOGOUT,
  AUTHENTICATED,
  AUTHORIZED,
} from '_js/store/actions/auth';
import {
  getTokenPayloadRoles,
  getPermissionsFromRoles,
  getApplicationIdFromTokens,
} from '_js/utils/Authorization';
import { decodeJwtPayload } from '_js/utils/api/jwt';
import { NO_PERMISSIONS, PERMISSIONS } from '_js/constants/Permissions';

import {
  SIGN_LOGIN,
  SIGN_LOGIN_FAILED,
  SIGN_LOGIN_STOP,
  SIGN_LOGIN_SUCCESS,
  UPDATE_QR_DATA,
} from '_js/store/actions/api/auth/login';

import { FETCH_APPLICATION_SUCCESS } from '_js/store/actions/api/application/fetch';

import { getMarket } from '_js/brands';

export const initialState = {
  state: AUTH_LOADING,
  error: null,
  user: {},
  roles: ['guest'],
  permissions: NO_PERMISSIONS,
  applicationId: null,
  loggingIn: false,
  uuid: null,
  autoStartToken: null,
  qrData: null,
  qrRestart: false,
  loginLink: null,
};

export default (state = initialState, action) => {
  const market = getMarket();
  if (action.type === FETCH_APPLICATION_SUCCESS) {
    const { permissions = [] } = action.payload;
    return {
      ...state,
      permissions: permissions.includes(PERMISSIONS.BIDS.ACCEPT)
        ? Array.from(new Set(state.permissions.concat(permissions)))
        : state.permissions.filter((permission) => permission !== PERMISSIONS.BIDS.ACCEPT),
    };
  }

  if (!Object.values(AUTH_STATES).includes(action.type)) {
    return state;
  }

  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        state: action.type,
        user: action.payload.user,
      };

    case AUTHORIZED: {
      const tokenPayload = decodeJwtPayload(action.payload.authorizationToken);
      const roles = getTokenPayloadRoles(tokenPayload);
      const permissions = getPermissionsFromRoles(roles);
      const applicationId = getApplicationIdFromTokens(tokenPayload);

      if (applicationId) {
        Sentry.setUser({
          id: `applicationId:${applicationId}`,
          type: 'customer',
          roles: roles.join(', '),
        });

        return {
          ...state,
          state: action.type,
          roles,
          permissions,
          applicationId,
        };
      }

      Sentry.setUser({
        id: `userId:${tokenPayload.userId}`,
        type: 'internal',
        email: state.user.email,
        username: state.user.name,
        roles: roles.join(', '),
      });

      return {
        ...state,
        state: action.type,
        roles,
        permissions,
      };
    }

    case AUTH_LOADING_FAILED:
      return {
        ...state,
        state: action.type,
        error: action.error ? action.payload.error : null,
      };

    case UNAUTHENTICATED:
    case LOGOUT:
      return {
        ...state,
        state: action.type,
        user: {},
        roles: ['guest'],
        permissions: NO_PERMISSIONS,
        error: action.error ? action.payload.error : null,
      };

    case SIGN_LOGIN:
      if (market === 'fi' || market === 'no') {
        return {
          ...state,
          loggingIn: true,
          loginLink: action?.payload?.loginLink,
        };
      }
      return {
        ...state,
        loggingIn: true,
        autoStartToken: action?.payload?.autoStartToken,
      };

    case UPDATE_QR_DATA:
      return {
        ...state,
        qrData: action?.payload?.qrData,
        qrRestart: action?.payload?.qrRestart,
      };

    case SIGN_LOGIN_FAILED:
      return {
        ...state,
        error: action.payload.error,
        loggingIn: false,
      };

    case SIGN_LOGIN_STOP:
      return {
        ...state,
        loggingIn: false,
      };

    case SIGN_LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
      };

    default:
      return {
        ...state,
        state: action.type,
      };
  }
};
