import { FormattedMessage } from 'react-intl';
import { FormattedNumberWithFallback } from '_js/utils/FormattedNumberWithFallback';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LabelValueRowWithBorderBottom, BoldText } from './GjeldsregisteretCurrentLoans.styles';

const StyledLabel = styled.span`
  text-align: left;
`;

export default function GjeldsregisteretCreditFacility({ loan }) {
  return (
    <>
      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.financialInstitutionName.2"
            defaultMessage="Bank"
            description="A label for the financial institution name"
          />
        </StyledLabel>
        <BoldText>{loan?.financialInstitutionName?.toLowerCase()}</BoldText>
      </LabelValueRowWithBorderBottom>

      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em" data-testid="creditLimit">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.originalBalance"
            defaultMessage="Credit limit"
            description="A label for the original balance"
          />
        </StyledLabel>
        <BoldText>
          <FormattedNumberWithFallback value={loan.creditLimit} formatAs="currency" />
        </BoldText>
      </LabelValueRowWithBorderBottom>
      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em" data-testid="nonInterestBearingBalance">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.nonInterestBearingBalance.2"
            defaultMessage="Non Interest Bearing Balance"
            description="A label for the Non interest Bearing Balance"
          />
        </StyledLabel>
        <BoldText>
          <FormattedNumberWithFallback
            value={loan.nonInterestBearingBalance / 100}
            formatAs="currency"
          />
        </BoldText>
      </LabelValueRowWithBorderBottom>

      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.interestBearingBalance.2"
            defaultMessage="Interest Bearing Balance"
            description="A label for the Interest Bearing Balance"
          />
        </StyledLabel>
        <BoldText>
          <FormattedNumberWithFallback value={loan.interestBearingBalance} formatAs="currency" />
        </BoldText>
      </LabelValueRowWithBorderBottom>

      <>
        <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em" data-testid="interest">
          <StyledLabel>
            <FormattedMessage
              id="gjeldsregisteret.current.loans.moreInfo.interest.2"
              defaultMessage="Interest"
              description="A label for the nominal interest rate"
            />
          </StyledLabel>
          <BoldText>
            <FormattedNumberWithFallback
              value={loan.nominalInterestRate / 100}
              formatAs="interestRate"
            />
          </BoldText>
        </LabelValueRowWithBorderBottom>
        <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em" data-testid="installmentCharges">
          <StyledLabel>
            <FormattedMessage
              id="gjeldsregisteret.current.loans.moreInfo.installmentCharges"
              defaultMessage="Charge"
              description="A label for the installment Charges"
            />
          </StyledLabel>
          <BoldText>
            <FormattedNumberWithFallback value={loan?.installmentCharges} formatAs="currency" />
          </BoldText>
        </LabelValueRowWithBorderBottom>
        <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em" data-testid="installmentChargePeriod">
          <StyledLabel>
            <FormattedMessage
              id="gjeldsregisteret.current.loans.moreInfo.installmentChargePeriod"
              defaultMessage="Installment charge period"
              description="A label for the installment charge period"
            />
          </StyledLabel>
          <BoldText>
            {loan?.installmentChargePeriod === 'MONTHLY' ? (
              <FormattedMessage
                id="common.monthly"
                defaultMessage="Monthly"
                description="Value for monthly"
              />
            ) : (
              <FormattedMessage
                id="common.annually"
                defaultMessage="Annually"
                description="Value for annually"
              />
            )}
          </BoldText>
        </LabelValueRowWithBorderBottom>
      </>

      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.coBorrower.2"
            defaultMessage="Co-borrower"
            description="A label for the co borrower"
          />
        </StyledLabel>
        <BoldText>
          {loan?.coBorrower === 'none' ? (
            <FormattedMessage
              id="gjeldsregisteret.current.loans.moreInfo.coBorrowerValue.none.2"
              defaultMessage="None"
              description="The value of co borrower"
            />
          ) : (
            <FormattedMessage
              id="gjeldsregisteret.current.loans.moreInfo.coBorrowerValue.exists.2"
              defaultMessage="Exists"
              description="The value of co borrower"
            />
          )}
        </BoldText>
      </LabelValueRowWithBorderBottom>
      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.processedTime.2"
            defaultMessage="Last updated from creditor"
            description="A label for the processed time"
          />
        </StyledLabel>
        <BoldText>{format(new Date(loan?.processedTime), 'dd/MM/yy')}</BoldText>
      </LabelValueRowWithBorderBottom>
      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.receivedTime.2"
            defaultMessage="Last updated from gjeldsregistret"
            description="A label for the receieved time"
          />
        </StyledLabel>
        <BoldText>{format(new Date(loan?.receivedTime), 'dd/MM/yy')}</BoldText>
      </LabelValueRowWithBorderBottom>
    </>
  );
}

GjeldsregisteretCreditFacility.propTypes = {
  loan: PropTypes.shape({
    id: PropTypes.number,
    balance: PropTypes.number,
    coBorrower: PropTypes.string,
    consentId: PropTypes.number,
    createdAt: PropTypes.string,
    financialInstitutionId: PropTypes.string,
    financialInstitutionName: PropTypes.string,
    installmentChargePeriod: PropTypes.string,
    installmentCharges: PropTypes.number,
    interestBearingBalance: PropTypes.number,
    loanType: PropTypes.string,
    nominalInterestRate: PropTypes.number,
    nonInterestBearingBalance: PropTypes.number,
    originalBalance: PropTypes.number,
    processedTime: PropTypes.string,
    providerId: PropTypes.string,
    receivedTime: PropTypes.string,
    type: PropTypes.number,
    updatedAt: PropTypes.string,
    terms: PropTypes.number,
    creditLimit: PropTypes.number,
  }),
};

GjeldsregisteretCreditFacility.defaultProps = {
  loan: null,
};
