/* eslint-disable prefer-promise-reject-errors */
import * as Sentry from '@sentry/react';

import { loadScript } from '_js/utils/DOM';
import GOOGLE_AUTH_CONFIG from '_js/constants/GoogleAuth';

export const initializeGoogleAuth = () =>
  new Promise((onInit, onError) => {
    loadScript('https://apis.google.com/js/platform.js?onload=init', {
      onload: () => {
        window.gapi.load('auth2', {
          callback: () => {
            window.gapi.auth2
              .init(GOOGLE_AUTH_CONFIG)
              .then(onInit)
              .catch(() => onError('API_INIT_ERROR'));
          },
          onerror: () => onError('API_LOAD_ERROR'),
          timeout: 15000,
          ontimeout: () => onError('API_LOAD_TIMEOUT'),
        });
      },
      onerror: () => onError('SCRIPT_LOAD_ERROR'),
    });
  });

export const signIn = ({ success, unauthorized, failed }) => {
  window.gapi.auth2
    .getAuthInstance()
    .signIn()
    .then(success, (signInErrorCode) => {
      switch (signInErrorCode) {
        case 'access_denied':
          unauthorized();
          break;
        case 'popup_closed_by_user':
        case 'immediate_failed':
        default:
          failed('ERROR');
          break;
      }
    })
    .catch((error) => {
      Sentry.captureException(error);
      failed('ERROR');
    });
};

export const signOut = () => {
  if (window.gapi) {
    window.gapi.auth2.getAuthInstance().signOut();
  }
};

export const getGoogleIdToken = (googleUser) => {
  const authResponse = googleUser.getAuthResponse();

  return authResponse.id_token;
};

export const getGoogleUserInfo = (googleUser) => {
  const basicProfile = googleUser.getBasicProfile();

  return {
    name: basicProfile.getName(),
    givenName: basicProfile.getGivenName(),
    familyName: basicProfile.getFamilyName(),
    imageUrl: basicProfile.getImageUrl(),
    email: basicProfile.getEmail(),
  };
};
