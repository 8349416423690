import { fetchAcceptNewsletterStatus } from '_js/utils/api/API';

export const UPDATE_ACCEPT_NEWSLETTER_STATUS = 'UPDATE_ACCEPT_NEWSLETTER_STATUS';

const updateAcceptNewsletterStatus = (status) => ({
  type: UPDATE_ACCEPT_NEWSLETTER_STATUS,
  payload: {
    status,
  },
});

export const getAcceptNewsletterStatus = () => async (dispatch, getState) => {
  const {
    application: { id: applicationId },
  } = getState();

  const response = await fetchAcceptNewsletterStatus(applicationId);
  if (response !== null) {
    dispatch(updateAcceptNewsletterStatus(response));
  }
};
