import styled from 'styled-components';
import { useEffect, useState } from 'react';
import useMobileLayout from '_js/hooks/useMobileLayout';
import ArrowRight from '../../../../images/arrow-right.svg?react';

const Button = styled.button`
  background-color: ${(props) => props.theme.colors.button.primary};
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  height: 57px;
  width: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  z-index: 1;

  svg {
    transform: rotate(270deg) scale(1.5);
    stroke: white;
    width: 40px;
  }
`;

export default function FloatingScrollButton() {
  const isMobile = useMobileLayout();
  const [scrollTop, setScrollTop] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 340) {
        setScrollTop(true);
      } else {
        setScrollTop(false);
      }
    });

    return () => {
      window.removeEventListener('scroll', () => {});
    };
  }, []);

  const onScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  if (scrollTop && isMobile)
    return (
      <Button onClick={onScrollToTop} type="button">
        <ArrowRight />
      </Button>
    );
  return null;
}
