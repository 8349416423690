import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';

import { PERMISSIONS } from '_js/constants/Permissions';

import AuthorizationBoundary from '_js/containers/AuthorizationBoundary';
import { repaymentTimeDisplay } from '_js/utils/Loan';

import { FormattedNumberWithFallback } from '_js/utils/FormattedNumberWithFallback';
import intlShape from '_js/schemas/intl';
import InformationBox from '_js/components/atoms/InformationBox';
import devices from '_js/styles/Devices';

const SummarySection = styled.section`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  text-align: center;
  margin: auto;
  width: 100%;
  margin-bottom: 1em;

  @media ${devices.desktop} {
    width: ${(props) => (props.$noBids ? '100%' : '50%')};
  }
`;

const ApplicationSummary = ({
  repaymentTime,
  totalAmount,
  text,
  status,
  noBids,
  intl: { formatMessage },
}) => (
  <AuthorizationBoundary requiredPermissions={PERMISSIONS.APPLICATIONS.VIEW}>
    <SummarySection $noBids={noBids}>
      <InformationBox
        formatMessage={formatMessage}
        heading={formatMessage(text)}
        align="left"
        information={
          <strong>
            <FormattedNumberWithFallback value={totalAmount} formatAs="currency" />
            {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
            <span>, </span>
            <FormattedMessage
              id="applicationSummary.applicationBoundary"
              defaultMessage="{years}&nbsp;years"
              values={{
                years: repaymentTimeDisplay(repaymentTime),
              }}
            />
          </strong>
        }
      />
      <InformationBox
        formatMessage={formatMessage}
        align="right"
        fontStyle="italic"
        heading={
          <FormattedMessage
            id="applicationSummary.applicationStatus.heading"
            defaultMessage="Status"
            description="Short headning in the right box to show the loan application status"
          />
        }
        information={
          <strong>
            <FormattedMessage
              id="applicationSummary.application.status"
              defaultMessage="{status}"
              values={{
                status,
              }}
            />
          </strong>
        }
      />
    </SummarySection>
  </AuthorizationBoundary>
);

ApplicationSummary.propTypes = {
  repaymentTime: PropTypes.number.isRequired,
  totalAmount: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  text: PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
  status: PropTypes.node.isRequired,
  noBids: PropTypes.bool,
};

ApplicationSummary.defaultProps = {
  text: null,
  noBids: false,
};

export default injectIntl(ApplicationSummary);
