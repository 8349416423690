import { FormattedMessage } from 'react-intl';
import StatusMessage from '_js/components/organisms/StatusMessage';

const Error = () => (
  <StatusMessage state="warning">
    <FormattedMessage
      id="credit.card.error"
      defaultMessage="Something went wrong. Try to reload the page or contact customer service"
      description=""
    />
  </StatusMessage>
);

export default Error;
