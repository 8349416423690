/* eslint-disable default-param-last */
import { actionTypes } from 'redux-form/dist/redux-form';

import { saveState } from '_js/utils/form-state';

let timeout;
const debounce = (fn, delay) => {
  clearTimeout(timeout);
  timeout = setTimeout(fn, delay);
};

const formState = (state = {}, action) => {
  if (action.type === actionTypes.CHANGE) {
    debounce(() => saveState('application-form', state.values), 500);
  }
  return state;
};

export default formState;
