import { addMilliseconds, differenceInMilliseconds, isAfter } from 'date-fns';
import { SHORT_EXPIRY_TIME } from '_js/constants/Time';

// eslint-disable-next-line import/prefer-default-export
export const shouldShowExpirationTime = (bid, clientTimeOffset = 0) => {
  const date = new Date();
  const now = addMilliseconds(date, clientTimeOffset);
  const expiresAt = new Date(bid.expiresAt);
  const createdAt = new Date(bid.createdAt);
  const timeframe = differenceInMilliseconds(expiresAt, createdAt);
  const hasShortExpiryTime = timeframe <= SHORT_EXPIRY_TIME;
  const hasNotExpired = isAfter(expiresAt, now);

  return hasShortExpiryTime && hasNotExpired;
};
