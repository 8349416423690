const white = '#fff';
const black = '#000000';
const transparentTeal = '#e5f8f9';
const acceptTeal = '#0bb1b9';
const vegasGold50 = '#FCF9F2';
const vegasGold400 = ' #d5b85f';
const vegasGold550 = '#9d8746';
const rahoituOrange500 = '#FF9C07';
const grey200 = '#f4f4f4';

export default {
  id: 'rahoitu',
  loading: {
    background: vegasGold50,
    bounce: vegasGold550,
    fontColor: vegasGold550,
  },
  fonts: {
    button: {
      medium: '1.4em',
      large: '1.7em',
    },
    p: {
      small: '0.7500em',
      medium: '0.95em',
      mediumlarge: '1.1em',
      large: '1.4em',
    },
    headingsize: {
      small: '1.5em',
      medium: '2.25em',
      large: '2.5em',
    },
    subheading: {
      large: '2em',
    },
    bidlistfont: {
      size: '0.95em',
    },
    color: vegasGold550,
  },
  colors: {
    primary: rahoituOrange500,
    button: {
      primary: {
        text: white,
        background: rahoituOrange500,
      },
      secondary: {
        text: white,
        background: rahoituOrange500,
      },
      tertiary: {
        text: '',
        bg: {
          default: '',
          hover: '',
          pressed: '',
        },
      },
    },
    navlink: {
      color: black,
    },
    handlebutton: {
      color: {
        closed: '',
        open: '',
      },
      bg: {
        closed: '',
        open: '',
      },
    },
    carousel: {
      dot: rahoituOrange500,
      arrow: rahoituOrange500,
    },
    circle: {
      color: vegasGold550,
      background: rahoituOrange500,
      boxShadow: vegasGold550,
    },
    expand: {
      background: '',
    },
    checkbullet: {
      color: '',
    },
    details: {
      color: '',
    },
    numbers: {
      color: '',
    },
    arrow: {
      color: rahoituOrange500,
      chevron: white,
    },
    body: {
      background: grey200,
    },
    chevronArrow: {
      color: '',
    },
    slider: {
      color: rahoituOrange500,
    },
    border: {
      color: rahoituOrange500,
    },
    spinner: {
      color: '',
    },
    selectedOption: {
      color: vegasGold400,
    },
    acceptedBid: {
      border: acceptTeal,
      color: transparentTeal,
    },
    lucideIcons: {
      primary: vegasGold550,
      secondary: vegasGold400,
    },
    accordion: {
      background: white,
      border: vegasGold400,
      body: vegasGold550,
      title: vegasGold550,
      chevron: vegasGold550,
    },
    infoCircle: {
      fill: vegasGold550,
    },
    myPagesAd: {
      background: '',
      header: '',
    },
  },
  styled: {
    button: rahoituOrange500,
    arrowButton: black,
  },
  spinner: {
    border: '',
  },
  header: {
    background: {
      color: vegasGold50,
    },
  },
  bidPage: {
    separator: vegasGold400,
  },
  breadcrumb: {
    background: {
      color: '',
      bullet: '',
    },
    border: '',
    line: vegasGold400,
    checkIcon: '',
    activeColor: '',
    fontColor: '',
    bulletFontColor: '',
  },
  burgerMenuLi: {
    background: {
      color: vegasGold50,
    },
  },
  headerText: {
    color: vegasGold400,
  },
  bidListBorder: {
    color: vegasGold50,
    radius: '5px',
  },
  gjeldsregisteret: {
    privateLoans: {
      background: '',
      color: '',
    },
    creditCard: {
      background: '',
      color: '',
    },
    changeCards: {
      background: '',
      color: '',
    },
    button: {
      radius: '0px',
    },
  },
  insurance: {
    borderColor: vegasGold50,
    iconColor: vegasGold550,
    backgroundColor: vegasGold50,
    textColor: black,
  },
};
