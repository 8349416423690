import awaitingBidsMessages from '_js/localizations/messages/awaiting-bids-info';

import BestBidIcon from '../../../images/AwaitingBids/best-bid.svg?react';
import ClockIcon from '../../../images/AwaitingBids/clock.svg?react';
import LoanCompareIcon from '../../../images/AwaitingBids/lanejamforelse.svg?react';
import SmsIcon from '../../../images/AwaitingBids/sms.svg?react';
import UcIcon from '../../../images/AwaitingBids/UC.svg?react';
import PPIIcon from '../../../images/AwaitingBids/ppiInsurance.svg?react';

export const loanCompare = {
  id: 'loan-compare',
  contentHeader: awaitingBidsMessages.loanCompareHeader,
  contentText: awaitingBidsMessages.loanCompare,
  image: <LoanCompareIcon className="c-good-to-know-icon u-svg--fill-current-color" />,
};

export const bestBid = {
  id: 'best-bid',
  contentHeader: awaitingBidsMessages.bestBidHeader,
  contentText: awaitingBidsMessages.bestBid,
  image: <BestBidIcon className="c-good-to-know-icon u-svg--stroke-current-color" />,
};

export const sms = {
  id: 'sms',
  contentHeader: awaitingBidsMessages.smsHeader,
  contentText: awaitingBidsMessages.sms,
  image: <SmsIcon className="c-good-to-know-icon u-svg--fill-current-color" />,
};

export const oneUc = {
  id: 'one-uc',
  contentHeader: awaitingBidsMessages.oneUcHeader,
  contentText: awaitingBidsMessages.oneUc,
  image: <UcIcon className="c-good-to-know-icon u-svg--fill-current-color" />,
};

export const responseTime = {
  id: 'fast-answers',
  contentHeader: awaitingBidsMessages.fastAnswersHeader,
  contentText: awaitingBidsMessages.responseTime,
  image: <ClockIcon className="c-good-to-know-icon u-svg--fill-current-color" />,
};

export const ppiInsurance = {
  id: 'ppi-insurance',
  contentHeader: awaitingBidsMessages.ppiInsuranceHeader,
  contentText: awaitingBidsMessages.ppiInsurance,
  image: <PPIIcon className="c-good-to-know-icon u-svg--fill-current-color" />,
};
