import queryString from 'query-string';
import * as Sentry from '@sentry/react';
import { InvalidTokenError } from 'jwt-decode';

import { getAuthorizationToken, getTokenPayloadRoles } from '_js/utils/Authorization';
import { INTERNAL_ROLES } from '_js/constants/Roles';
import { decodeJwtPayload } from '_js/utils/api/jwt';

export const hasCrmUrlSource = (location = {}) => {
  const queryObject = queryString.parse(location.search);
  const crmSource = queryObject.login === 'internal';
  const isLoginSuccessfulPage = location.pathname === '/internal-login-successful';
  return crmSource || isLoginSuccessfulPage;
};

export const isCrmUser = () => {
  try {
    const cookieToken = getAuthorizationToken();
    const tokenPayload = decodeJwtPayload(cookieToken);
    const cookieTokenRoles = getTokenPayloadRoles(tokenPayload);
    const hasCrmRoles = cookieTokenRoles.some((role) => INTERNAL_ROLES.includes(role));

    if (hasCrmRoles) {
      return true;
    }
  } catch (error) {
    if (!(error instanceof InvalidTokenError)) {
      Sentry.captureException(error);
    }
  }

  return hasCrmUrlSource(window.location);
};
