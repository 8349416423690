export const RECENT_APPLICATION_MODAL_IS_OPEN = 'RECENT_APPLICATION_MODAL_IS_OPEN';
export const closeRecentApplicationModal = () => ({
  type: RECENT_APPLICATION_MODAL_IS_OPEN,
  payload: false,
});

export const openRecentApplicationModal = () => ({
  type: RECENT_APPLICATION_MODAL_IS_OPEN,
  payload: true,
});
