import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { FormattedNumberWithFallback } from '_js/utils/FormattedNumberWithFallback';
import {
  Loans,
  Header,
  LabelValueRowNotBorderBottom,
  BoldText,
  NumberText,
} from './GjeldsregisteretCurrentLoans.styles';

const GjeldsregisteretCurrentLoansChangeCards = ({ currentLoansAmount }) => (
  <Loans>
    <Header gjeldsregisteret="changeCards">
      <FormattedMessage
        id="gjeldsregisteret.current.loans.change.cards"
        defaultMessage="Change Cards"
        description="A text to prompt the change cards"
      />
    </Header>
    <LabelValueRowNotBorderBottom>
      <BoldText>
        <FormattedMessage
          id="gjeldsregisteret.current.loans.change.cards.amount"
          defaultMessage="Total"
          description="A text to prompt the total amount"
        />
      </BoldText>
      <NumberText>
        <FormattedNumberWithFallback value={currentLoansAmount} formatAs="currency" />
      </NumberText>
    </LabelValueRowNotBorderBottom>
  </Loans>
);

GjeldsregisteretCurrentLoansChangeCards.propTypes = {
  currentLoansAmount: PropTypes.number,
};

GjeldsregisteretCurrentLoansChangeCards.defaultProps = {
  currentLoansAmount: null,
};

export default GjeldsregisteretCurrentLoansChangeCards;
