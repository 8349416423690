export const SIGN_AUTOGIRO_BANK_ID = 'SIGN_AUTOGIRO_BANK_ID';
export const AUTOGIRO_SIGNED = 'AUTOGIRO_SIGNED';

export const signAutogiroBankId = ({ bankIdAutostartToken, qr }) => ({
  type: SIGN_AUTOGIRO_BANK_ID,
  payload: {
    bankIdAutostartToken,
    qr,
  },
});

export const autogiroSigned = () => ({
  type: AUTOGIRO_SIGNED,
});
