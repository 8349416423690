import {
  employedOptionGroupTitleSE,
  permanentSE,
  probationarySE,
  retiredOptionGroupTitleSE,
  retiredSE,
  disabilityPensionSE,
  tempEmployedOptionGroupTitleSE,
  projectSE,
  byTheHourSE,
  selfEmployedOptionGroupTitleSE,
  employedAtOwnCompanySE,
  soleProprietorshipSE,
} from '_js/localizations/messages/employment-types';

const EMPLOYMENT_TYPES = [
  {
    ...employedOptionGroupTitleSE,
    subOptions: [permanentSE, probationarySE],
  },
  {
    ...retiredOptionGroupTitleSE,
    subOptions: [retiredSE, disabilityPensionSE],
  },
  {
    ...tempEmployedOptionGroupTitleSE,
    subOptions: [projectSE, byTheHourSE],
  },
  {
    ...selfEmployedOptionGroupTitleSE,
    subOptions: [employedAtOwnCompanySE, soleProprietorshipSE],
  },
];

export default EMPLOYMENT_TYPES;
