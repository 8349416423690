/* eslint-disable */

// eslint-disable-next-line import/prefer-default-export
/**
 * Loads GTM and appends it before the first <script> tag it finds.
 *
 * @param {Object} options
 * @param {String} options.layer
 *   What the name of the stack should be that GTM looks at.
 *   Defaults to `dataLayer`.
 * @param {String} options.id
 *   The GTM container ID
 *
 * @return void
 */
export const load = (options = {}) => {
  const { layer = 'dataLayer', id } = options;

  if (window[layer]) {
    throw new Error(
      `Tried loading GTM but would've overwritten \`${layer}\`: ${JSON.stringify(
        window[layer],
      )}, please pick another name for your data layer.`,
    );
  }

  if (!id) {
    throw new Error(`\`id\` must be passed, given was ${JSON.stringify(id)}`);
  }

  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', layer, id);
};

/**
 * @param {Object} options
 * @param {String} options.layer
 *   What the name of the stack should be that GTM looks at.
 *   Defaults to `dataLayer`.
 * @param {String} options.payload
 *   What's going to be sent to GTM
 *
 * @return void
 */

const isDataLayer = (dataLayer = 'dataLayer') => {
  if (window[dataLayer] && window[dataLayer] instanceof Array) {
    return true;
  }
  return false;
};

export const sendEvent = (options = {}) => {
  const { layer = 'dataLayer', ...payload } = options;

  if (isDataLayer(layer)) {
    window[layer].push(payload);
  }
};

export const isLatestEvent = (event = '') => {
  if (isDataLayer()) {
    return window['dataLayer'][window['dataLayer'].length - 1].event === event;
  }
  return false;
};

export const isLatestEventLabel = (eventLabel = '') => {
  if (isDataLayer()) {
    return window['dataLayer'][window['dataLayer'].length - 1].eventLabel === eventLabel;
  }
  return false;
};
