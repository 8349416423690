import PropTypes from 'prop-types';
import classNames from 'classnames';

const InformationBox = ({ heading, information, align, fontStyle }) => (
  <div
    className={classNames(
      'c-card',
      'c-card--white',
      'c-card--borderless',
      'c-card--borderless-radius-0',
      'c-card--flex-49',
      'u-margin--bottom-none',
      'u-padding--half-em',
    )}
  >
    <p className={classNames('u-margin--none', [`c-text--align-${align}`], 'c-text--light-grey')}>
      {heading || ''}
    </p>
    <p
      className={classNames('u-margin--none', [`c-text--align-${align}`], {
        [`c-text--${fontStyle}`]: fontStyle !== '',
      })}
    >
      {information}
    </p>
  </div>
);

InformationBox.propTypes = {
  heading: PropTypes.node.isRequired,
  information: PropTypes.node.isRequired,
  align: PropTypes.string.isRequired,
  fontStyle: PropTypes.string,
};

InformationBox.defaultProps = {
  fontStyle: '',
};

export default InformationBox;
