/* eslint-disable default-param-last */
import {
  FETCH_PPI,
  FETCH_PPI_SUCCESS,
  FETCH_PPI_FAILED,
  FETCH_INSURANCE_ELIGIBILITY_SUCCESS,
} from '_js/store/actions/api/ppi/fetch';
import {
  SIGN_PPI,
  SIGN_PPI_SUCCESS,
  SIGN_PPI_FAILED,
  SIGN_PPI_RESET_ERROR_IF_HAPPENED,
  SIGN_PPI_STOP,
  SIGN_PPI_PAPER_INVOICE,
  SIGN_PPI_PAPER_INVOICE_SUCCESS,
  SIGN_PPI_PAPER_INVOICE_FAILED,
  SIGN_PPI_AUTO_GIRO,
  SIGN_PPI_AUTO_GIRO_SUCCESS,
  SIGN_PPI_AUTO_GIRO_FAILED,
  UPDATE_QR_DATA,
} from '_js/store/actions/api/ppi/sign';
import { SET_PPI_AMOUNT } from '_js/store/actions/ppi/set-amount';
import { SIGN_AUTOGIRO_BANK_ID, AUTOGIRO_SIGNED } from '_js/store/actions/api/ppi/autogiro';
import PPI_STATUS from '_js/constants/PpiStatus';

export const initialState = {
  status: PPI_STATUS.NOT_ELIGIBLE,
  loading: true,
  signing: false,
  signError: false,
  options: [],
  bankIdAutostartToken: undefined,
  qrData: null,
  hasPpi: false,
  hasLi: false,
};

export default (state = initialState, action) => {
  const {
    payload: {
      status,
      selectedOptionId,
      suggestedAmount,
      options = [],
      bankIdAutostartToken,
      qrData,
      hasPpi,
      hasLi,
      person,
      ...rest
    } = {},
  } = action;

  switch (action.type) {
    case FETCH_PPI:
      return {
        ...state,
        loading: true,
      };

    case FETCH_PPI_SUCCESS:
      return {
        ...state,
        ...rest,
        loading: false,
        status,
        options: options.map((option) => ({
          ...option,
          selected: option.suggested,
        })),
        hasPpi,
        hasLi,
      };

    case FETCH_INSURANCE_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        insuranceEligible: person ? Object.values(person).some((val) => val) : false,
      };

    case FETCH_PPI_FAILED:
      return {
        ...state,
        loading: false,
      };

    case SIGN_PPI:
      return {
        ...state,
        bankIdAutostartToken: action.payload.autoStartToken,
        signing: true,
      };

    case SIGN_PPI_SUCCESS:
      return {
        ...state,
        signing: false,
        signError: false,
        bankIdAutostartToken: undefined,
        status,
      };

    case SIGN_PPI_FAILED:
      return {
        ...state,
        signing: false,
        signError: true,
      };

    case SIGN_PPI_RESET_ERROR_IF_HAPPENED:
      return {
        ...state,
        signError: false,
      };

    case SIGN_PPI_STOP:
      return {
        ...state,
        signing: false,
      };

    case SET_PPI_AMOUNT:
      return {
        ...state,
        options: state.options.map((option) => ({
          ...option,
          selected: option.id && option.id === selectedOptionId,
        })),
      };

    case SIGN_AUTOGIRO_BANK_ID:
      return {
        ...state,
        bankIdAutostartToken,
        qrData,
      };

    case AUTOGIRO_SIGNED:
      return {
        ...state,
        bankIdAutostartToken: null,
        qrData: null,
      };

    case SIGN_PPI_PAPER_INVOICE:
      return {
        ...state,
        signing: true,
      };

    case SIGN_PPI_PAPER_INVOICE_SUCCESS:
      return {
        ...state,
        signing: false,
        signError: false,
        status,
      };

    case SIGN_PPI_PAPER_INVOICE_FAILED:
      return {
        ...state,
        signing: false,
        signError: true,
      };

    case SIGN_PPI_AUTO_GIRO:
      return {
        ...state,
        signing: true,
      };

    case SIGN_PPI_AUTO_GIRO_SUCCESS:
      return {
        ...state,
        signing: false,
        signError: false,
        status,
      };

    case SIGN_PPI_AUTO_GIRO_FAILED:
      return {
        ...state,
        signing: false,
        signError: true,
      };

    case UPDATE_QR_DATA:
      return {
        ...state,
        qrData: action?.payload?.qrData,
        qrRestart: action?.payload?.qrRestart,
      };

    default:
      return state;
  }
};
