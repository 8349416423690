/**
 * Toggleable feature names in GrowthBook
 */
export const features = {
  INSURANCE_FEATURE: 'insurance',
  AWAITING_BIDS_INFO_FEATURE: 'awaiting-bids-info',
  FAQ_FEATURE: 'faq',
  CHAT_FEATURE: 'chat',
  PHONE_FEATURE: 'phone',
  HIGH_COST_WARNING_FEATURE: 'high-cost-warning',
  MUTE_COMMUNICATION_FEATURE: 'mute-communication',
  RECENT_APPLICATION_MODAL_FEATURE: 'recent-application-modal',
  SECOND_CHANCE_FEATURE: 'second-chance',
  APPLICATION_AUTHENTICATION_BYPASS_FEATURE: 'application-authentication-bypass',
  MORTGAGE_INTEREST_FEATURE: 'mortgage-interest',
  SIMPLIFIED_LOAN_SIGNING_FEATURE: 'simplified-loan-signing',
  MULTIPLE_ACCEPT_FEATURE: 'multiple-accept',
  BIN_SORTING_FEATURE: 'bin-sorting',
  NEW_STEPPER_FEATURE: 'new-stepper',
  NO_BID_BANKY_FEATURE: 'no-bid-banky',
  BID_SORTING_TOOLTIP_FEATURE: 'bid-sorting-tooltip',
  SWEDISH_INSURANCE_VIEW_FEATURE: 'swedish-insurance-view',
  USE_DEPRECATED_PPI_ENDPOINT_FEATURE: 'use-deprecated-insurance-endpoint',
  FI_COPY_FEATURE: 'fi-copy',
  MY_PAGES_FEATURE: 'my-pages',
  NO_LOGIN_FEATURE: 'no-login',
  FI_LOGIN_FEATURE: 'fi-login',
  SE_LOGIN_FEATURE: 'se-login',
  NO_BID_NSTART_FEATURE: 'no-bid-nstart',
  ZERO_BIDS_MORTGAGE_FEATURE: '0-bids-mortgage',
  ACCEPTED_LOAN_OFFER_PAGE: 'accepted-loan-offer-page',
  APP_ADVERT_FEATURE: 'app-advert',
  ADD_CURRENT_LOANS_FEATURE: 'add-current-loans-step',
  ALO_EXPERIMENT_FEATURE: 'alo-experiment-feature',
  INSURANCE_EXPERIMENT_FEATURE: 'insurance-experiment-feature',
  BID_RANKING_SE_EXPERIMENT_FEATURE: 'bid-ranking-experiment-se',
  BID_RANKING_EXPERIMENT_FEATURE: 'bid-ranking-experiment',
  SHOW_INSURANCE_INTRO_FEATURE: 'show-insurance-intro-feature',
  REQUIRE_SECURE_LOGIN_FEATURE: 'require-secure-login',
  MY_APPLICATIONS_OVERVIEW_FEATURE: 'my-applications-overview',
  SHOW_CREDITOR_DISCLAIMER_FEATURE: 'show-creditor-disclaimer',
};

// For reference when checking for feature flags in code
export const {
  INSURANCE_FEATURE,
  AWAITING_BIDS_INFO_FEATURE,
  FAQ_FEATURE,
  CHAT_FEATURE,
  PHONE_FEATURE,
  HIGH_COST_WARNING_FEATURE,
  MUTE_COMMUNICATION_FEATURE,
  RECENT_APPLICATION_MODAL_FEATURE,
  SECOND_CHANCE_FEATURE,
  APPLICATION_AUTHENTICATION_BYPASS_FEATURE,
  MORTGAGE_INTEREST_FEATURE,
  SIMPLIFIED_LOAN_SIGNING_FEATURE,
  MULTIPLE_ACCEPT_FEATURE,
  BIN_SORTING_FEATURE,
  NEW_STEPPER_FEATURE,
  NO_BID_BANKY_FEATURE,
  BID_SORTING_TOOLTIP_FEATURE,
  SWEDISH_INSURANCE_VIEW_FEATURE,
  USE_DEPRECATED_PPI_ENDPOINT_FEATURE,
  FI_COPY_FEATURE,
  MY_PAGES_FEATURE,
  NO_LOGIN_FEATURE,
  FI_LOGIN_FEATURE,
  SE_LOGIN_FEATURE,
  NO_BID_NSTART_FEATURE,
  ZERO_BIDS_MORTGAGE_FEATURE,
  ACCEPTED_LOAN_OFFER_PAGE,
  APP_ADVERT_FEATURE,
  ADD_CURRENT_LOANS_FEATURE,
  ALO_EXPERIMENT_FEATURE,
  INSURANCE_EXPERIMENT_FEATURE,
  BID_RANKING_SE_EXPERIMENT_FEATURE,
  BID_RANKING_EXPERIMENT_FEATURE,
  SHOW_INSURANCE_INTRO_FEATURE,
  REQUIRE_SECURE_LOGIN_FEATURE,
  MY_APPLICATIONS_OVERVIEW_FEATURE,
  SHOW_CREDITOR_DISCLAIMER_FEATURE,
} = features;

// For mocking purposes in test-utils.js, follows development defaults for Advisa_se
export const featuresWithDefaultValues = {
  [INSURANCE_FEATURE]: {
    defaultValue: true,
  },
  [NEW_STEPPER_FEATURE]: {
    defaultValue: true,
  },

  [MULTIPLE_ACCEPT_FEATURE]: {
    defaultValue: false,
  },
  [RECENT_APPLICATION_MODAL_FEATURE]: {
    defaultValue: true,
  },
  [APPLICATION_AUTHENTICATION_BYPASS_FEATURE]: {
    defaultValue: true,
  },
  [SECOND_CHANCE_FEATURE]: {
    defaultValue: true,
  },
  [MUTE_COMMUNICATION_FEATURE]: {
    defaultValue: true,
  },
  [HIGH_COST_WARNING_FEATURE]: {
    defaultValue: false,
  },
  [PHONE_FEATURE]: {
    defaultValue: true,
  },

  [CHAT_FEATURE]: {
    defaultValue: true,
  },
  [FAQ_FEATURE]: {
    defaultValue: true,
  },
  [AWAITING_BIDS_INFO_FEATURE]: {
    defaultValue: true,
  },
  [BID_SORTING_TOOLTIP_FEATURE]: {
    defaultValue: false,
  },
  [BIN_SORTING_FEATURE]: {
    defaultValue: false,
  },
  [SIMPLIFIED_LOAN_SIGNING_FEATURE]: {
    defaultValue: true,
  },
  [MORTGAGE_INTEREST_FEATURE]: {
    defaultValue: false,
  },
  [SWEDISH_INSURANCE_VIEW_FEATURE]: {
    defaultValue: true,
  },
  [USE_DEPRECATED_PPI_ENDPOINT_FEATURE]: {
    defaultValue: true,
  },
  [FI_COPY_FEATURE]: {
    defaultValue: false,
  },
  [MY_PAGES_FEATURE]: {
    defaultValue: false,
  },
  [NO_BID_BANKY_FEATURE]: {
    defaultValue: true,
  },
  [NO_LOGIN_FEATURE]: {
    defaultValue: false,
  },
  [SE_LOGIN_FEATURE]: {
    defaultValue: true,
  },
  [FI_LOGIN_FEATURE]: {
    defaultValue: false,
  },
  [NO_BID_NSTART_FEATURE]: {
    defaultValue: true,
  },
  [ZERO_BIDS_MORTGAGE_FEATURE]: {
    defaultValue: true,
  },
  [ACCEPTED_LOAN_OFFER_PAGE]: {
    defaultValue: true,
  },
  [APP_ADVERT_FEATURE]: {
    defaultValue: false,
  },
  [ADD_CURRENT_LOANS_FEATURE]: {
    default: true,
  },
  [ALO_EXPERIMENT_FEATURE]: {
    default: 'control',
  },
  [INSURANCE_EXPERIMENT_FEATURE]: {
    default: 'control',
  },
  [BID_RANKING_SE_EXPERIMENT_FEATURE]: {
    default: 'control',
  },
  [BID_RANKING_EXPERIMENT_FEATURE]: {
    default: 'control',
  },
  [SHOW_INSURANCE_INTRO_FEATURE]: {
    default: false,
  },
  [REQUIRE_SECURE_LOGIN_FEATURE]: {
    defaultValue: false,
  },
  [MY_APPLICATIONS_OVERVIEW_FEATURE]: {
    default: false,
  },
  [SHOW_CREDITOR_DISCLAIMER_FEATURE]: {
    default: true,
  },
};
