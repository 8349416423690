// eslint-disable-next-line import/prefer-default-export
export const APPLICATION_ON_HOLD_STEPS = {
  OPTIMIZE_APPLICATION: 'optimizeApplication',
};

export const APPLICATION_OPTIMIZE_STATE = {
  INIT: 'init',
  GJELDSREGISTERET: 'gjeldsregisteret',
  SCRAPE_BANK_ACCOUNT: 'scrapeBankAccount',
  ADD_CO_APPLICANT: 'addCoApplicant',
  ADD_CURRENT_LOANS: 'addCurrentLoans',
  FINISHED: 'finished',
};
