/* eslint-disable import/no-extraneous-dependencies, react/jsx-filename-extension */
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { selectUnit } from '@formatjs/intl-utils';

export const formattedRelative = (intl, value, options) => {
  if (!value) {
    return undefined;
  }

  const { value: selectedValue, unit } = selectUnit(new Date(value), undefined, {
    day: 30,
    quarter: false,
  });

  if (unit === 'day' && selectedValue > 6 && selectedValue < 30) {
    const weeks = Math.round(selectedValue / 7);
    return intl.formatRelativeTime(weeks, 'week', options);
  }

  return intl.formatRelativeTime(selectedValue, unit, options);
};

export const FormattedRelative = ({ options, value }) => {
  const intl = useIntl();

  return <>{formattedRelative(intl, value, options)}</>;
};

FormattedRelative.propTypes = {
  options: PropTypes.shape(),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
};

FormattedRelative.defaultProps = {
  options: {},
  value: undefined,
};
