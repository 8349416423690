import styled, { keyframes } from 'styled-components';

const LoaderAnimation = keyframes`
  0%,
  100% {
    box-shadow:
      0 -3em 0 0.2em,
      2em -2em 0 0,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0,
      2em -2em 0 0.2em,
      3em 0 0 0,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  25% {
    box-shadow:
      0 -3em 0 -0.5em,
      2em -2em 0 0,
      3em 0 0 0.2em,
      2em 2em 0 0,
      0 3em 0 -1em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0 0 0,
      2em 2em 0 0.2em,
      0 3em 0 0,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  50% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 0,
      0 3em 0 0.2em,
      -2em 2em 0 0,
      -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 0,
      -2em 2em 0 0.2em,
      -3em 0 0 0,
      -2em -2em 0 -1em;
  }
  75% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 0,
      -3em 0 0 0.2em,
      -2em -2em 0 0;
  }
  87.5% {
    box-shadow:
      0 -3em 0 0,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 0,
      -3em 0 0 0,
      -2em -2em 0 0.2em;
  }
`;

const Loader = styled.span`
  display: block;
  color: ${(props) => props.theme.colors.primary};
  font-size: 12px;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  animation-name: ${LoaderAnimation};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform: translateZ(0);
`;

export default Loader;
