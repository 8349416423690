// internal
import { API } from '_js/store/constants';

export const FETCH_RECOMMENDED_UPDATE = 'API/SYSTEM/FETCH_RECOMMENDED_UPDATE';
export const FETCH_RECOMMENDED_UPDATE_SUCCESS = 'API/SYSTEM/FETCH_RECOMMENDED_UPDATE_SUCCESS';
export const FETCH_RECOMMENDED_UPDATE_FAILED = 'API/SYSTEM/FETCH_RECOMMENDED_UPDATE_FAILED';

export const SUBMIT_RECOMMENDED_UPDATE = 'API/SYSTEM/SUBMIT_RECOMMENDED_UPDATE';
export const SUBMIT_RECOMMENDED_UPDATE_SUCCESS = 'API/SYSTEM/SUBMIT_RECOMMENDED_UPDATE_SUCCESS';
export const SUBMIT_RECOMMENDED_UPDATE_FAILED = 'API/SYSTEM/SUBMIT_RECOMMENDED_UPDATE_FAILED';

const success = (type, payload) => ({
  type,
  payload,
});

const failure = (type, error) => ({
  type,
  message: 'Recommended update failure',
  error,
});

export const fetchRecommendenUpdate = (id) => ({
  type: API,
  types: [
    FETCH_RECOMMENDED_UPDATE,
    FETCH_RECOMMENDED_UPDATE_SUCCESS,
    FETCH_RECOMMENDED_UPDATE_FAILED,
  ],
  payload: () => ({
    url: `/applications/${id}/recommended-update`,
    success,
    failure,
    auth: true,
  }),
  axios: {
    withCredentials: true,
  },
});

export const submitRecommendedUpdate = (id) => ({
  type: API,
  types: [
    SUBMIT_RECOMMENDED_UPDATE,
    SUBMIT_RECOMMENDED_UPDATE_SUCCESS,
    SUBMIT_RECOMMENDED_UPDATE_FAILED,
  ],
  method: 'post',
  payload: () => ({
    url: `/applications/${id}/recommended-update`,
    success,
    failure,
    throwOnError: true,
    auth: true,
  }),
  axios: {
    withCredentials: true,
  },
});
