/* eslint-disable import/prefer-default-export */

export const loadScript = (url, customOptions = {}) => {
  const existingScriptElement = document.querySelector(`[src="${url}"]`);
  const scriptIsAlreadyLoaded = Boolean(existingScriptElement);

  if (scriptIsAlreadyLoaded) {
    if (customOptions.onload) {
      customOptions.onload();
    }
    return false;
  }

  const scriptElement = Object.assign(
    document.createElement('script'),
    { src: url },
    customOptions,
  );

  return document.head.appendChild(scriptElement);
};

export const addScript = (javascript, id, onLoad) => {
  const existing = document.getElementById(id);
  if (!existing) {
    const script = document.createElement('script');
    script.innerHTML = javascript;
    script.id = id;
    document.head.appendChild(script);
    if (onLoad) {
      onLoad();
    }
    return script;
  }
  return existing;
};
