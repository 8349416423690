import PropTypes from 'prop-types';

const Spinner = ({ classes }) => (
  <div className={[...classes, 'c-spinner c-spinner--inline-block'].join(' ')} />
);

Spinner.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Spinner;
