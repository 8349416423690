import PropTypes from 'prop-types';

const StatusMessage = ({ state, children, className }) => (
  <section
    className={`
    o-section
    c-text--align-center
    ${className}
  `}
  >
    <div
      className="
      o-container
      o-container--no-mobile-padding
    "
    >
      <div
        className={`
        c-card
        c-card--center
        c-card--full-width@mobile-contain
        c-card--no-margin
        c-card--${state}
      `}
      >
        <div className="c-card__content">{children}</div>
      </div>
    </div>
  </section>
);

StatusMessage.propTypes = {
  state: PropTypes.oneOf(['success', 'warning', 'error', 'grey']),
  children: PropTypes.node,
  className: PropTypes.string,
};

StatusMessage.defaultProps = {
  state: 'grey',
  children: null,
  className: '',
};

export default StatusMessage;
