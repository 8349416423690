import { APPLICATION_OPTIMIZE_STATE } from '_js/constants/ApplicationOnHold';
import LOGOS from '../SE/CreditorLogos';
import creditors from '../SE/CreditorsName';

export default {
  phone: {
    number: '+46770220180',
    display: '0770 - 22 01 80',
  },
  email: 'kundtjanst@sambla.se',
  chatOpeningHours: {
    display: '09-20'
  },
  currency: {
    code: 'SEK',
    display: 'kr',
  },
  tracking: {
    googleTagManagerId: 'GTM-WG44SN4',
    hotjarId: 2834060,
  },
  tink: {
    baseUrl: 'https://link.tink.com/1.0/reports/create-report',
    reportTypes: ['INCOME_CHECK_REPORT', 'RISK_INSIGHTS_REPORT', 'EXPENSE_CHECK_REPORT'],
    clientId: 'b4559e3dbd8d4541af3fc32ff16926c2',
    redirectPath: '/application',
    scopes: {
      all: {
        accounts: 'read',
        transactions: 'read',
        user: 'read',
        identity: 'read',
        investments: 'read',
        credentials: 'read',
      },
      accountsOnly: {
        accounts: 'read',
      },
    },
  },
  homePageInsuranceLink: 'https://www.sambla.se/trygghetsforsakring/',
  optimizeSteps: APPLICATION_OPTIMIZE_STATE,
  logos: LOGOS,
  creditors,
  website: {
    url: 'https://www.sambla.se/',
    display: 'sambla.se',
  },
  languageSwitchSelect: `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="14" height="7"%3E%3Cpath fill="%23fff" fill-rule="evenodd" d="M5.732 6.56c.58.59 1.52.592 2.101 0l5.399-5.491C13.812.479 13.619 0 12.79 0H.776C-.05 0-.248.477.333 1.069L5.732 6.56z"/%3E%3C/svg%3E%0A');`,
};
