import PropTypes from 'prop-types';

const scrollToHash = (id, e) => {
  const element = document.getElementById(id);
  if (typeof element.scrollIntoView !== 'undefined') {
    e.preventDefault();
    if (!element) {
      return;
    }

    element.scrollIntoView({ behavior: 'smooth' });
  }
};

const HashLink = (props) => (
  <a onClick={(e) => scrollToHash(props.targetId, e)} href={`#${props.targetId}`}>
    {props.children}
  </a>
);

HashLink.propTypes = {
  targetId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default HashLink;
