import styled, { keyframes } from 'styled-components';
import lottie from 'lottie-web';
import { useEffect } from 'react';
import SamblaLogo from '../../../images/sambla-animation.svg?react';
import DigifinansLoadingAnimation from '../../../images/digifinans-loading-animation.json';
import AdvisaLoadingAnimation from '../../../images/advisa-loading-animation.json';

const AdvisaLoadingContainer = styled.div`
  height: 200px;
`;

export const AdvisaLoading = () => {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#advisa-loader'),
      animationData: AdvisaLoadingAnimation,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      name: 'advisa-loader',
    });

    return () => {
      lottie.destroy('advisa-loader');
    };
  }, []);

  return <AdvisaLoadingContainer id="advisa-loader" data-testid="advisa-loader" />;
};

const DigifinansLoadingContainer = styled.div`
  height: 200px;
`;

export const DigifinansLoading = () => {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#digifinans-loader'),
      animationData: DigifinansLoadingAnimation,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      name: 'digifinans-loader',
    });

    return () => {
      lottie.destroy('digifinans-loader');
    };
  }, []);

  return <DigifinansLoadingContainer id="digifinans-loader" data-testid="digifinans-loader" />;
};

const Outline = keyframes`
  from {
    stroke-dashoffset: 2300;
    opacity: 1;
  }
  to {
    stroke-dashoffset: 0;
    opacity: 1;
  }
`;

export const StyledSamblaLogo = styled(SamblaLogo)`
  .sambla-animation,
  .sambla-animation-path {
    stroke-dashoffset: 928.137;
    stroke-dasharray: 928.137;
    transition: stroke-dashoffset 0.6s ease-out 0s;
    animation: 4s ${Outline} infinite;
  }
`;
