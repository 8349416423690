import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { FormattedNumberWithFallback } from '_js/utils/FormattedNumberWithFallback';
import {
  TotalLoansWrapper,
  HeaderTitle,
  TotalCurrencyValue,
} from './GjeldsregisteretCurrentLoansTotalDebt.styles';

const GjeldsregisteretCurrentLoansTotalDebt = ({ currentLoansTotalAmount }) => (
  <TotalLoansWrapper>
    <HeaderTitle>
      <FormattedMessage
        id="gjeldsregisteret.current.loans.total.debt"
        defaultMessage="Total debt"
        description="A text to prompt the total debt"
      />
    </HeaderTitle>
    <TotalCurrencyValue>
      <FormattedNumberWithFallback value={currentLoansTotalAmount} formatAs="currency" />
    </TotalCurrencyValue>
  </TotalLoansWrapper>
);

GjeldsregisteretCurrentLoansTotalDebt.propTypes = {
  currentLoansTotalAmount: PropTypes.number,
};

GjeldsregisteretCurrentLoansTotalDebt.defaultProps = {
  currentLoansTotalAmount: null,
};

export default GjeldsregisteretCurrentLoansTotalDebt;
