import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import messageDescriptorSchema from '_js/schemas/message-descriptor';
import intlShape from '_js/schemas/intl';
import devices from '_js/styles/Devices';
import { BiddingStateWrapper } from '_js/components/molecules/BiddingStateDisplay';

const Wrapper = styled.h1`
  font-family: var(--wallet-font-family-secondary), serif;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding-top: 0.1em;
  -webkit-font-smoothing: antialiased;
  display: flex;
  color: ${(props) => props.theme.headerText.color};
  justify-content: ${({ $justifyContent }) => $justifyContent || 'left'};
  margin-top: ${({ $marginTop }) => $marginTop || '0'};
  font-size: 2rem;

  ${BiddingStateWrapper} & {
    ${(props) =>
      props.theme.id === 'sambla' &&
      css`
        font-size: 1rem;
      `}
  }

  @media ${devices.mobileMediumAndUp} {
    width: min-content;
    min-width: 9em;
  }

  @media ${devices.mobileLargeAndUp} {
    font-size: 2rem;
  }

  @media ${devices.desktop} {
    width: auto;
    justify-content: ${({ $justifyContent }) => $justifyContent || 'left'};
    font-size: ${(props) => (props.theme.id === 'sambla' ? '1.5rem' : '2.5rem')};
  }
`;

const Title = styled.span`
  ${({ $animatedEllipsis }) =>
    $animatedEllipsis &&
    css`
      &::after {
        display: inline-block;
        animation: ellipsis 3s infinite;
        content: '';
        text-align: left;
        margin-bottom: 0.5rem;
        position: absolute;
      }

      @keyframes ellipsis {
        0% {
          content: '';
        }
        25% {
          content: '.';
        }
        50% {
          content: '..';
        }
        75% {
          content: '...';
        }
      }
    `}
`;

const HeaderText = ({
  headline,
  intl: { formatMessage },
  animatedEllipsis,
  justifyContent,
  marginTop,
  values,
  className,
}) => (
  <Wrapper className={className} $marginTop={marginTop} $justifyContent={justifyContent}>
    <Title $animatedEllipsis={animatedEllipsis}>{formatMessage(headline, { values })}</Title>
  </Wrapper>
);

HeaderText.propTypes = {
  headline: messageDescriptorSchema.isRequired,
  intl: intlShape.isRequired,
  animatedEllipsis: PropTypes.bool,
  justifyContent: PropTypes.string,
  marginTop: PropTypes.string,
  values: PropTypes.string,
  className: PropTypes.string,
};

HeaderText.defaultProps = {
  animatedEllipsis: false,
  justifyContent: '',
  marginTop: '',
  values: '',
  className: '',
};

export default injectIntl(HeaderText);
