import { FormattedMessage } from 'react-intl';

import { getBrandConstants } from '_js/brands';
import StatusMessage from '_js/components/organisms/StatusMessage';

const ErrorBoundaryFallback = () => (
  <>
    <StatusMessage state="error">
      <h2>
        <FormattedMessage
          id="errorBoundary.defaultErrorMessage"
          defaultMessage="Oh, something went wrong."
          description="The default error message when something has failed - Oops, something went wrong"
        />
      </h2>
      <button
        type="submit"
        onClick={() => window.location.reload()}
        className="
          c-button--unstyled
          c-link
          c-link--white
          c-link--underline
        "
      >
        <FormattedMessage
          id="errorBoundary.reloadPage"
          defaultMessage="Try reloading the page"
          description="As something has went so wrong that the application has crashed, suggest to the user to reload the page"
        />
      </button>
    </StatusMessage>
    <p className="c-text--align-center">
      <FormattedMessage
        id="errorBoundary.contactCustomerService"
        defaultMessage="Or contact customer service if it still does not work: {phone}"
        description="Once the application has crashed, as a last resort, ask the customer to call customer service"
        values={{
          phone: (
            <a
              className="
          c-link
          c-link--blue
          c-text--no-wrap
          c-text--underline
        "
              href={`tel:${getBrandConstants().phone.number}`}
            >
              {getBrandConstants().phone.display}
            </a>
          ),
        }}
      />
    </p>
  </>
);

export default ErrorBoundaryFallback;
