import anonymize from '_js/utils/anonymize';

import { cloneDeep, has, get, set } from 'lodash-es';

const MAXIMUM_NR_ENTRIES = 15;

export const actionTransformer = (action) => {
  if (action.type.startsWith('API/SYSTEM')) {
    return null;
  }

  return {
    action: action.type,
  };
};

const ANONYMIZE_STORE_KEYS = [
  'token',
  'bidding.acceptedBid.bidAccept.bankAccount',
  'ppi.autoStartToken',
  'ppi.qr',
  'application.applicant',
  'application.applicantDraft',
  'application.bankAccount',
  'application.bankAccounts',
  'application.coApplicant',
  'auth.autoStartToken',
];

export const stateTransformer = (state) => {
  try {
    const transformedState = cloneDeep(state);

    ANONYMIZE_STORE_KEYS.forEach((key) => {
      if (has(transformedState, key)) {
        const value = get(transformedState, key);
        const anonimizedValue = anonymize(value);
        set(transformedState, key, anonimizedValue);
      }
    });

    delete transformedState.applicationLogs;
    delete transformedState.form;
    delete transformedState.creditors;
    delete transformedState.bidding.invites;
    delete transformedState.auth.user;

    // Bidding
    let { bids } = transformedState.bidding;
    if (bids.length > MAXIMUM_NR_ENTRIES) {
      bids = bids.slice(0, MAXIMUM_NR_ENTRIES);
    }

    transformedState.bidding = {
      ...transformedState.bidding,
      bids,
    };

    return transformedState;
  } catch (err) {
    return {
      error: 'Sentry redux stateTransformer failed to transform the state during error reporting',
      message: err.message,
    };
  }
};
