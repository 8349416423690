import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Link from '_js/components/ui/Link';
import MissingSharingNationalId from '../molecules/MissingSharingNationalId';
import GjeldsregisteretSigninModal from './GjeldsregisteretSigninModal';
import { getBrandIcon } from './index';

import {
  Heading,
  BodyText,
  ConsentButton,
  SkipButton,
  MetaText,
  Section,
  Layout,
} from './GjeldsregisteretConsent.styles';

const GjeldsregisteretLink = () => (
  // eslint-disable-next-line formatjs/no-literal-string-in-jsx
  <Link href="https://www.gjeldsregisteret.com/" rel="noopener" target="_blank">
    gjeldsregisteret.com
  </Link>
);

const GjeldsregisteretConsent = ({ setIsGRJourneyFinished }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const invalidNationlId = searchParams.has('error');
  const handleClose = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Layout>
        {invalidNationlId && <MissingSharingNationalId invalidNationlId={invalidNationlId} />}
        <Section>
          {getBrandIcon()}
          <Heading>
            <FormattedMessage
              id="gjeldsregisteret.consent.heading"
              defaultMessage="Complete your application"
              description="A heading for prompting the user to proceed in their loan application"
            />
          </Heading>
          <BodyText>
            <FormattedMessage
              id="gjeldsregisteret.consent.body"
              defaultMessage="To complete your application, you can verify and share your debt situtation."
              description="A text to prompt the user to add additional info about their financial siutation"
            />
          </BodyText>
          <ConsentButton
            fullWidth={false}
            onClick={() => setIsModalOpen(true)}
            primary
            outline={false}
          >
            <FormattedMessage
              id="gjeldsregisteret.consent.button"
              defaultMessage="Accept and continue"
              description="A button for the user to consent and continue in their loan application process"
            />
          </ConsentButton>
          <MetaText>
            <FormattedMessage
              id="gjeldsregisteret.consent.metaText"
              defaultMessage="The sharing period is 6 months, and consent can be withdrawn at {gjeldsregisteretLink}. Sambla Group might store your personal data longer in accordance with our privacy guidelines."
              description="Text that informs the user about data privacy guidelines when using Gjeldsregisteret via Sambla Group"
              values={{ gjeldsregisteretLink: GjeldsregisteretLink() }}
            />
          </MetaText>
          <SkipButton fullWidth={false} onClick={() => setIsGRJourneyFinished(true)} outline>
            <FormattedMessage
              id="gjeldsregisteret.consent.skip"
              defaultMessage="Skip (not recommended)"
              description="Button text to skip gjeldsregisteret process"
            />
          </SkipButton>
        </Section>
      </Layout>
      <GjeldsregisteretSigninModal isOpen={isModalOpen} handleClose={handleClose} />
    </>
  );
};

GjeldsregisteretConsent.propTypes = {
  setIsGRJourneyFinished: PropTypes.func.isRequired,
};

export default GjeldsregisteretConsent;
