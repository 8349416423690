import * as Sentry from '@sentry/react';
import { skipAccountScraping } from '_js/utils/api/API';
import { AdvisaError } from '_js/constants/Errors';
import { fetchApplication } from './fetch';

export const SKIP_APPLICATION_TASK = 'API/SYSTEM/SKIP_APPLICATION_TASK';
export const SKIP_APPLICATION_TASK_SUCCESS = 'API/SYSTEM/SKIP_APPLICATION_TASK_SUCCESS';
export const SKIP_APPLICATION_TASK_FAILED = 'API/SYSTEM/SKIP_APPLICATION_TASK_FAILED';

export const skipApplicationTaskLoading = () => ({
  type: SKIP_APPLICATION_TASK,
});

export const skipApplicationTaskFailed = () => ({
  type: SKIP_APPLICATION_TASK_FAILED,
});

export const skipApplicationTask = (applicationId, task) => async (dispatch) => {
  dispatch(skipApplicationTaskLoading());
  try {
    await skipAccountScraping(applicationId, task);
    await dispatch(fetchApplication(applicationId));
    localStorage.removeItem('optimize-application-steps');
  } catch (error) {
    dispatch(skipApplicationTaskFailed());
    if (!(error instanceof AdvisaError)) {
      Sentry.captureException(error);
    }
  }
};
