import Cookies from 'js-cookie';

import { sendEvent, isLatestEvent } from '_js/utils/3rdparty/gtm';
import webBaseCookieNormalize from './web-base-cookie-handler';

export const defaultCookieOptions = {
  expires: 300,
};

export const saveState = (name, state, options) => {
  try {
    // Log form interaction if it's not the latest event already
    if (!isLatestEvent('interaction')) {
      sendEvent({
        event: 'interaction',
        eventCategory: 'form-interaction',
        eventAction: 'field-change',
        window_location: '*',
      });
    }

    Cookies.set(name, JSON.stringify(state), { ...defaultCookieOptions, ...options });
  } catch (err) {
    // Ignore write errors
  }
};

export const loadState = (name, options) => {
  try {
    let serializedState = Cookies.get(name, options);
    if (serializedState === null) {
      return undefined;
    }

    if (name === 'application-auto-submit-form') {
      serializedState = webBaseCookieNormalize(serializedState);
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const clearState = (name, options) => {
  Cookies.remove(name, options);
};
