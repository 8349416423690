import { defineMessages } from 'react-intl';

const messages = defineMessages({
  consolidateLoans: {
    defaultMessage: 'Consolidate loans',
    id: 'loanPurposeTypes.consolidateLoans',
    description: 'Purpose of the loan - Consolidate loans',
  },
  vehicles: {
    defaultMessage: 'Vehicle (e.g.: car, MC)',
    id: 'loanPurposeTypes.vehicles',
    description: 'Purpose of the loan - Vehichle expenses',
  },
  consumption: {
    defaultMessage: 'Shopping/consumption',
    id: 'loanPurposeTypes.consumption',
    description: 'Purpose of the loan - Shopping/consumption',
  },
  health: {
    defaultMessage: 'Health/medical',
    id: 'loanPurposeTypes.health',
    description: 'Purpose of the loan - Health',
  },
  renovation: {
    defaultMessage: 'Renovation',
    id: 'loanPurposeTypes.renovation',
    description: 'Purpose of the loan - Renovation',
  },
  services: {
    defaultMessage: 'Services (e.g.: wedding)',
    id: 'loanPurposeTypes.services',
    description: 'Purpose of the loan - Services(for example "wedding")',
  },
  buffer: {
    defaultMessage: 'Investment/buffer',
    id: 'loanPurposeTypes.buffer',
    description: 'Purpose of the loan - Buffer/Investment',
  },
  studies: {
    defaultMessage: 'Studies',
    id: 'loanPurposeTypes.studies',
    description: 'Purpose of the loan - Studies',
  },
  travel: {
    defaultMessage: 'Travel',
    id: 'loanPurposeTypes.travel',
    description: 'Purpose of the loan - Travel',
  },
  other: {
    defaultMessage: 'Other',
    id: 'loanPurposeTypes.other',
    description: 'Purpose of the loan - Other',
  },
  combiningLoans: {
    defaultMessage: 'Consolidate loans',
    id: 'loanPurposeTypes.combiningLoans',
    description: 'Purpose of the loan - Combine/consolidate loans',
  },
  everydayExpenses: {
    defaultMessage: 'Daily expenses',
    id: 'loanPurposeTypes.everydayExpenses',
    description: 'Purpose of the loan - Everyday expenses/daily spendings',
  },
  home: {
    defaultMessage: 'For the home',
    id: 'loanPurposeTypes.home',
    description: 'Purpose of the loan - For the home',
  },
  vehicle: {
    defaultMessage: 'Vehicle',
    id: 'loanPurposeTypes.vehicle',
    description: 'Purpose of the loan - Vehicle',
  },
});

const loanPurposeTypeLabels = {
  consolidateLoans: {
    label: messages.consolidateLoans,
    value: 'consolidateLoans',
  },
  vehicles: {
    label: messages.vehicles,
    value: 'vehicles',
  },
  consumption: {
    label: messages.consumption,
    value: 'consumption',
  },
  health: {
    label: messages.health,
    value: 'health',
  },
  renovation: {
    label: messages.renovation,
    value: 'renovation',
  },
  services: {
    label: messages.services,
    value: 'services',
  },
  buffer: {
    label: messages.buffer,
    value: 'buffer',
  },
  studies: {
    label: messages.studies,
    value: 'studies',
  },
  travel: {
    label: messages.travel,
    value: 'travel',
  },
  other: {
    label: messages.other,
    value: 'other',
  },
  combiningLoans: {
    label: messages.combiningLoans,
    value: 'combiningLoans',
  },
  everydayExpenses: {
    label: messages.everydayExpenses,
    value: 'everydayExpenses',
  },
  home: {
    label: messages.home,
    value: 'home',
  },
  vehicle: {
    label: messages.vehicle,
    value: 'vehicle',
  },
};

export default loanPurposeTypeLabels;
