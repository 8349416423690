import { useSelector } from 'react-redux';
import { getCreditCardOffers } from '_js/utils/api/API';

export default async () => {
  const applicationId = useSelector((state) => state.auth.applicationId);

  if (!applicationId) throw new Error('Token is missing!');
  const response = await getCreditCardOffers(applicationId);

  if (!response) throw new Error('Something went wrong with the response!');

  return response;
};
