/**
 * Supposed to have a syntax that is compatible with:
 * - https://github.com/albburtsev/bem-cn
 * - https://github.com/azproduction/b_
 */

const CLASS_SEPARATOR = ' ';
const BEM_SEPARATORS = {
  element: '__',
  modifier: '--',
  modifierKeyValue: '_',
};

const bemArgsToObject = (args) => {
  const hasElement = typeof args[0] === 'string';

  if (hasElement) {
    return {
      element: args[0],
      modifiers: args[1],
    };
  }

  return {
    modifiers: args[0],
  };
};

const mapModifierKeyValue = ({ modifierKey, modifierValue }) => {
  const modifierType = typeof modifierValue;

  if (modifierValue === true) {
    return modifierKey;
  }
  if (modifierType === 'string') {
    return [modifierKey, modifierValue].join(BEM_SEPARATORS.modifierKeyValue);
  }

  throw new Error(`BEM modifier value must be a boolean or a string, given: ${modifierType}`);
};

const modifiersToArray = (mixedModifiers) => {
  const modifiersType = typeof mixedModifiers;

  if (modifiersType !== 'object') {
    throw new Error(`BEM modifier must be an object or an array, given: ${modifiersType}`);
  }

  if (Array.isArray(mixedModifiers)) {
    return mixedModifiers;
  }

  return Object.keys(mixedModifiers)
    .map((modifierKey) => ({
      modifierKey,
      modifierValue: mixedModifiers[modifierKey],
    }))
    .filter(({ modifierValue }) => modifierValue !== false)
    .map(mapModifierKeyValue);
};

const getElementClass = (block, element) => {
  if (!element) {
    return null;
  }

  const elementType = typeof element;

  if (elementType !== 'string') {
    throw new Error(`BEM element must be a string, given: ${elementType}`);
  }

  return [block, element].join(BEM_SEPARATORS.element);
};

const getModifierClassesString = (block, element, mixedModifiers) => {
  if (!mixedModifiers) {
    return null;
  }

  const modifiersArray = modifiersToArray(mixedModifiers);

  const hasModifiers = Object.keys(modifiersArray).length > 0;

  if (!hasModifiers) {
    return null;
  }

  const elementClass = getElementClass(block, element);

  const modifierClasses = modifiersArray.map((modifier) =>
    [elementClass || block, modifier].join(BEM_SEPARATORS.modifier),
  );

  return modifierClasses.join(CLASS_SEPARATOR);
};

const getClasses = (block, ...args) => {
  const { element, modifiers } = bemArgsToObject(args);
  const classes = [];

  if (element) {
    classes.push(getElementClass(block, element));
  } else {
    classes.push(block);
  }

  if (modifiers) {
    const modifierClasses = getModifierClassesString(block, element, modifiers);
    if (modifierClasses) {
      classes.push(modifierClasses);
    }
  }

  return classes.join(CLASS_SEPARATOR);
};

const createBlock = (blockInput) => {
  if (!blockInput) {
    throw new Error('Block name needs to be provided in the constructor');
  }

  const blockType = typeof blockInput;
  if (blockType !== 'string') {
    throw new Error(`BEM block must be a string, given: ${blockType}`);
  }

  const block = blockInput.trim();

  const getClassesForBlock = getClasses.bind(this, block);
  getClassesForBlock.toString = getClassesForBlock;

  return getClassesForBlock;
};

export default createBlock;
