const loadOptimize = (callback) => {
  const existingScript = document.getElementById('googleOptimize');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://www.googleoptimize.com/optimize.js?id=GTM-5H5WQF9';
    script.id = 'googleOptimize';
    document.head.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

export default loadOptimize;
