import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import useEffectOnce from '_js/hooks/useEffectOnce';
import { Header } from './GjeldsregisteretCurrentLoans.styles';
import GjeldsregisteretRepaymentLoan from './GjeldregisteretRepaymentLoan';
import GjeldsregisteretCreditFacility from './GjeldregisteretCreditFacility';
import GjeldsregisteretChargeCard from './GjeldregisteretChargeCard';

const StyledInfoItem = styled.section`
  margin: 1em 0;
`;

export default function GjeldsregisteretCurrentLoansMoreInfoItem({ loan }) {
  const [headerProps, setHeaderProps] = useState({});
  const determineHeaderProps = (loanType) => {
    switch (loanType) {
      case 'repaymentLoan':
        setHeaderProps({
          gjeldsregisteret: 'currentLoans',
          text: (
            <FormattedMessage
              id="gjeldsregisteret.current.loans.private.loans.2"
              defaultMessage="Private Loan"
              description="A text to prompt the private Loans"
            />
          ),
        });
        break;
      case 'creditFacility':
        setHeaderProps({
          gjeldsregisteret: 'creditCard',
          text: (
            <FormattedMessage
              id="gjeldsregisteret.current.loans.credit.card.2"
              defaultMessage="Credit cards"
              description="A text to prompt the credit card"
            />
          ),
        });

        break;
      case 'chargeCard':
        setHeaderProps({
          gjeldsregisteret: 'changeCards',
          text: (
            <FormattedMessage
              id="gjeldsregisteret.current.loans.change.cards.2"
              defaultMessage="Charge Cards"
              description="A text to prompt the change cards"
            />
          ),
        });

        break;
      default:
        setHeaderProps({
          gjeldsregisteret: 'changeCards',
          text: 'Unable to determine header text',
        });
        break;
    }
  };

  useEffectOnce(() => {
    determineHeaderProps(loan.loanType);
  });

  return (
    <StyledInfoItem>
      <Header gjeldsregisteret={headerProps.gjeldsregisteret} data-testid="header">
        {headerProps.text}
      </Header>
      {loan?.loanType === 'repaymentLoan' && <GjeldsregisteretRepaymentLoan loan={loan} />}
      {loan?.loanType === 'creditFacility' && <GjeldsregisteretCreditFacility loan={loan} />}
      {loan?.loanType === 'chargeCard' && <GjeldsregisteretChargeCard loan={loan} />}
    </StyledInfoItem>
  );
}

GjeldsregisteretCurrentLoansMoreInfoItem.propTypes = {
  loan: PropTypes.shape({
    id: PropTypes.number,
    balance: PropTypes.number,
    coBorrower: PropTypes.string,
    consentId: PropTypes.number,
    createdAt: PropTypes.string,
    financialInstitutionId: PropTypes.string,
    financialInstitutionName: PropTypes.string,
    installmentChargePeriod: PropTypes.string,
    installmentCharges: PropTypes.number,
    interestBearingBalance: PropTypes.number,
    loanType: PropTypes.string,
    nominalInterestRate: PropTypes.number,
    nonInterestBearingBalance: PropTypes.number,
    originalBalance: PropTypes.number,
    processedTime: PropTypes.string,
    providerId: PropTypes.string,
    receivedTime: PropTypes.string,
    type: PropTypes.number,
    updatedAt: PropTypes.string,
    terms: PropTypes.number,
  }),
};

GjeldsregisteretCurrentLoansMoreInfoItem.defaultProps = {
  loan: null,
};
