/* eslint-disable default-param-last */
// internal
import Cookie from 'js-cookie';
import { getAcceptedBidFromList } from '_js/utils/bidOperators';
import { PAYSLIP_STATE } from '_js/constants/States';
import {
  ACCEPTED_BID_POLLING_DONE,
  SHOULD_REDIRECT_TO_PPI,
  RESET_REDIRECT_TO_PPI,
} from '_js/store/actions/bidding';
import {
  FETCH_BIDDING,
  FETCH_BIDDING_SUCCESS,
  FETCH_BIDDING_FAILED,
} from '_js/store/actions/api/bidding/fetch';

import {
  SHOULD_REDIRECT_TO_CREDITOR,
  SET_PAYSLIP_VALIDATION_STATE,
} from '_js/store/actions/should-redirect-to-creditor';
import REQUIRE_BANKACCOUNT from '_js/constants/bids';
import { isCrmUser } from '_js/utils/crm';

const parseBool = (value) => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return false;
};

export const initialState = {
  loadingNewBidRequests: false,
  state: null,
  bids: [],
  invites: [],
  loading: true,
  updateLoading: false,
  acceptedBid: null,
  requestNewBidsRequiresUcCheck: false,
  requestNewBidsStatus: null,
  acceptedBidPollingDone: false,
  biddingReceivedTracked: false,
  shouldRedirectToCreditor: false,
  payslipValidationState: Cookie.get('payslip-validation') || PAYSLIP_STATE.INITIAL,
  showPPIPage: false,
  skippedInsuranceStep: parseBool(localStorage?.getItem('skipped-insurance-step')),
  mortgage: {
    eligible: false,
  },
};

const requiresBankAccount = (value) => {
  switch (value) {
    case REQUIRE_BANKACCOUNT.ALWAYS:
      return REQUIRE_BANKACCOUNT.ALWAYS;
    case REQUIRE_BANKACCOUNT.OPTIONAL_ADVISORS:
      return isCrmUser() ? REQUIRE_BANKACCOUNT.OPTIONAL_ADVISORS : REQUIRE_BANKACCOUNT.ALWAYS;
    case REQUIRE_BANKACCOUNT.NO:
      return REQUIRE_BANKACCOUNT.NO;
    case true:
      return REQUIRE_BANKACCOUNT.ALWAYS;
    case false:
      return REQUIRE_BANKACCOUNT.NO;
    default:
      return value;
  }
};

const sanitizeBidValues = (bid) => {
  const sanitizedBid = bid;
  ['apr', 'totalCost', 'averageMonthlyPayment'].forEach((key) => {
    sanitizedBid[key] = bid[key] || Infinity;
  });
  sanitizedBid.requireBankAccount = requiresBankAccount(sanitizedBid.requireBankAccount);
  return sanitizedBid;
};

export default (state = initialState, action) => {
  let sanitizedBids = [];

  if (action.payload && action.payload.bids && action.payload.bids.length) {
    sanitizedBids = action.payload.bids.map(sanitizeBidValues);
  }

  switch (action.type) {
    case FETCH_BIDDING:
      return {
        ...state,
        loading: !state.state,
      };

    case FETCH_BIDDING_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        bids: sanitizedBids,
        acceptedBid: getAcceptedBidFromList(sanitizedBids) || null,
      };

    case FETCH_BIDDING_FAILED:
      return { ...state, loading: false };

    case ACCEPTED_BID_POLLING_DONE:
      return {
        ...state,
        acceptedBidPollingDone: true,
      };

    case SHOULD_REDIRECT_TO_CREDITOR:
      return {
        ...state,
        shouldRedirectToCreditor: action.payload,
      };
    case SET_PAYSLIP_VALIDATION_STATE:
      return {
        ...state,
        payslipValidationState: action.payload,
      };
    case SHOULD_REDIRECT_TO_PPI:
      localStorage.setItem('skipped-insurance-step', false);
      return {
        ...state,
        showPPIPage: true,
      };

    case RESET_REDIRECT_TO_PPI:
      localStorage.setItem('skipped-insurance-step', true);
      return {
        ...state,
        showPPIPage: false,
      };
    default:
      return state;
  }
};
