import styled from 'styled-components';

export const TotalLoansWrapper = styled.div`
  border: solid 1px;
  border-radius: 0.3em;
  border-color: #e0e0e0;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 90%;
  padding: 0.75em;
  box-shadow: 0 2px 4px rgb(0 0 0 / 9%);
`;

export const HeaderTitle = styled.span`
  color: #002235;
  font-weight: bold;
  text-transform: capitalize;
`;

export const TotalCurrencyValue = styled.span`
  font-size: 48px;
  font-weight: bold;
  color: ${(props) => props.theme.headerText.color};
`;
