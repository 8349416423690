import { APPLICATION_OPTIMIZE_STATE } from '_js/constants/ApplicationOnHold';
import LOGOS from '../FI/CreditorLogos';
import creditors from '../SE/CreditorsName';

export default {
  phone: {
    number: '+358923113670',
    display: '09 2311 3670',
  },
  email: 'asiakaspalvelu@rahalaitos.fi',
  currency: {
    code: 'EUR',
    display: 'euro',
  },
  tracking: {
    googleTagManagerId: 'GTM-T9QT96M',
    hotjarId: 3374890,
  },
  tink: {
    baseUrl: 'https://link.tink.com/1.0/reports/create-report',
    reportTypes: ['INCOME_CHECK_REPORT', 'RISK_INSIGHTS_REPORT', 'EXPENSE_CHECK_REPORT'],
    clientId: '194ca27c6ae047cbbb7fbea125b84720',
    redirectPath: '/application',
    scopes: {
      all: {
        accounts: 'read',
        transactions: 'read',
        user: 'read',
        identity: 'read',
        statistics: 'read',
        investments: 'read',
        credentials: 'read',
      },
      accountsOnly: {
        accounts: 'read',
      },
    },
  },
  optimizeSteps: {
    ADD_CURRENT_LOANS: APPLICATION_OPTIMIZE_STATE.ADD_CURRENT_LOANS,
  },
  logos: LOGOS,
  creditors,
  website: {
    url: 'https://rahalaitos.fi/',
    display: 'rahalaitos.fi',
  },
  languageSwitchSelect: `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="14" height="7"%3E%3Cpath fill="%23fff" fill-rule="evenodd" d="M5.732 6.56c.58.59 1.52.592 2.101 0l5.399-5.491C13.812.479 13.619 0 12.79 0H.776C-.05 0-.248.477.333 1.069L5.732 6.56z"/%3E%3C/svg%3E%0A');`,
};
