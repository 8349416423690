import Cookie from 'js-cookie';
import { PAYSLIP_STATE } from '_js/constants/States';

export const SHOULD_REDIRECT_TO_CREDITOR = 'SHOULD_REDIRECT_TO_CREDITOR';
export const SET_PAYSLIP_VALIDATION_STATE = 'SET_PAYSLIP_VALIDATION_STATE';
export const shouldRedirectToCreditor = (shouldRedirect) => ({
  type: SHOULD_REDIRECT_TO_CREDITOR,
  payload: shouldRedirect,
});
export const setPayslipValidationState = (paySlipState) => {
  // Should only save payslip state if it is successfully performed
  if (paySlipState === PAYSLIP_STATE.PERFORMED) {
    Cookie.set('payslip-validation', PAYSLIP_STATE.PERFORMED);
  }
  return {
    type: SET_PAYSLIP_VALIDATION_STATE,
    payload: paySlipState,
  };
};
