import { getBrandConstants } from '_js/brands'; // eslint-disable-line import/no-extraneous-dependencies

const NUMBER = {
  currency: {
    style: 'currency',
    currency: getBrandConstants().currency.code,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
  interestRate: {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  },
  repaymentTime: {
    // Deprecated, use repaymentTimeYears instead to also get years unit
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
  repaymentTimeYears: {
    style: 'unit',
    unit: 'year',
    unitDisplay: 'short',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
};

export default NUMBER;
