import { bindActionCreators } from 'redux';

import { signIn, signOut, initializeGoogleAuth } from '_js/utils/3rdparty/googleAuth';

import {
  unauthenticated,
  authenticationSuccess,
  authLoadingFailed,
  authorize,
  authorizationSuccess,
  unauthorized,
  AUTH_LOADING,
  AUTHENTICATING,
  AUTHENTICATED,
  LOGOUT,
  logout,
} from '_js/store/actions/auth';
import { FETCH_BIDDING_FAILED } from '_js/store/actions/api/bidding/fetch';
import { FETCH_APPLICATION_FAILED } from '_js/store/actions/api/application/fetch';

import { authorizeGoogleToken } from '_js/utils/api/API';

export default (store) => (next) => (action) => {
  const actions = bindActionCreators(
    {
      unauthenticated,
      authenticationSuccess,
      authLoadingFailed,
      authorize,
      authorizationSuccess,
      unauthorized,
      logout,
    },
    store.dispatch,
  );

  switch (action.type) {
    case AUTH_LOADING:
      initializeGoogleAuth().then((GoogleAuth) => {
        if (GoogleAuth.isSignedIn.get()) {
          const GoogleUser = GoogleAuth.currentUser.get();
          actions.authenticationSuccess(GoogleUser);
        } else {
          actions.unauthenticated();
        }
      }, actions.authLoadingFailed);
      break;

    case AUTHENTICATING:
      signIn({
        success: actions.authenticationSuccess,
        unauthorized: actions.unauthorized,
        failed: () => actions.unauthenticated('ERROR'),
      });
      break;

    case AUTHENTICATED:
      actions.authorize();
      authorizeGoogleToken(action.payload.idToken).then(
        (authResponse) => actions.authorizationSuccess(authResponse.token),
        actions.unauthorized,
      );
      break;

    case FETCH_APPLICATION_FAILED:
      if (action?.error?.response?.status === 401) {
        actions.logout();
      } else if (action?.error?.response?.status >= 500) {
        throw new Error(action.message);
      }
      break;

    case FETCH_BIDDING_FAILED:
      if (action?.error?.response?.status === 401) {
        actions.logout();
      }
      break;

    case LOGOUT:
      signOut();
      break;

    default:
      break;
  }

  next(action);
};
