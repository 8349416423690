import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { FormattedNumberWithFallback } from '_js/utils/FormattedNumberWithFallback';
import {
  Loans,
  Header,
  LabelValueRowWithBorderBottom,
  LabelValueRowNotBorderBottom,
  BoldText,
  NumberText,
} from './GjeldsregisteretCurrentLoans.styles';

const GjeldsregisteretCurrentLoansCreditCards = ({
  currentLoansAmount,
  currentLoansCreditLimit,
  currentLoansTotal,
}) => (
  <Loans>
    <Header gjeldsregisteret="creditCard">
      <FormattedMessage
        id="gjeldsregisteret.current.loans.credit.card"
        defaultMessage="Credit card"
        description="A text to prompt the credit card"
      />
    </Header>
    <LabelValueRowWithBorderBottom>
      <BoldText>
        <FormattedMessage
          id="gjeldsregisteret.current.loans.credit.card.amount"
          defaultMessage="Total"
          description="A text to prompt the total amount"
        />
      </BoldText>
      <NumberText>
        <FormattedNumberWithFallback value={currentLoansAmount} formatAs="currency" />
      </NumberText>
    </LabelValueRowWithBorderBottom>
    <LabelValueRowWithBorderBottom>
      <BoldText>
        <FormattedMessage
          id="gjeldsregisteret.current.loans.credit.limit"
          defaultMessage="Credit limit"
          description="A text to prompt the credit limit"
        />
      </BoldText>
      <NumberText>
        <FormattedNumberWithFallback value={currentLoansCreditLimit} formatAs="currency" />
      </NumberText>
    </LabelValueRowWithBorderBottom>
    <LabelValueRowNotBorderBottom>
      <BoldText>
        <FormattedMessage
          id="gjeldsregisteret.current.loans.credit.card.count"
          defaultMessage="Count"
          description="A text to prompt the count total"
        />
      </BoldText>
      <NumberText>{currentLoansTotal}</NumberText>
    </LabelValueRowNotBorderBottom>
  </Loans>
);

GjeldsregisteretCurrentLoansCreditCards.propTypes = {
  currentLoansAmount: PropTypes.number,
  currentLoansCreditLimit: PropTypes.number,
  currentLoansTotal: PropTypes.number,
};

GjeldsregisteretCurrentLoansCreditCards.defaultProps = {
  currentLoansAmount: null,
  currentLoansCreditLimit: null,
  currentLoansTotal: null,
};

export default GjeldsregisteretCurrentLoansCreditCards;
