import { memo } from 'react';
import { useSelector } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import { formattedRelative } from '_js/utils/formatted-relative';
import intlShape from '_js/schemas/intl';
import { getEarliestExpirationDate, haveAllBidsExpired } from '_js/utils/bidOperators';
import StatusMessage from '_js/components/organisms/StatusMessage';
import { getBrandConstants } from '_js/brands';
import { isBefore, isAfter, subDays, addMilliseconds } from 'date-fns';

const ExpirationWarningMessage = memo(({ intl }) => {
  const bids = useSelector((state) => state.bidding.bids);
  const clientTimeOffset = useSelector((state) => state.clientTimeOffset);
  const bidsExpired = haveAllBidsExpired(bids);

  if (bidsExpired) {
    return (
      <StatusMessage state="warning">
        <FormattedMessage
          id="biddingStateDisplay.expired"
          defaultMessage="The time to accept the offers has expired. You can make a new application at {websiteUrl}"
          description="Informing the customer that time for accepting the offers/bids has expired and are no longer available. Also refering where the customer can make a new application"
          values={{
            websiteUrl: (
              <a href={getBrandConstants().website.url}> {getBrandConstants().website.display}</a>
            ),
          }}
        />
      </StatusMessage>
    );
  }

  const bidsExpiresAt = getEarliestExpirationDate(bids, clientTimeOffset);
  const noBidsExpire = !bidsExpiresAt;
  if (noBidsExpire) return null;

  const warningEndWithOffset = addMilliseconds(bidsExpiresAt, clientTimeOffset);
  const warningStartWithOffset = subDays(warningEndWithOffset, 7);
  const showExpiresSoon =
    isAfter(new Date(), warningStartWithOffset) && isBefore(new Date(), warningEndWithOffset);

  if (showExpiresSoon) {
    return (
      <StatusMessage state="warning" className="o-section--tight">
        <FormattedMessage
          id="biddingStateDisplay.expiresAt"
          defaultMessage="Your offers expire {inXDays}. We are happy to answer your questions on {phoneNumber}."
          description="Informing the customer when his/her bids expires, also helping the customer by having our customer service phone number present"
          values={{
            inXDays: <b>{formattedRelative(intl, warningEndWithOffset)}</b>,
            phoneNumber: (
              <a href={`tel:${getBrandConstants().phone.number}`}>
                {getBrandConstants().phone.display}
              </a>
            ),
          }}
        />
      </StatusMessage>
    );
  }

  return null;
});

ExpirationWarningMessage.propTypes = {
  intl: intlShape.isRequired,
};

const ExpirationWarningMessageWithIntl = injectIntl(ExpirationWarningMessage);
ExpirationWarningMessageWithIntl.displayName = 'ExpirationWarningMessageWithIntl';

export default ExpirationWarningMessageWithIntl;
