import { FormattedMessage } from 'react-intl';
import HashLink from '_js/utils/HashLink';
import { APPLICATION, WEBSITE_STATES } from '_js/constants/States';
import { APPLICATION_OPTIMIZE_STATE } from '_js/constants/ApplicationOnHold';
import { getBrandConstants, getBrandQandA } from '_js/brands';

const phone = { ...getBrandConstants().phone };
const teleHref = `tel:${phone.number}`;
const hashLink = (chunks) => (
  <HashLink targetId="what-happens-now-creditor-logo">{chunks}</HashLink>
);
const teleLink = (chunks) => <a href={teleHref}>{chunks}</a>;
const teleLinkDisplay = <a href={teleHref}>{phone.display}</a>;

const BidsOnDecline = [
  {
    id: 'bidsOnDeclineLowerAmount',
    question: (
      <FormattedMessage
        id="faq.no.question.bidsOnDecline.bidsOnDeclineLowerAmount"
        defaultMessage="Why should I apply for a smaller amount?"
        description="FAQ question displayed at the bottom when the customer did not receive any loan proposals"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.no.answer.bidsOnDecline.bidsOnDeclineLowerAmount"
        defaultMessage="<p>In general, lenders offer the amount they are able to, regardless of the amount you applied for. However, we have noticed that some of our lenders sometimes act differently if you apply for a smaller amount. Go ahead and change the amount you're applying for and see if you can get a loan offer. Keep in mind that this does <strong>not</strong> require another credit check.</p>"
        description="FAQ answer displayed at the bottom when the customer did not receive any loan proposals"
        tagName=""
      />
    ),
  },
  {
    id: 'bidsOnDeclineNewUc',
    question: (
      <FormattedMessage
        id="faq.no.question.bidsOnDecline.bidsOnDeclineNewUc"
        defaultMessage="Do I need to do another credit check through UC?"
        description="FAQ question displayed at the bottom when the customer did not receive any loan proposals"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.no.answer.bidsOnDecline.bidsOnDeclineNewUc"
        defaultMessage="<p>No, you do <strong>not</strong> need to do another credit check.</p>"
        description="FAQ answer displayed at the bottom when the customer did not receive any loan proposals"
        tagName=""
      />
    ),
  },
];

const awaiting = [
  {
    id: 'awaitingMoreBids',
    question: (
      <FormattedMessage
        id="faq.no.question.awaiting.awaitingMoreBids"
        defaultMessage="Am I still waiting for more offers?"
        description="FAQ question displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.no.answer.awaiting.awaitingMoreBids"
        defaultMessage="<p>Yes, more offers may still come in. Once all of the lenders have submitted their offers, you will receive an email and text message confirming that your application has been processed. To be able to make a well-informed decision, we recommend waiting until all lenders have made their offers.</p>"
        description="FAQ answer displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
  },
  {
    id: 'freeAmortization',
    question: (
      <FormattedMessage
        id="faq.no.question.awaiting.freeAmortization"
        defaultMessage="Can I pay off more of my loan whenever I want?"
        description="FAQ question displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.no.answer.awaiting.freeAmortization"
        defaultMessage="<p>Yes, you can always pay off more. You can pay a little more every month or a larger amount when you want, or you can even pay off your entire loan without paying any additional fees. You only pay interest for the time you have active debt.</p>"
        description="FAQ answer displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
  },
  {
    id: 'whenPayout',
    question: (
      <FormattedMessage
        id="faq.no.question.awaiting.whenPayout"
        defaultMessage="When will I get the money?"
        description="FAQ question displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.no.answer.awaiting.whenPayout"
        defaultMessage="<p>That varies from lender to lender, but usually within one or a few days from when you accept the offer.</p><p>The timeline for when the loan is paid out depends on multiples factors. Factors include whether or not the lender uses electronic signature. Generally, electronic signatures are much faster than waiting on the post. To make the process as efficient as possible, it is a good idea to gather and prepare any possible supplementary information that the lender may request (for example, a copy of an ID document, a payslip or employer's certificate).</p>"
        description="FAQ answer displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
  },
];

const onHoldCoApplicant = [
  {
    id: 'coApplicantWhatIsIt',
    question: (
      <FormattedMessage
        id="faq.no.question.onHoldCoApplicant.coApplicantWhatIsIt"
        defaultMessage="What is a co-applicant?"
        description="FAQ question displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.no.answer.onHoldCoApplicant.coApplicantWhatIsIt"
        defaultMessage="<p>A co-applicant is a second person on the loan who is included to provide additional security. In general, having a co-applicant significantly strengthens your application, increasing your chances of getting better loan terms.</p>"
        description="FAQ answer displayed at the bottom when the customer is optimizing their application"
        tagName=""
      />
    ),
  },
  {
    id: 'coApplicantWhyIsItGood',
    question: (
      <FormattedMessage
        id="faq.no.question.onHoldCoApplicant.coApplicantWhyIsItGood"
        defaultMessage="Why is it a good idea to have a co-applicant?"
        description="FAQ question displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.no.answer.onHoldCoApplicant.coApplicantWhyIsItGood"
        defaultMessage="<p>By adding another person to your application who will share the loan, you increase the chances of getting the full loan amount requested, as well as better loan terms. When two people are on the application, the banks see this as double the security. However, remember that your co-applicant needs to have good, steady finances to be able to increase your chances of getting better loan terms.</p>"
        description="FAQ answer displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
  },
  {
    id: 'coApplicantWhoCanItBe',
    question: (
      <FormattedMessage
        id="faq.no.question.onHoldCoApplicant.coApplicantWhoCanItBe"
        defaultMessage="Who can be a co-applicant?"
        description="FAQ question displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
    answer: (
      <FormattedMessage
        id="faq.no.answer.onHoldCoApplicant.coApplicantWhoCanItBe"
        defaultMessage="<p>Anyone you trust. It is common for people to include their spouse or partner as a co-applicant. You can also use a parent, another relative or a friend. Always talk with your co-applicant before applying for a loan.</p>"
        description="FAQ answer displayed at the bottom when the customer is waiting for loan proposals"
        tagName=""
      />
    ),
  },
];

const FAQNo = {
  [APPLICATION.AWAITING]: awaiting,
  [APPLICATION_OPTIMIZE_STATE.ADD_CO_APPLICANT]: onHoldCoApplicant,
  [APPLICATION.HAS_BIDS]: [
    {
      id: 'whatHappensNow',
      question: (
        <FormattedMessage
          id="faq.no.question.hasBids.whatHappensNow"
          defaultMessage="What happens now?"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.no.answer.hasBids.whatHappensNow"
          defaultMessage="<p>The bidding process is now over, and your offers have been received. Because different lenders target different types of loans and different types of customers, all of the lenders who are willing to issue you a loan based on your application have now given their offer.</p><p>All that is left to do now is choose the best offer for you. After that, you will sign the loan agreement, and the money will be issued.</p>"
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
    },
    {
      id: 'whatHappensWhenOfferAccepted',
      question: (
        <FormattedMessage
          id="faq.no.question.hasBids.whatHappensWhenOfferAccepted"
          defaultMessage="What happens when I accept an offer?"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.no.answer.hasBids.whatHappensWhenOfferAccepted"
          defaultMessage="<p>When you accept an offer, the lender you chose will send you an agreement, either electronically or by post. This does <strong>not</strong> mean that you have signed a loan offer.</p>"
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
    },
    {
      id: 'isLoanOfferAcceptBinding',
      question: (
        <FormattedMessage
          id="faq.no.question.hasBids.isLoanOfferAcceptBinding"
          defaultMessage="Am I locked into a loan if I accept?"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.no.answer.hasBids.isLoanOfferAcceptBinding"
          defaultMessage="<p>No. The offer will be sent to you. Don't hesitate to reach out to us if you want us to go through the offer with you and compare it with your current loans, for example, if you are going to use the loan to replace your old loans.</p>"
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
    },
    {
      id: 'whichLoanOfferToChoose',
      question: (
        <FormattedMessage
          id="faq.no.question.hasBids.whichLoanOfferToChoose"
          defaultMessage="How do I know which loan to choose?"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.no.answer.hasBids.whichLoanOfferToChoose"
          defaultMessage="<p>That depends on what is important to you. In general, the cheapest loan will be the one that has the lowest effective interest rate, but it can also be important to look at the monthly payment amount and try to get the lowest one possible. Sometimes it's better to find the loan that consolidates the most debt. Feel free to get in contact with us if you need advice. We can change the amount you applied for, as well as the repayment terms to help get you the best offer.</p>"
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
    },
    {
      id: 'needHelpHaveQuestions',
      question: (
        <FormattedMessage
          id="faq.no.question.hasBids.needHelpHaveQuestions"
          defaultMessage="I need help or have questions"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.no.answer.hasBids.needHelpHaveQuestions"
          defaultMessage="<p>No problem. <teleLink>Contact us</teleLink> for assistance or guidance. We're here to help make things easier for you.</p>"
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
          values={{
            teleLink,
          }}
        />
      ),
    },
    {
      id: 'canChangeLoanAmountRepaymentTime',
      question: (
        <FormattedMessage
          id="faq.no.question.hasBids.canChangeLoanAmountRepaymentTime"
          defaultMessage="Can I change the amount I applied for or the loan term?"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.no.answer.hasBids.canChangeLoanAmountRepaymentTime"
          defaultMessage="<p>Of course! <teleLink>Contact us</teleLink> and we'll update your loan to best meet your needs.</p>"
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
          values={{
            teleLink,
          }}
        />
      ),
    },
    {
      id: 'whyNotWholeAmountGranted',
      question: (
        <FormattedMessage
          id="faq.no.question.hasBids.whyNotWholeAmountGranted"
          defaultMessage="Why is my loan offer less than what I applied for?"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.no.answer.hasBids.whyNotWholeAmountGranted"
          defaultMessage="<p>All lenders offer the maximum amount they are willing to offer you based on the loan amount you have applied for. If a lender has offered a lower amount than what you applied for, their offer reflects the largest loan they are prepared to issue you. If you did a loan comparison but would like to apply for a higher amount, <teleLink>contact us</teleLink> and we'll take care of it.</p>"
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
          values={{
            teleLink,
          }}
        />
      ),
    },
    {
      id: 'whatIsDebtAcquittal',
      question: (
        <FormattedMessage
          id="faq.no.question.hasBids.whatIsDebtAcquittal"
          defaultMessage="What does a debt acquittal mean?"
          description="FAQ question displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.no.answer.hasBids.whatIsDebtAcquittal"
          defaultMessage='<p>A debt acquittal means that the lender will issue you the loan, on the condition that they be able to pay off your previous debts on your behalf. The lender will send you a "pay-off power of attorney", which you will fill out with the loans that are to be paid off. You will then sign the power of attorney, thereby giving your consent for the lender to do this. A debt acquittal is an easy way to avoid having to contact all of your lenders personally to pay off your old debts. Let the lender take care of this for you!</p>'
          description="FAQ answer displayed at the bottom when the customer has received loan proposals"
          tagName=""
        />
      ),
    },
  ],
  [APPLICATION.MORTGAGE_INTEREST]: [
    {
      id: 'whyMortgageApplication',
      question: (
        <FormattedMessage
          id="faq.no.question.mortgageEligible.whyRefinanceMortgage"
          defaultMessage="Why can it pay off to refinance the mortgage?"
          description="FAQ question displayed at the bottom when the consumer loan customer is eligible for a mortgage application"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.no.answer.mortgageEligible.whyRefinanceMortgage"
          defaultMessage="<p>If you have a lot of credits and private loans with high interest rates, it may pay off to consolidate these by refinancing the mortgage as the interest rates usually are lower on a mortgage compared to creditors and unsecured loans.</p>"
          description="FAQ answer displayed at the bottom when the consumer loan customer is eligible for a mortgage application"
          tagName=""
        />
      ),
    },
    {
      id: 'whyNoLoanOffers',
      question: (
        <FormattedMessage
          id="faq.no.question.noBids.whyNoLoanOffers"
          defaultMessage="Why didn't I get any loan offers?"
          description="FAQ question displayed at the bottom when the customer did not receive any loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.no.answer.noBids.whyNoLoanOffers"
          defaultMessage="<p>The lenders have reviewed your application and are unable to issue you a loan at this time. There are many possible reasons for this and we never find out the exact reason.</p>"
          description="FAQ question displayed at the bottom when the customer did not receive any loan proposals"
          tagName=""
        />
      ),
    },
    {
      id: 'whatToDoDifferentInFuture',
      question: (
        <FormattedMessage
          id="faq.no.question.noBids.whatToDoDifferentInFuture"
          defaultMessage="What should I do differently moving forward?"
          description="FAQ question displayed at the bottom when the customer did not receive any loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.no.answer.noBids.whatToDoDifferentInFuture"
          defaultMessage="<p>One factor that impacts short-term credit worthiness is the number of credit reports that have been ordered within a certain period of time. If this applies to you, don't request any more reports to ensure you get the best possible comparison.</p>"
          description="FAQ answer displayed at the bottom when the customer did not receive any loan proposals"
          tagName=""
        />
      ),
    },
  ],
  [APPLICATION.NO_BIDS]: [
    {
      id: 'whyNoLoanOffers',
      question: (
        <FormattedMessage
          id="faq.no.question.noBids.whyNoLoanOffers"
          defaultMessage="Why didn't I get any loan offers?"
          description="FAQ question displayed at the bottom when the customer did not receive any loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.no.answer.noBids.whyNoLoanOffers"
          defaultMessage="<p>The lenders have reviewed your application and are unable to issue you a loan at this time. There are many possible reasons for this and we never find out the exact reason.</p>"
          description="FAQ question displayed at the bottom when the customer did not receive any loan proposals"
          tagName=""
        />
      ),
    },
    {
      id: 'whatToDoDifferentInFuture',
      question: (
        <FormattedMessage
          id="faq.no.question.noBids.whatToDoDifferentInFuture"
          defaultMessage="What should I do differently moving forward?"
          description="FAQ question displayed at the bottom when the customer did not receive any loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.no.answer.noBids.whatToDoDifferentInFuture"
          defaultMessage="<p>One factor that impacts short-term credit worthiness is the number of credit reports that have been ordered within a certain period of time. If this applies to you, don't request any more reports to ensure you get the best possible comparison.</p>"
          description="FAQ answer displayed at the bottom when the customer did not receive any loan proposals"
          tagName=""
        />
      ),
    },
  ],
  [WEBSITE_STATES.NO_BIDS_ON_DECLINED]: [
    {
      id: 'whyNoLoanOffers',
      question: (
        <FormattedMessage
          id="faq.no.question.noBidsOnDeclined.whyNoLoanOffers"
          defaultMessage="Why didn't I get any loan offers?"
          description="FAQ question displayed at the bottom when the customer did not receive any loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.no.answer.noBidsOnDeclined.whyNoLoanOffers"
          defaultMessage="<p>The lenders have reviewed your application and are unable to issue you a loan at this time. There are many possible reasons for this and we never find out the exact reason. </p>"
          description="FAQ answer displayed at the bottom when the customer did not receive any loan proposals"
          tagName=""
        />
      ),
    },
  ].concat(BidsOnDecline),
  [APPLICATION.ACCEPTED]: [
    {
      id: 'whatHappensNow',
      question: (
        <FormattedMessage
          id="faq.no.question.applicationAccepted.whatHappensNow"
          defaultMessage="What happens now?"
          description="FAQ answer displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.no.answer.applicationAccepted.whatHappensNow"
          defaultMessage='<p>Now there is only one step left: sign the agreement. After that, the money will be issued.</p><p>How your agreement is sent to you will depend on the lender you have chosen. Please refer to the list above to find out how you will receive your agreement.</p><p>When you sign the agreement, the lender will sometimes ask you to send along "supplementary information". This can be, for example, a payslip that shows that the information you provided is correct. If the lender wants you to provide more information, it will be stated in the <hashLink>list above</hashLink>.</p>'
          description="FAQ answer displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
          values={{
            hashLink,
          }}
        />
      ),
    },
    {
      id: 'rejectedAfterAccept',
      question: (
        <FormattedMessage
          id="faq.no.question.applicationAccepted.rejectedAfterAccept"
          defaultMessage="What happens if my loan has been denied after approval?"
          description="FAQ question displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.no.answer.applicationAccepted.rejectedAfterAccept"
          defaultMessage="<p>Was your loan denied after approval by a lender? Don't worry! If possible, we'll reopen your portal with your other offers to let you choose a new loan. Contact our customer service line on {teleLinkDisplay} for assistance!</p>"
          description="FAQ answer displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
          values={{
            teleLinkDisplay,
          }}
        />
      ),
    },
    {
      id: 'didNotGetDebtLetter',
      question: (
        <FormattedMessage
          id="faq.no.question.applicationAccepted.didNotGetDebtLetter"
          defaultMessage="I have not received the agreement from the bank"
          description="FAQ question displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
        />
      ),
      answer: (
        <FormattedMessage
          id="faq.no.answer.applicationAccepted.didNotGetDebtLetter"
          defaultMessage="<p>If the lender uses electronic signature, the information should be sent to you by no later than one banking day from the date you accepted. If the lender only sends agreements by post, it may take 1–3 banking days. If you still have not received your loan agreement, please <teleLink>contact us</teleLink> for assistance.</p>"
          description="FAQ answer displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
          values={{ teleLink }}
        />
      ),
    },
  ],
  [APPLICATION_OPTIMIZE_STATE.ADD_CURRENT_LOANS]: [
    {
      id: 'addCurrentLoan',
      question: (
        <FormattedMessage
          id="faq.no.question.optimizeApplication.addCurrentLoan"
          defaultMessage="Why should you fill out more than one loan?"
          description="FAQ question displayed at the bottom when the customer accepted a loan proposals"
          tagName=""
        />
      ),
      answer: getBrandQandA(),
    },
  ],
};

export default FAQNo;
