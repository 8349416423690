import { defineMessages } from 'react-intl';

export const MAIL = 'MAIL';
export const EMAIL = 'EMAIL';
export const EBANK = 'EBANK';
export const SMS = 'SMS';

export const DEBT_LETTER_DELIVERY_METHOD_TRANSLATIONS = defineMessages({
  [MAIL]: {
    id: 'debtDeliveryMethod.mail',
    defaultMessage: 'Post',
  },
  [EMAIL]: {
    id: 'debtDeliveryMethod.email',
    defaultMessage: 'e-mail',
  },
  [EBANK]: {
    id: 'debtDeliveryMethod.ebank',
    defaultMessage: 'internet bank',
  },
  [SMS]: {
    id: 'debtDeliveryMethod.sms',
    defaultMessage: 'SMS',
  },
});

export const DEBT_LETTER_DELIVERY_METHOD_MAPPING = {
  0: [MAIL],
  1: [EMAIL],
  2: [EMAIL, MAIL],
  3: [EBANK],
  4: [EMAIL, SMS],
  5: [SMS],
};

export const DEBT_LETTER_DELIVERY_METHOD_IDS = Object.keys(DEBT_LETTER_DELIVERY_METHOD_MAPPING).map(
  Number,
);
