import existingLanguages from './language-region';

export const region = import.meta.env.VITE_REGION || 'SE';
export const language = import.meta.env.VITE_LANGUAGE || 'sv';
const locale = `${language}-${region}`;

export const currentLocale = {
  ...existingLanguages[language],
  lang: language,
  region,
  locale,
};
