import styled, { keyframes, css } from 'styled-components';
import devices from '_js/styles/Devices';
import { Wrapper as OptimizeStepWrapper } from '_js/components/optimizeApplication/OptimizeStep';
import Loading from '../../../../images/loading.svg?react';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Loader = styled(Loading)`
  width: 1.1em;
  margin-right: 0.5em;
  animation: ${rotate} 1s linear infinite;
`;

export const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-decoration: none;
  font-size: 1.4rem;
  font-family: var(--wallet-font-family-standard), sans-serif;
  padding: 0.7em 1.5rem;
  border: none;
  border-radius: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  min-width: 200px;
  ${({ $primary }) =>
    $primary
      ? css`
          background: var(--primary-button-background);
          color: var(--primary-button-color);
        `
      : css`
          background: var(--secondary-button-background);
          color: var(--secondary-button-color);
        `}

  ${({ $outline }) =>
    $outline &&
    `
    background: var(--primary-button-color);
    color: var(--primary-button-background);
    border: 2px solid var(--primary-button-background);
  `}

  ${(props) =>
    props.disabled &&
    `
    background: var(--light-grey);
    border-color: var(--light-grey);
    cursor: not-allowed;
    color: var(--white);
  `}

  @media ${({ $sticky }) => ($sticky ? devices.desktop : 'all')} {
    position: relative;
    border-radius: 5px;
    ${({ $fullWidth }) =>
      $fullWidth
        ? css`
            width: 100%;
          `
        : css`
            width: auto;
          `}
  }

  ${/* sc-selector */ OptimizeStepWrapper} & {
    margin: 2rem auto 1rem;
  }
`;
