import deepFreeze from '_js/utils/deepFreeze';

let instance;

export default class Config {
  constructor() {
    throw new Error('Cannot instantiate singleton, use instantiate() instead');
  }

  static instantiate(config) {
    if (instance !== undefined) {
      throw new Error('Already instantiated');
    }

    instance = deepFreeze(JSON.parse(JSON.stringify(config)));
  }

  static getValues() {
    if (instance === undefined) {
      throw new Error('Not instantiated, use instantiate() first');
    }

    return instance;
  }
}
