/* eslint-disable react/jsx-filename-extension */

// external
import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';
import { Provider } from 'react-redux';

// internal
import App from '_js/App';
import Favicon from '_js/brands/Favicon';
import * as Tracking from '_js/utils/external/Tracking';
import { initLogging } from '_js/utils/log';
import loadOptimize from '_js/utils/3rdparty/optimize';
import { isProductionOrStaging } from '_js/utils/environment';
import store from './store';
import { version as appVersion } from '../../package.json';

Tracking.gtm();
loadOptimize();
if (isProductionOrStaging) initLogging(appVersion);

const container = document.getElementById('root');
const root = createRoot(container);
Modal.setAppElement(root);
root.render(
  <Provider store={store}>
    <Favicon />
    <App />
  </Provider>,
);

Tracking.hotjar();
