import { defineMessages } from 'react-intl';

const messages = defineMessages({
  rentingApartment: {
    defaultMessage: 'Rented apartment',
    id: 'homeTypes.rentingApartment',
    description: 'Residential type - Renting appartment',
  },
  houseOrTownhouse: {
    defaultMessage: 'House',
    id: 'homeTypes.houseOrTownhouse',
    description: 'Residential type - House',
  },
  condominium: {
    defaultMessage: 'Tenant',
    id: 'homeTypes.condominium',
    description: 'Residential type - Condominium',
  },
  rentingRoom: {
    defaultMessage: 'Lodger',
    id: 'homeTypes.rentingRoom',
    description: 'Residential type - Renting room/lodger',
  },
  withParents: {
    defaultMessage: 'Parental home',
    id: 'homeTypes.withParents',
    description: 'Residential type - Living with parents',
  },
  other: {
    defaultMessage: 'Other',
    id: 'homeTypes.other',
    description: 'Residential type - Other',
  },
  subrenter: {
    defaultMessage: 'Sublease',
    id: 'homeTypes.subrenter',
    description: 'Residential type - Subletting contract',
  },
  partOwner: {
    defaultMessage: 'Part-owner',
    id: 'homeTypes.partOwner',
    description: 'Residential type - Part owner',
  },
  villa: {
    defaultMessage: 'House',
    id: 'homeTypes.villa',
    description: 'Residential type - Villa',
  },
  rentedApartment: {
    defaultMessage: 'Rented apartment',
    id: 'homeTypes.rentedApartment',
    description: 'Residential type - Tenancy/rented apartment',
  },
});

const homeTypes = {
  rentingApartment: {
    label: messages.rentingApartment,
    value: 'rentingApartment',
  },
  houseOrTownhouse: {
    label: messages.houseOrTownhouse,
    value: 'houseOrTownhouse',
  },
  condominium: {
    label: messages.condominium,
    value: 'condominium',
  },
  rentingRoom: {
    label: messages.rentingRoom,
    value: 'rentingRoom',
  },
  withParents: {
    label: messages.withParents,
    value: 'withParents',
  },
  other: {
    label: messages.other,
    value: 'other',
  },
  subrenter: {
    label: messages.subrenter,
    value: 'subrenter',
  },
  partOwner: {
    label: messages.partOwner,
    value: 'partOwner',
  },
  villa: {
    label: messages.villa,
    value: 'villa',
  },
  rentedApartment: {
    label: messages.rentedApartment,
    value: 'rentedApartment',
  },
};

export const {
  rentingRoom,
  rentingApartment,
  houseOrTownhouse,
  condominium,
  withParents,
  other,
  subrenter,
  partOwner,
  rentedApartment,
  villa,
} = homeTypes;
