/* eslint-disable default-param-last */
import {
  FETCH_CREDITORS,
  FETCH_CREDITORS_SUCCESS,
  FETCH_CREDITORS_FAILED,
} from '_js/store/actions/api/creditors/fetch';

import { getObjectOfCreditors } from '_js/utils/creditors';

export const initialState = {
  list: {},
  loading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CREDITORS:
      return {
        ...state,
        loading: !state.state,
      };
    case FETCH_CREDITORS_SUCCESS:
      return {
        ...state,
        list: getObjectOfCreditors(action.payload),
        loading: false,
      };

    case FETCH_CREDITORS_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
